body {
  font-family: "Lato", sans-serif;
}

.mentee-dashboard {
  min-height: 100vh;
}

.menteeProfile-photoName {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.dashboard-container {
  width: 100%;
}

.profile-cover {
  background: linear-gradient(135deg, #244e92 0%, #1a365d 100%);
  width: 100%;
  border-radius: 1rem;
  padding-bottom: 2rem;
}

.cover-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 2rem;
}

.profile-image-container {
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  border: 4px solid #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* flex: 1; */
}

.mentee-dashboard .profile-imageDashBoard {
  width: 200px;
  height: 200px;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.profile-image-container:hover .update-text {
  opacity: 1;
}

.profile-image-container:hover .profile-imageDashBoard {
  opacity: 0.6;
}

.update-text {
  position: absolute;
  width: 7rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3sease-in-out;
  font-size: 14px;
}

.mentee-dashboardHeadline {
  flex: 3;
}

.profile-actions {
  margin-bottom: 20px;
}

.action-button {
  background-color: #fff;
  color: #244e92;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background-color: #f8f9fa;
  transform: translateY(-1px);
}

.main-content {
  max-width: 1200px;
  margin: -60px auto 0;
  padding: 0 20px;
}

.content-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  padding-top: 80px;
}

.left-column {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 3px -5px 13px 3px rgba(0, 0, 0, 0.05);
}

.MenteeDashboard-info-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

.mentee-name {
  font-size: 2rem;
  font-weight: 600;
  color: white;
  font-family: "lato";
  margin: 0;
}

.social-links {
  display: flex;
  gap: 12px;
}

.social-links i {
  font-size: 20px;
  color: #244e92;
  cursor: pointer;
}

.info-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;
}

.info-item p {
  margin: 0;
  color: #ffffffd9;
  font-family: "lato";
  text-align: justify;
  line-height: normal;
}

.about-text {
  line-height: 1.6;
}

.skills-section {
  margin: 32px 0;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.skill-tag {
  background-color: #e9ecef;
  color: #244e92;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
}

.right-column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: -1px 0px 16px 15px rgba(0, 0, 0, 0.05);
}

.details-card {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.card-title {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.detail-item {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #e9ecef;
  justify-content: space-between;
}

.detail-item:last-child {
  border-bottom: none;
}

.detail-header {
  display: flex;
  align-items: baseline;
  gap: 12px;
  margin-bottom: 8px;
}

.detail-header h6 {
  margin: 0;
  font-weight: 600;
  color: #4a5568;
}

.detail-item p {
  margin: 0;
  color: #4a5568;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.section-header h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #1a1a1a;
}

@media (max-width: 1024px) {
  .content-grid {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .content-grid {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .profile-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-actions {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .MenteeDashboard-info-section {
    /* flex-direction: column; */
    align-items: center;
    text-align: center;
    gap: 16px;
  }
}

@media (max-width: 480px) {
  .menteeProfile-photoName {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
    flex-direction: column;
  }

  /* .profile-image-container {
    width: 140px;
    height: 140px;
  } */

  .main-content {
    padding: 0 16px;
  }

  .mentee-name {
    font-size: 20px;
  }

  .left-column,
  .details-card {
    padding: 16px;
    width: 95%;
  }

  .skills-container {
    gap: 6px;
  }

  .skill-tag {
    font-size: 12px;
    padding: 4px 8px;
  }
}

.work-experience-container {
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
}

.experience-card {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.experience-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.company-name {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1f2937;
}

.duration {
  font-size: 0.875rem;
  color: #4b5563;
}

.duration-separator {
  margin: 0 0.5rem;
}

.role-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #374151;
  margin-bottom: 0.75rem;
}

.experience-description {
  color: #4b5563;
  line-height: 1.625;
  white-space: pre-wrap;
}

@media (max-width: 640px) {
  .experience-card {
    padding: 1rem;
  }

  .experience-header {
    flex-direction: column;
    gap: 0.5rem;
  }

  .company-name {
    font-size: 1.125rem;
  }

  .role-title {
    font-size: 1rem;
  }
}

.education-section {
  max-width: 64rem;
  margin: 0 auto;
}

.section-header {
  margin-bottom: 2rem;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 1rem;
}

.section-header h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #111827;
}

.education-content {
  display: grid;
  gap: 1.5rem;
}

.education-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.education-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.education-type {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: #e5e7eb;
  border-radius: 9999px;
  font-size: 0.875rem;
  text-transform: capitalize;
  color: #4b5563;
  margin-bottom: 0.75rem;
}

.course-name,
.school-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
}

.education-details {
  display: grid;
  gap: 0.75rem;
}

.detail-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.detail-label {
  font-weight: 500;
  color: #6b7280;
  min-width: 5rem;
}

.detail-value {
  color: #374151;
}

@media (max-width: 640px) {
  .education-section {
    padding: 1rem;
  }

  .education-card {
    padding: 1rem;
  }

  .detail-group {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  .detail-label {
    min-width: auto;
  }
}

@media (min-width: 768px) {
  .education-content {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.certification-section {
  max-width: 64rem;
}

.section-header {
  margin-bottom: 2rem;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 1rem;
}

.section-header h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #111827;
}

.certification-content {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.certification-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.certification-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.certification-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
}

.certificate-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
  flex: 1;
}

.certificate-level {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: #e5e7eb;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: #4b5563;
  white-space: nowrap;
}

.certificate-details {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.certificate-duration {
  font-size: 0.875rem;
  color: #6b7280;
}

.duration-separator {
  margin: 0 0.5rem;
}

.certificate-description {
  color: #4b5563;
  line-height: 1.625;
  margin: 0;
  font-size: 0.975rem;
}

@media (max-width: 640px) {
  .certification-section {
    padding: 1rem;
  }

  .certification-card {
    padding: 1rem;
  }

  .certification-header {
    flex-direction: column;
    gap: 0.5rem;
  }

  .certificate-level {
    align-self: flex-start;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.certification-card {
  animation: fadeIn 0.3s ease-out;
}

.additional-section {
  max-width: 64rem;
}

.section-header {
  margin-bottom: 2rem;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 1rem;
}

.section-header h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #111827;
}

.additional-content {
  display: grid;
  gap: 1.5rem;
}

.additional-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.additional-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.additional-headline {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 1rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.additional-description {
  color: #4b5563;
  line-height: 1.625;
  margin: 0;
  font-size: 1rem;
  white-space: pre-wrap;
}

@media (max-width: 640px) {
  .additional-section {
    padding: 1rem;
  }

  .additional-card {
    padding: 1rem;
  }

  .additional-headline {
    font-size: 1.125rem;
  }
}

@media (min-width: 768px) {
  .additional-content {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.additional-card {
  animation: slideIn 0.3s ease-out;
}