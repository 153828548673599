.ai-casestudy-hero-section {
  min-height: 95vh;
  padding: 3rem 2rem;
  background: linear-gradient(90deg, #e0e7ff 1.52%, #f7f7f7 99.87%);
  display: flex;
  align-items: center;
}

.ai-casestudy-grid {
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
  align-items: center;
}

.ai-casestudy-image-column {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ai-casestudy-casecontributor-image-bg {
  width: 80%;
  height: 350px;
  position: relative;
  background-color: rgba(59, 174, 246, 0.275);
  border-radius: 16px;
  transition: transform 0.3s;
  margin-top: 2rem;
}

.ai-casestudy-casecontributor-image {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 16px;
}

.ai-casestudy-casecontributor-image-bg:hover {
  transform: scale(1.02);
}

.ai-casestudy-preview-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 1rem;
  object-fit: cover;
}

.ai-casestudy-content-column {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ai-casestudy-badge {
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  background-color: #dbeafe;
  color: #2563eb;
  font-size: 0.875rem;
  margin-bottom: 2rem;
  white-space: nowrap;
}

.ai-casestudy-content-wrapper {
  width: 100%;
}

.ai-casestudy-title {
  font-size: 3.3rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.ai-casestudy-highlight {
  color: #2563eb;
}

.ai-casestudy-description {
  font-size: clamp(1rem, 3vw, 1.25rem);
  color: #1f2937;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.ai-casestudy-button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.ai-casestudy-primary-button,
.ai-casestudy-secondary-button {
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.ai-casestudy-primary-button {
  background-color: #2563eb;
  color: white;
  border: none;
}

.ai-casestudy-primary-button:hover {
  background-color: #1d4ed8;
}

.ai-casestudy-secondary-button {
  border: 2px solid #2563eb;
  color: #2563eb;
  background: transparent;
}

.ai-casestudy-secondary-button:hover {
  background-color: #eff6ff;
}

.ai-casestudy-icon {
  width: 1rem;
  height: 1rem;
}

@media (min-width: 420px) {
  .ai-casestudy-casecontributor-image {
    width: auto;
    height: 400px;
  }
}
@media (min-width: 540px) {
  .ai-casestudy-casecontributor-image {
    width: auto;
    height: 400px;
  }
}
@media (min-width: 768px) {
  .ai-casestudy-hero-section {
    padding: 3rem 2rem;
  }

  .ai-casestudy-button-group {
    flex-direction: row;
    justify-content: center;
  }

  .ai-casestudy-primary-button,
  .ai-casestudy-secondary-button {
    width: auto;
    min-width: 180px;
  }
  .ai-casestudy-casecontributor-image {
    width: auto;
    height: 400px;
  }
}

@media (min-width: 1024px) {
  .ai-casestudy-hero-section {
    padding: 5rem 24px;
  }

  .ai-casestudy-container {
    max-width: 1300px;
    margin: 0 auto;
  }

  .ai-casestudy-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }

  .ai-casestudy-image-column,
  .ai-casestudy-content-column {
    flex: 1;
  }

  .ai-casestudy-image-column {
    justify-content: start;
  }

  .ai-casestudy-content-column {
    text-align: right;
    align-items: flex-end;
  }

  .ai-casestudy-button-group {
    justify-content: flex-end;
  }
}
