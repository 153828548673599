.bank-details-container {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 85vh;
  min-height: 300px;
  max-height: 720px;
  width: 100%;
  overflow: scroll;
}

.bank-details-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.bank-details-form h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btnContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.btn_bank_details {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.btn1:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
