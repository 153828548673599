.col-md-flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px 0;
}

.djioerr_dpdwn22 {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 9;
  top: 6rem;
  right: 1rem;
}

.djioerr_dpdwn22 li {
  list-style: none;
  text-transform: uppercase;
  border-bottom: 1px solid var(--light-grey-border);
  padding: 5px 0;
  font-weight: 600;
}

.djioerr_dpdwn22 li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.asdfasdfa {
  display: flex;
  align-items: end;
  justify-content: end;
  margin: 0px !important;
}

.ksjdflaksjflk {
  margin-right: 10px;
}

@media only screen and (max-width: 993px) {
  .mbMentorDashboardProfilePicture {
    display: none;
  }

  .mbMentorDashboardProfilePictureBlock {
    display: block;
    margin-bottom: 30px;
    width: 10rem;
    height: 10rem;
  }

  .jhjhjujhhh h5 {
    font-size: 16px;
  }

  .lndfhjfvgdvbfgfghgf .py-4 {
    padding: 0px !important;
  }

  .widthlajsdlf {
    width: 92%;
  }

  .display-raw {
    display: none;
    margin: 0px 10px;
  }

  .col-md-flex-center {
    margin: 16px 30px;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .mobileMenuIconMentorDashboard {
    display: block;
  }

  .px81 {
    padding: 10px 20px !important;
  }

  .nfhgfg {
    margin: 0px;
  }

  .bank-details-container {
    padding: 0px 24px;
  }

  .py-4 {
    padding: 0px !important;
  }

  .mbgjfhg {
    display: none;
  }

  .hiniertvrer_change_password {
    margin: 0 30px;
  }

  .py-5 {
    padding: 0px 0px 30px 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .gjnjfghg h2 {
    font-size: 1.5rem !important;
  }
}

@media only screen and (min-width: 994px) {
  .mbMentorDashboardProfilePictureBlock {
    display: none;
  }

  .widthlajsdlf {
    width: 81%;
  }

  .mobileMenuIconMentorDashboard {
    display: none;
  }
}