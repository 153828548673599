.containerOfGueste {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.containerOfFilter {
  padding: 20px;
  width: 95%;
  margin: 20px auto;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.containerOfCard {
  padding: 20px;
  width: 95%;
  margin: 20px auto;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
}
.containerOfFilter label {
  display: block;
  margin-bottom: 15px;
}

.containerOfFilter h6.inline {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
  font-weight: 600;
}

.containerOfFilter select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  transition: border-color 0.3s;
}

.containerOfFilter select:focus {
  border-color: #007bff;
  outline: none;
}

.containerOfFilter button {
  width: 200px;
  padding: 10px;
  margin-top: 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.containerOfFilter button:hover {
  background-color: #0056b3;
}

.inline {
  display: inline;
  margin: 0 3px;
}
