/* 

footer {
  background: #001237;
}
footer p {
  color: #fff;
  letter-spacing: 0.5px;
}
footer h4 {
  color: #fff;
  position: relative;
}
footer h4:after {
  position: absolute;
  content: "";
  width: 2rem;
  height: 2px;
  border-radius: 25px;
  background: linear-gradient(to right, var(--main-orange-color), transparent);
  left: 0;
  bottom: -5px;
}
footer .hjgfgfghghgfh .nbffg {
  color: #fff;
  letter-spacing: 0.5px;
  transition: 0.3s ease-in-out;
}
footer .hjgfgfghghgfh .nbffg i {
  color: var(--main-orange-color);
  font-size: 1rem;
  transition: 0.3s ease-in-out;
}
footer .hjgfgfghghgfh .nbffg:hover i {
  padding-right: 5px;
  transition: 0.3s ease-in-out;
}
footer .hjgfgfghghgfh .nbffg:after {
  width: 0;
  content: "";
  position: absolute;
  height: 1.5px;
  border-radius: 25px;
  background: var(--main-orange-color);
  left: 1rem;
  bottom: -5px;
  transition: 0.2s ease-in-out;
}
footer .hjgfgfghghgfh .nbffg:hover:after {
  width: 80%;
  transition: 0.3s ease-in-out;
}
footer .nbffg2 .d-flex {
  align-items: baseline;
}
footer .nbffg2 a i {
  color: var(--main-orange-color);
  font-size: 1.2rem;
}
footer .nbffg2 a p {
  color: #fff;
}
footer .bghjdfgfdg button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #fff !important;
  border: none;
  transition: 0.3s ease-in-out;
}
footer .jdfhgdfg img {
  width: 50%;
}
footer .bghjdfgfdg button:hover {
  background: var(--main-orange-color) !important;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  transform: translateY(-5px);
}
footer .bghjdfgfdg button i {
  color: var(--main-orange-color);
  font-size: 1.4rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
}
footer .bghjdfgfdg button:hover i {
  color: #fff;
  transition: 0.3s ease-in-out;
  transform: translate(-50%, -45%) scale(1.2);
} */

/* new */

.footer {
  background-color: #001237;
  color: white;
}

.footer-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 4rem 1rem 1.5rem;
}

.footer-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.footer-brand {
  text-align: center;
  max-width: 100%;
}

.footer-logo {
  height: 2.5rem;
  display: inline-block;
  color: #0d9488;
}

.footer-description {
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 28rem;
  color: #9ca3af;
  line-height: 1.6;
}

.social-links {
  list-style: none;
  padding: 0;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.social-icon {
  border-radius: 100%;
  padding: 1rem;
  background-color: #00ced7;
  transition: color 0.3s;
}

.social-icon i {
  height: 1.5rem;
  width: 1.5rem;
  color: #6b7280;
}

.social-icon:hover {
  color: #0f766e;
}

.footer-links {
  display: grid;
  grid-template-columns: 1fr;
}

.footer-section {
  text-align: center;
}

.footer-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.footer-section ul {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  gap: 1rem;
}

.footer-section a {
  color: #fff !important;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section a:hover {
  color: #d9dfea !important;
  transition: 0.3s ease-in-out;
}

.contact-info li {
  display: flex;
  justify-content: center;
  gap: 0.375rem;
}

.contact-icon {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  margin-right: 10px;
  color: #fff;
}

.footer-bottom {
  margin-top: 3rem;
  padding-top: 1.5rem;
  border-top: 0.5px solid #444b7338;
}

.footer-bottom-content {
  text-align: center;
}

.copyright {
  font-size: 0.875rem;
  color: #6b7280;
}

.copyright a {
  color: #0d9488 !important;
  text-decoration: underline !important;
  margin: 0 0.5rem;
  transition: color 0.3s;
}

.copyright a:hover {
  color: #0f766e !important;
}

.company-copyright {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #6b7280;
}

@media (min-width: 640px) {
  .footer-brand,
  .footer-section {
    text-align: left;
  }

  .footer-description {
    margin-left: 0;
    margin-right: 0;
  }
  .social-links,
  .contact-info li {
    justify-content: flex-start;
  }

  .footer-links {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-bottom-content {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .company-copyright {
    margin-top: 0;
    order: -1;
  }
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-brand {
    flex: 0 0 35%;
  }

  .footer-links {
    flex: 0 0 55%;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .footer-brand {
    flex: 0 0 45%;
  }

  .footer-links {
    flex: 0 0 50%;
  }
}
