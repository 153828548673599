
.kjdhhjgd {
  background-color: white !important;
}
.mhgf1 i {
  background-color: white !important;
}

.aslkhghj {
  background: linear-gradient(
    270deg,
    #eeeeff 44.17%,
    rgba(238, 238, 255, 0) 100%
  ) !important;
}

.kjhfhffd {
  align-items: initial !important;
}

.kjhfhffd {
  display: flex;
  margin-top: 45px;
  align-items: baseline;
}

.mhgf1 i {
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  color: gray;
  border: 2px solid var(--main-orange-color);
  border-radius: 50px;
  margin-left: 15px;
}

.jhgjgf i {
  color: #ee6c29;
}

.kjdfvghjdrbgdfgfd {
  width: 100%;
  padding: 40px 0;
}

.lghjgt {
  margin-bottom: 25px;
}
.lghjgt h5 {
  font-size: 25px;
  color: black;
  font-weight: 600;
}
.lghjgt p {
  font-size: 16px;
  color: gray;
  line-height: 25px;
}
.lghjgt ul li {
  font-size: 16px;
  color: gray;
  padding-bottom: 10px;
  line-height: 25px;
}

.kfgjh i {
  padding: 8px;
  border-radius: 50px;
  font-size: 18px;
  border: 2px solid var(--main-orange-color);
  color: var(--main-orange-color);
}

.kfgjh {
  margin-left: 20px;
}

.jfgngjfgh {
  display: flex;
}

.jfgngjfgh p {
  font-weight: bold;
}

.kjdhjdrgfg {
  margin-top: 15px;
}
.kjdhjdrgfg h5 {
  font-size: 25px;
  color: black;
  font-weight: 600;
  margin-bottom: 0;
}
.kjdhjdrgfg p {
  font-size: 17px;
  color: #8b8989;
}

.oidjaioerr,
.klfgf {
  height: 10rem;
  overflow-y: auto;
  margin-bottom: 1.8rem;
}

.kjdfhjdfdffg {
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.kjdfhjdfdffg h5 {
  font-size: 22px;
  color: black;
  font-weight: 600;
  margin-bottom: 0;
}

.fkghfgnhgjhg {
  margin-top: 15px;
}

.fjhg {
  display: flex;
  margin-bottom: -10px;
  align-items: baseline;
  justify-content: space-between;
}
.fjhg h6 {
  font-weight: 600;
  font-size: 16px;
  color: black;
}
.fjhg p {
  font-size: 15px;
  color: gray;
}

.nfhjdfghfdghf {
  margin-top: -10px;
}

.hjgfjgfgf {
  margin-top: -10px;
}

.fkjhgjfgf {
  display: flex;
  justify-content: space-between;
}
.fkjhgjfgf i {
  color: var(--main-orange-color);
  font-size: 14px;
}
.fkjhgjfgf p {
  font-size: 16px;
  color: gray;
  font-weight: 500;
}

.fkjhgjfgf {
  margin-top: 10px;
}

