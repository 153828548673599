.single-applicant-profile-overview-container {
  padding: 0 2rem;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}
.single-applicant-prof-profile-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.single-applicant-prof-profile-title {
  margin: 0;
  font-size: 1.5rem;
}

.single-applicant-profile-overview-wrapper {
  max-width: 900px;
  margin: 0 auto;
}
.single-applicant-profile-card {
  background: #ffffff;
  border-radius: 1rem;
  border: 1px solid rgb(204, 204, 204);
  padding: 2rem;
}
.single-applicant-profile-header {
  display: flex;
  align-items: start;
  gap: 2rem;
}
.single-applicant-profile-picture {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 2px solid #e2e8f0;
}
.single-applicant-profile-info {
  flex: 1;
}
.single-applicant-profile-name {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1f2937;
}
.single-applicant-profile-location {
  color: #6b7280;
}
.single-applicant-profile-description {
  margin-top: 0.5rem;
  color: #374151;
}
.single-applicant-profile-links {
  display: flex;
}
.single-applicant-icon {
  color: rgb(38, 108, 246);
  font-size: 1.5rem;
}
.single-applicant-profile-section {
  margin-top: 2rem;
}
.single-applicant-section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}
.single-applicant-section-list {
  margin-top: 0.5rem;
  list-style-type: disc;
  padding-left: 1.5rem;
  color: #374151;
}
.single-applicant-experience-item {
  margin-top: 1.5rem;
}
.single-applicant-experience-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #374151;
}
.single-applicant-experience-company {
  color: #6b7280;
}
.single-applicant-experience-list {
  margin-top: 0.5rem;
  list-style-type: disc;
  padding-left: 1.5rem;
  color: #374151;
}
.single-applicant-prof-profile-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 1rem;
}
.single-applicant-prof-btn-outline {
  padding: 0.5rem 1rem;
  border: 1px solid #d1d5db;
  background: transparent;
  border-radius: 0.375rem;
  color: #374151;
  transition: all 0.3s;
}
.single-applicant-prof-btn-outline:hover {
  background: #f3f4f6;
}
.single-applicant-btn-default {
  padding: 0.5rem 1rem;
  background: #1d4ed8;
  border: none;
  border-radius: 0.375rem;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
}
.single-applicant-btn-default:hover {
  background: #2563eb;
}
@media (max-width: 768px) {
  .single-applicant-profile-overview-container {
    padding: 0;
  }
  .single-applicant-profile-header {
    flex-direction: column;
    align-items: center;
  }
  .single-applicant-profile-info {
    text-align: center;
  }
  .single-applicant-profile-card {
    padding: 1rem;
  }
}
