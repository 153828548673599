@media (max-width: 991px) {
  .md-header {
    margin: 0;
  }
  .mob-main {
    font-size: 15px;
    height: 100dvh;
   
    width: 100%;
  }
  .mob-center {
    display: flex;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    align-items: center;
    align-self: center;
    align-content: center;
  }
  .mob-column {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  .mob-hide {
    display: none !important;
  }
  .mlr {
    margin-left: auto;
    margin-right: auto;
  }
  .w15r {
    width: 15rem;
  }
  .menu-items {
    display: flex;
    gap: 1rem;
    align-items: baseline;
    position: relative;
  }
  .gap5 {
    gap: 5px important;
  }

  .mob-close-menu-container {
    display: flex;
    justify-content: end;
    height: 1.5rem;
    border: none !important;
  }
  .mob-close-menu {
    color: #ff0000 !important;
    font-size: 1.2rem;
    border: solid 1px #000;
    border-radius: 3px;
    padding: 5px 7px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

@media (min-width: 991px) {
  hide {
    display: none;
  }
}
