.ye-waala-final-assign-container {
  max-width: 1300px;
  margin: 0 auto;
  min-height: 80vh;
  padding: 20px;
}

.ye-waala-final-assign-dashboard {
  width: 100%;
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

/* Left Panel Styles */
.ye-waala-final-assign-left-panel {
  flex: 1;
  padding: 30px;
  border-right: 1px solid #eaedf3;
  max-width: 500px;
}

.ye-waala-final-assign-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.ye-waala-final-assign-tags {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.ye-waala-final-assign-tag {
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.ye-waala-final-assign-tag-business {
  background-color: #e6f7ff;
  color: #0070f3;
}

.ye-waala-final-assign-tag-strategy {
  background-color: #f0f5ff;
  color: #2f54eb;
}

.ye-waala-final-assign-tag-marketing {
  background-color: #e6fffb;
  color: #13c2c2;
}

.ye-waala-final-assign-description {
  font-size: 15px;
  line-height: 1.6;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.ye-waala-final-assign-course-info {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.ye-waala-final-assign-info-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 14px;
}

.ye-waala-final-assign-icon {
  color: #999;
}

.ye-waala-final-assign-subtitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.ye-waala-final-assign-modules {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.ye-waala-final-assign-module {
  font-size: 14px;
  line-height: 1.6;
  color: #4a4a4a;
}

.ye-waala-final-assign-module strong {
  color: #1a1a1a;
}

.ye-waala-final-assign-view-button {
  width: 100%;
  padding: 12px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ye-waala-final-assign-view-button:hover {
  background-color: #1d4ed8;
}

/* Right Panel Styles */
.ye-waala-final-assign-right-panel {
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.ye-waala-final-assign-search-section {
  margin-bottom: 20px;
}

.ye-waala-final-assign-search-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.ye-waala-final-assign-search-input {
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.ye-waala-final-assign-class-filter {
  position: relative;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  min-width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.ye-waala-final-assign-class-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-top: 5px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ye-waala-final-assign-class-option {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ye-waala-final-assign-class-option:hover {
  background-color: #f5f7fa;
}

.ye-waala-final-assign-active-filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.ye-waala-final-assign-filter-tag {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  background-color: #f0f5ff;
  color: #2f54eb;
  border-radius: 4px;
  font-size: 14px;
}

.ye-waala-final-assign-remove-filter {
  cursor: pointer;
  font-size: 12px;
}

.ye-waala-final-assign-add-button {
  padding: 10px 20px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  align-self: flex-end;
  display: flex;
  justify-self: end;
}

.ye-waala-final-assign-add-button:hover {
  background-color: #1d4ed8;
}

.ye-waala-final-assign-students-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #eaedf3;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px;
}

.ye-waala-final-assign-table-header {
  display: flex;
  background-color: #f9fafb;
  padding: 15px;
  font-weight: 600;
  border-bottom: 1px solid #eaedf3;
}

.ye-waala-final-assign-table-body {
  flex: 1;
  overflow-y: auto;
  max-height: 400px;
}

.ye-waala-final-assign-table-row {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #eaedf3;
}

.ye-waala-final-assign-table-row:last-child {
  border-bottom: none;
}

.ye-waala-final-assign-checkbox-cell {
  width: 40px;
  display: flex;
  align-items: center;
}

.ye-waala-final-assign-name-cell {
  flex: 2;
}

.ye-waala-final-assign-id-cell {
  flex: 1;
}

.ye-waala-final-assign-class-cell {
  flex: 1;
}

.ye-waala-final-assign-email-cell {
  flex: 2;
}

.ye-waala-final-assign-action-cell {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ye-waala-final-assign-delete-icon {
  color: #999;
  cursor: pointer;
  transition: color 0.2s;
}

.ye-waala-final-assign-delete-icon:hover {
  color: #f43f5e;
}

.ye-waala-final-assign-table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ye-waala-final-assign-selection-info {
  font-size: 14px;
  color: #666;
}

.ye-waala-final-assign-pagination {
  display: flex;
  gap: 5px;
}

.ye-waala-final-assign-page-button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.ye-waala-final-assign-page-button:hover {
  background-color: #f5f7fa;
}

.ye-waala-final-assign-active-page {
  background-color: #2563eb;
  color: white;
  border-color: #2563eb;
}

.ye-waala-final-assign-assign-button {
  padding: 10px 20px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ye-waala-final-assign-assign-button:hover {
  background-color: #1d4ed8;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .ye-waala-final-assign-dashboard {
    flex-direction: column;
  }

  .ye-waala-final-assign-left-panel {
    max-width: 100%;
    border-right: none;
    border-bottom: 1px solid #eaedf3;
  }
}

@media (max-width: 768px) {
  .ye-waala-final-assign-course-info {
    flex-direction: column;
    gap: 10px;
  }

  .ye-waala-final-assign-search-container {
    flex-direction: column;
  }

  .ye-waala-final-assign-table-header,
  .ye-waala-final-assign-table-row {
    font-size: 14px;
  }

  .ye-waala-final-assign-email-cell {
    display: none;
  }

  .ye-waala-final-assign-table-footer {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .ye-waala-final-assign-container {
    padding: 10px;
  }

  .ye-waala-final-assign-left-panel,
  .ye-waala-final-assign-right-panel {
    padding: 15px;
  }

  .ye-waala-final-assign-id-cell {
    display: none;
  }

  .ye-waala-final-assign-class-cell {
    display: none;
  }
}
