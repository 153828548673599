.react-tel-input .form-control {
  position: relative;
  font-size: 1rem;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  line-height: 25px;
  height: auto;
  width: 100%;
  outline: none;
}
