.position-relative {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 70%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff; 
  font-size: 18px; 
}

.password-toggle:focus {
  outline: none;
}
