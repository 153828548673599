@media only screen and (max-width: 1054px) {
  main .s-2 .uirhtt_wrapper .nav img {
    width: 75%;
  }
  .dnhghjdfgdfg {
    height: 500px;
  }
  .section7 {
    height: 50vh;
  }
  .contactuss {
    height: auto;
    margin-bottom: 30px;
  }

  .custom-select .sfgrwwe_btn {
    margin-bottom: 15px !important;
  }
}

@media only screen and (max-width: 991px) {
  header .navbarmenucollapse {
    display: none;
  }
  header .navbar-light .navbar-toggler {
    border-color: #fff !important;
  }
  header #nav-toggler i {
    font-size: 2rem;
  }
  .ndfhjvdfv {
    display: block;
    padding-bottom: 1rem;
  }
  .jdoieoir_wrapper {
    width: 95%;
  }
  .jdoieoir_wrapper #tabs.d-flex {
    display: block !important;
  }
  .jdoieoir_wrapper #tabs .tablinks {
    margin-bottom: 10px;
  }
  .bjuerirr_btn .juybeubrer_btn,
  .iudhehrnbeer_btn {
    padding: 0.375rem 0.75rem !important;
  }
  #frame {
    height: 88vh;
    min-height: initial;
    max-height: initial;
  }
  #navbarSupportedContent {
    padding: 1rem 0;
  }

  .duigehrr_wrapper .buyadnn.sticky-top {
    top: 10% !important;
  }

  .csdfygtyy {
    justify-content: space-between;
  }

  .jbigfhgf {
    height: auto !important;
  }

  .jbigfhgf img {
    height: auto !important;
  }
  .huygrut.d-flex {
    display: block !important;
  }
  .mentor-prf-settings form .huygrut .deuirr_circle {
    margin-bottom: 10px;
  }
  .mkjghfdfgg {
    height: auto;
    margin-bottom: 20px;
  }
  .regis_background .iuhieiuihaw_right {
    margin: 0 0;
  }
  .fhvgdshfdgf {
    padding: 15px;
  }
  .hjfghfg,
  .nfjgdfg {
    text-align: center;
  }
  .fhjffs {
    margin: auto;
  }
  .hjgb {
    margin-left: 0;
  }

  .oisgdfuhjbdsjgf.d-none {
    display: block !important;
  }

  .iughuibugerr {
    display: none;
  }

  .mentor_dashboard .col-lg-10.ps-0 {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .jfhfbg {
    height: auto !important;
    margin-bottom: 30px !important;
  }
  .fhjffs {
    margin-bottom: 20px;
  }
  .kjdhfdf {
    height: 300px;
  }

  .hghgg {
    padding-left: 0;
  }

  .jbdgfdsf {
    margin-top: 20px;
  }
  .fgbfgfgfh p {
    font-size: 18px;
  }
  .fjgkfhg p {
    font-size: 18px;
  }

  .mkjdfbhfbgfdfg {
    margin-top: 30px;
  }

  .banner_content {
    text-align: center;
  }

  h1 {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 2.3rem;
    line-height: 1.5;
  }

  .kjdrhgjdfgf .duiher:after {
    right: -1px;
  }

  .kjdrhgjdfgf {
    margin-bottom: 2.4rem;
  }

  p {
    font-size: 1.4rem;
  }

  .iudherrr {
    padding: 0 10px;
  }

  .tabs__tab-btn {
    margin: 0 3px;
    width: 100%;
    font-size: 1rem !important;
  }

  .rkgjfg {
    display: none;
  }

  h2 br,
  p br {
    display: none;
  }

  header .navbar.mx-4 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  header .navbar.mt-3 {
    margin-top: 0 !important;
  }

  header nav {
    border-radius: 0;
  }

  .duiehrr_dropdwn ul {
    position: relative;
  }

  .iugeuirrr.d-flex {
    display: block !important;
  }

  header .fvjhdf_btn {
    padding-left: 0 !important;
  }

  .udgehrr.ps-3 {
    padding-left: 0 !important;
  }

  header .iugeuirrr .udgehrr {
    border-right: 0;
  }

  .khgjfgfhg2252 {
    display: none;
  }

  .csdfygtyy h5 {
    margin-bottom: 10px !important;
  }

  .csdfygtyy h5:first-child {
    display: none;
  }

  .csdfygtyy .cfbghed {
    display: block !important;
  }

  .hjgfdfg {
    min-height: 230px;
    line-height: 230px;
    max-height: 230px;
  }

  .duigehrr_wrapper .hjgfdfg img {
    max-height: 100% !important;
  }

  .iuheirhggg_ihberr {
    top: 0;
    display: block !important;
    height: 100vh !important;
    position: fixed;
    z-index: 999;
    width: 95%;
    max-width: 100%;
    padding-top: 3rem;
    transition: 0.3s ease-in-out;
    left: 0;
  }

  .ugenhuhrtniu {
    width: 0 !important;
    transition: 0.3s ease-in-out !important;
    left: -20% !important;
  }

  .huirebff_close i {
    font-size: 2rem;
    position: absolute;
    right: 5%;
    top: 2%;
    color: #fff;
  }

  .dieirherr_btn button {
    display: none;
  }

  .mentor_dashboard .csdegbfraedd,
  .md-header header .header-wrapper #nav-toggler {
    display: none;
  }

  .mentor_dashboard .csdegbfraedd,
  .difuhtre_content {
    height: 100vh;
  }

  .odejr_res.d-none {
    display: block !important;
  }

  .res-db-sidebar .md-header {
    top: 0;
    display: block !important;
    background: var(--main-orange-color);
    position: fixed;
    z-index: 999;
    width: 33%;
    max-width: 100%;
    padding-top: 3rem;
    transition: 0.3s ease-in-out;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.45) 0px -25px 20px 0;
  }

  .md-header .iugeuirrr.d-flex {
    display: none !important;
  }

  #responsive-side-bar i {
    color: rgba(0, 0, 0, 0.55);
  }

  .lgnbjfghbfgh {
    margin-bottom: 20px;
  }

  .jfgg {
    height: 260px;
  }

  .jgfgfg {
    margin-top: 100px;
  }

  .jgfg {
    height: auto !important;
  }

  .jgfg img {
    height: auto !important;
  }

  .kfhdfuygfgghfg .tab-content.current {
    text-align: justify;
  }

  .nfhjgbgf {
    display: block;
  }

  .kghfbgfgbhfg .sfgrwwe_btn {
    margin-bottom: 0.9rem !important;
  }

  .gkbhfg {
    display: table-cell;
  }

  .mgjbhfghfgh {
    margin-top: 10px;
  }

  .dfghgghfg {
    text-align: justify;
  }

  .dfhjbg2 button {
    font-size: 11px;
    margin: 0 2px;
    padding: 1px 6px;
  }

  .kjgfghvfggfhgfhg556 ul.tabs {
    height: auto;
    padding-right: 0;
  }

  .bnbgjhg {
    display: none;
  }

  .mbpm.d-none {
    display: block !important;
  }

  .mbpm .idneihrr_btn.d-flex {
    display: block !important;
  }

  .mbpm .ihstrerer {
    border-radius: 0;
    box-shadow: none;
  }

  .ighefirr.sticky-top {
    top: 7%;
  }

  .container-fluid.px-5 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .oikahdbaed_filter.d-none {
    display: inline-block !important;
  }

  .ighefirr .sfgrwwe_btn:nth-of-type(5n-4),
  .ighefirr .sfgrwwe_btn:nth-of-type(5n-3),
  .ighefirr .sfgrwwe_btn:nth-of-type(5n-2),
  .ighefirr .sfgrwwe_btn:nth-of-type(5n-1) {
    display: none;
  }

  #bmresfil {
    display: block !important;
  }

  .bm-res-filter {
    position: fixed;
    width: 100%;
    top: 65%;
    height: 100%;
    z-index: 999;
    transition: 0.3s ease-in-out;
  }

  .uihdfnihre {
    top: 100%;
    transition: 0.3s ease-in-out;
  }

  .bm-res-filter .diugerbihr {
    box-shadow: none;
    border: 1px solid #dde5ea;
    border-radius: 0;
    border-bottom: 0;
  }

  .bm-res-filter .idgheirr {
    border-right: 0;
  }

  .fffddf {
    height: 350px;
  }
  .hbffgg22 {
    margin-top: 10px;
  }

  .resb-wrapper.d-none {
    display: block !important;
  }

  .resb-wrapper {
    bottom: 0;
    width: 100%;
    z-index: 999;
    border-top: 1px solid rgb(216, 214, 214);
  }

  .mb-responsive-calendar {
    position: fixed;
    top: 50% !important;
    left: 3%;
    right: 3%;
    transform: translateY(-50%);
    transition: 0.6s ease-in-out;
    z-index: 999;
  }

  .fkjbghdfgfghghjygh {
    margin-top: 0;
  }

  .vvverrbrrtr {
    top: -190rem !important;
    transition: 0.6s ease-in-out;
  }

  .dieuhrnr {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: 999;
    transition: 0.6s ease-in-out;
  }

  .hgkfgkjfgfghgfg.sticky-top {
    position: relative !important;
    z-index: initial !important;
  }

  .idbier_close.d-none {
    display: block !important;
  }

  .idbier_close {
    position: absolute;
    right: 0;
    top: -4rem;
  }

  .idbier_close i {
    font-size: 2.5rem;
    color: #fff;
  }


  .banner {
    background-position: initial !important;
    background-size: initial !important;
  }

  .nfhjgbgf {
    padding: 15px 0 !important;
  }

  .duiehr_content {
    display: block !important;
  }

  .duigher_circle {
    transform: translateY(0) !important;
  }
}

@media only screen and (max-width: 912px) {
  main .s-2 .uirhtt_wrapper .nav img {
    width: 53%;
  }
}

@media only screen and (max-width: 820px) {
  main .s-2 .uirhtt_wrapper .nav img {
    width: 60%;
  }

  main .s-2 .uirhtt_wrapper .nav .nav-link:nth-child(4n) {
    left: 8%;
  }

  main .s-2 .uirhtt_wrapper .nav .nav-link:nth-child(4n-2) {
    right: 8%;
  }

  .jghfghf.mt-5,
  .dfnhjfg.mt-5 {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  main .s-2 .uirhtt_wrapper .nav img {
    width: 64%;
  }

  .kjdrhgjdfgf,
  .jdhfhddf img {
    border-radius: 0;
  }

  .kjdrhgjdfgf {
    border-right: solid 1px #cacaca;
  }

  .mkjghfdfgg {
    width: 100%;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0px 0px;
    overflow: hidden;
  }

  .iudherrr {
    padding: 0 1.4rem 2.4rem 1.4rem;
  }

  .hjgfdfg {
    box-shadow: none;
  }

  .hjgfg {
    padding: 1.8rem 1.4rem;
  }

  p {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 745px) {
  main .s-2 .uirhtt_wrapper .nav img {
    width: 66%;
  }
}

@media only screen and (max-width: 720px) {
  main .s-2 .uirhtt_wrapper .nav img {
    width: 70%;
  }
}

@media only screen and (max-width: 688px) {
  .jbdgfdsf {
    height: 300px;
  }
  .kjdhfdf {
    height: 250px;
  }
  .dfghdfg {
    height: 375px;
  }
  .bfvdfgf {
    margin-bottom: 30px;
    margin: 0;
  }
  .mdbhcxvvx2 {
    margin-top: 0;
  }
  .dskjdfhgdfgfgfd h2 {
    padding-top: 30px;
  }
  .section7 {
    height: 65vh;
  }

  .searchbox-wrap {
    width: 480px;
  }
  .kfhdfuygfgghfg ul.tabs li {
    padding: 8px 25px;
  }
}

@media only screen and (max-width: 657px) {
}

@media only screen and (max-width: 633px) {
}

@media only screen and (max-width: 600px) {
  h4 {
    font-size: 1.4rem !important;
  }

  .oiduiaeghrf_left h4 {
    font-size: 1.2rem !important;
  }

  .hfuydfgftgh p {
    padding-left: 0 !important;
  }

  p {
    font-size: 1rem;
  }

  .ihduwfr_form_wrapper {
    height: auto !important;
  }

  .regis_background {
    padding-bottom: 4rem !important;
  }

  .fkjhgdfbghh .tab-content {
    padding-left: 0;
    padding-right: 0;
  }

  .uihderr_slider {
    width: 70%;
    margin: auto;
  }

  footer p {
    font-weight: initial;
  }

  .section5 {
    margin-top: 5rem;
  }

  .banner .banner_content {
    padding-top: 8rem;
    padding-bottom: 0;
  }

  .iuhieiuihaw_right.p-5 {
    padding: 1rem !important;
  }

  .fyhugdghg.d-flex {
    display: block !important;
  }

  #rdo1,
  #rdo4 {
    position: absolute;
  }

  .ghudiahoidead .tab-link {
    width: 100%;
    margin-bottom: 1rem;
    margin-right: 0 !important;
  }

  .mfkjghhdfgd img {
    width: 100% !important;
    margin: auto;
  }

  .ghudiahoidead ul {
    padding-left: 0;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .section7 {
    height: auto !important;
  }

  .bdhjgdf h6 {
    padding-top: 20px;
  }
  .bdhjgdf {
    margin-bottom: 20px;
  }
  .fgbfgfgfh h2 {
    padding-top: 30px;
  }
  .kjdfhdfgfd h2 {
    padding-top: 30px;
  }

  .res-db-sidebar .md-header .difuhtre_nav button h5 {
    font-size: 0.7rem;
  }

  .fffddf {
    height: 300px;
  }

  .section5.py-5 {
    padding-bottom: 0 !important;
  }

  .digheirer h4:before,
  .digheirer h4:after {
    display: none;
  }

  .njfgfghf i.me-2 {
    margin-right: 0 !important;
  }

  .njfgfghf i {
    padding: 7px;
    width: 2rem;
    height: 2rem;
  }
}

@media only screen and (max-width: 598px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  .section-hor-gap {
    padding: 1rem 0;
  }

  .kfhdfuygfgghfg ul.tabs li {
    padding: 8px 18px;
  }
}

@media only screen and (max-width: 548px) {
  .kjbdbeuirrr.d-flex {
    display: block !important;
  }

  .ujgereter {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 533px) {
  .banner_content .container-fluid.px-5 {
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
  }

  .hjgfjgfgf button {
    margin-bottom: 0.5rem;
  }
  .tjbghdfhbgfgf {
    display: block !important;
  }

  .nhbfgfd {
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  .idcuhsewfrsedf button span {
    display: none;
  }

  .section7 .duieghrrr_content .idcuhsewfrsedf button {
    height: 2.8rem;
    width: 2.8rem;
  }

  .idcuhsewfrsedf button i {
    color: #fff !important;
    display: block !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 468px) {
  .section4 .uihderrr .tab-container .uydwgeaheoiwee {
    width: 15rem;
    height: 15rem;
  }
  .section4 .uihderrr .tab {
    width: 6rem;
    height: 6rem;
  }

  .dieyhr_iuhfiderr .d-flex {
    display: block !important;
  }

  .dieyhr_iuhfiderr .btn {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 430px) {
  .dhgfdf {
    width: 100% !important;
  }
  .searchbox-wrap button span {
    padding: 10px 10px;
    font-size: 12px !important;
  }
  .searchbox-wrap {
    width: 430px;
  }
  .section7 .duieghrrr_content {
    padding: 0 0 0rem 0;
  }
  .regis_background {
    padding: 8rem 0 0 0;
  }

  .container-fluid.px-5 {
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
  }

  .kfhdfuygfgghfg ul.tabs li {
    font-size: 15px;
    padding: 8px 13px;
  }
  .fughyfgfgf {
    margin: 0 !important;
  }
  .drhuydgfth {
    margin: 0 !important;
    margin-bottom: 30px !important;
  }

  .tjbghdfhbgfgf {
    display: block !important;
  }

  .dfhjbghfjgfgh22 h4 {
    padding-top: 10px;
  }
  .aslkhghj2 {
    padding: 40px 0;
  }

  .gkbfgbg {
    height: 300px;
  }

  .fffddf {
    height: 250px;
  }
  .ndfhjvdfv h2 {
    line-height: 35px;
  }
  .njfgfd {
    height: 300px;
  }

  .gjnjfghg h2 {
    font-size: 1.4rem;
  }

  .njfgfghf .btn-main {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 414px) {
  .searchbox-wrap {
    width: 390px;
  }
  .searchbox-wrap button {
    margin-left: -50px;
  }
  .section7 {
    height: 80vh;
  }

  .ihurtf_btn {
    display: flex;
  }

  .ihurtf_btn .btn {
    font-size: 12px !important;
    margin-right: 5px;
  }

  .dskjdfhgdfgfgfd h2 {
    padding-top: 5rem;
  }

  h1 {
    font-size: 2rem;
  }

  .fgnhjghjgh {
    display: block;
  }
  .fgnhjghjgh span {
    display: block;
    margin: 8px 0;
  }

  .banner .banner_content .iduherr_left #typing {
    font-size: 1.2rem;
  }

  .tabs__buttons--container {
    display: block;
  }

  .tabs__tab-btn {
    width: 100%;
    margin: 0 0 1rem 0 !important;
  }

  .dieirherr_btn .sfgrwwe_btn {
    width: 12.5rem !important;
  }

  #fuygernert {
    width: auto !important;
  }

  .sfgrwwe_btn .select-selected {
    font-size: 1rem;
  }

  .csdfygtyy .cfbghed {
    font-size: 1.2rem !important;
  }

  .kfhdfuygfgghfg ul.tabs li {
    padding: 8px 14px;
  }

  .digheirer h4:before,
  .digheirer h4:after {
    width: 2rem;
  }
}

@media only screen and (max-width: 393px) {
  main .s-4 .s-4-wrapper .iduhrrrr_content .uigerr_inner .nav .nav-link {
    padding: 0 0.5rem !important;
  }

  main .s-2 .uirhtt_wrapper .nav img {
    width: 78%;
  }

  main
    .s-4
    .s-4-wrapper
    .iduhrrrr_content
    .uigerr_inner
    .tab-content
    .idheuirr__inner
    img {
    border-radius: 30px;
  }

  .iuheirhggg_ihberr {
    width: 100%;
  }

  .ugenhuhrtniu {
    width: 0;
  }
  .kfhdfuygfgghfg ul.tabs li {
    font-size: 14px;
    padding: 8px 11px !important;
  }

  .price-input {
    display: block !important;
  }

  .price-input .separator {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  .searchbox-wrap {
    width: 360px;
  }
  .searchbox-wrap input {
    padding: 15px 5px;
  }
  .searchbox-wrap button {
    margin-left: -45px;
  }
}

@media only screen and (max-width: 360px) {
  main .s-2 .uirhtt_wrapper .nav img {
    width: 86%;
  }

  main .s-2 .uirhtt_wrapper .nav .nav-link {
    padding: 6px 6px !important;
  }

  main .s-3 .s-3-wrapper .iduhrrrr_content {
    padding: 6rem 0 20rem 0;
  }

  .iduhrrrr_content .col-lg-11.mb-5 {
    margin-bottom: 0.5rem !important;
  }

  .duegrrr_inner.mt-5 {
    margin-top: 0.5rem !important;
  }

  .dfghdfg {
    height: 340px;
  }

  .dnhghjdfgdfg {
    height: 400px;
  }

  .section4 .uihderrr .tab-container .uydwgeaheoiwee {
    width: 12rem;
    height: 12rem;
  }

  .section4 .uihderrr .tab {
    width: 5rem;
    height: 5rem;
  }

  .section4 .uihderrr .tab .ugdeerrf h5 {
    font-size: 1rem;
  }

  .section4 .uihderrr .tab .ugdeerrf h6 {
    font-size: 0.6rem;
  }

  .section4 .uihderrr .tab .ugdeerrf i {
    font-size: 1.2rem;
  }

  .gjnjfghg h2 {
    font-size: 1.4rem;
  }

  .res-db-sidebar .md-header .difuhtre_nav button h5 {
    opacity: 0;
  }

  .res-db-sidebar .md-header {
    padding-top: 5rem;
  }
}

@media only screen and (max-width: 359px) {
  .mbpm .fgnhjghjgh {
    display: block;
  }

  .mbpm .ihceuirr h4 {
    margin-bottom: 10px !important;
  }

  .mbpm .fgnhjghjgh p {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 342px) {
  header .navbar-brand img {
    width: 5rem;
    max-width: 100%;
  }

  .banner .banner_content {
    padding: 8rem 0 2rem 0;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 1.2rem !important;
  }

  .banner .banner_content .iduherr_left #typing,
  p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 305px) {
  .duiegrrrr_wrapper.px-5 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .djbgdfdfgh.d-flex {
    display: block !important;
  }

  .gnhjbggf {
    margin-top: 0;
  }
}

@media only screen and (max-width: 292px) {
  main .s-2 .uirhtt_wrapper .nav .nav-link {
    font-size: 0.5rem;
  }

  .gjnjfghg h2 {
    font-size: 1.2rem;
  }

  .njfgfghf i {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }

  .uihderr_slider {
    width: initial;
  }
}

@media only screen and (max-width: 280px) {
  .jbdgfdsf {
    height: 240px;
  }
  .djbgdfdfgh.d-flex {
    display: block !important;
  }

  .gnhjbggf {
    margin-top: 0;
  }
  .kjdhfdf {
    height: 180px;
  }
  .dfghdfg {
    height: 270px;
  }

  .dnhghjdfgdfg {
    height: 300px;
  }
  .searchbox-wrap {
    width: 290px;
  }
  .searchbox-wrap button {
    margin-left: -115px;
    font-size: 10px !important;
  }
  .searchbox-wrap button::placeholder {
    font-size: 10px !important;
  }
  p {
    font-size: 14px !important;
    line-height: 19px !important;
  }

  .kfghg {
    height: 180px !important;
  }
  .btn-main {
    padding: 6px 8px;
    font-size: 14px !important;
  }

  .iijieirr_left {
    width: 8rem;
    height: 8rem;
    border: 4px solid white;
  }

  .ihurtf_btn button {
    font-size: 10px !important;
  }
  .ihurtf_btn .btn i {
    font-size: 12px !important;
  }
  .hjgfdfg {
    min-height: 0px;
    max-height: 0px;
    line-height: 0px;
  }

  .ngdfhgfdg {
    padding: 15px;
  }
  table,
  th {
    font-size: 13px !important;
  }

  .mbpm .djbgdfdfgh.d-flex {
    display: block !important;
  }

  .mbpm .gnhjbggf {
    margin: 10px 0;
  }
}
