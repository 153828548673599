.otp-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 3%;
  border-radius: 10px;
}

.otp-button.send {
  background-color: #3b82f6;
  color: white;
}

.otp-button.sended {
  background-color: #10b981;
  color: white;
}

.otp-button.loading {
  background-color: #d1d5db;
  cursor: not-allowed;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.PhoneNoOtpInput {
  margin-top: 15px;
  width: 30%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.otp-buttonVerify {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 3%;
  border-radius: 10px;
}

.otp-buttonVerify.Verify {
  background-color: #3b82f6;
  color: white;
}

.otp-buttonVerify.Verified {
  background-color: #10b981;
  color: white;
}

.otp-buttonVerify.loadingVerify {
  background-color: #d1d5db;
  cursor: not-allowed;
}

.button-contentVerify {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.spinnerVerifyOTP {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}
.aftersendOTP {
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
}

.resendOtpBtn{
  margin-left: 20px;
  font-size: 11px;
}
