#MentorCoverPicture {
  background: url("../../../../Images/Mentors/ddd.png") no-repeat;
  background-size: "cover";
}

.huygggfhfg {
  width: 100%;
  padding: 40px 0;
}

.hfgdfgfdf53564 {
  display: flex;
  align-items: center;
}

.gkfhjg5559 {
  margin-left: 20px;
}

.fhjgfg i {
  color: rgb(255, 255, 255);
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  background-color: #ebfaf8;
  border-radius: 50%;
  background-color: red;
}

.gkfhjg5559 h5 {
  font-size: 18px;
  font-weight: 600;
  padding-top: 5px;
  margin-bottom: 0;
}
.gkfhjg5559 p {
  font-size: 16px;
  line-height: 22px;
  color: gray;
  margin-bottom: 0;
}
.gkfhjg5559 span {
  font-size: 14px;
  font-weight: 600;
  color: red;
}

.dfghjdfdf P {
  font-size: 14px;
  color: gray;
  font-weight: 500;
  padding-top: -10px;
  text-align: right;
}

.dbhfhdfgfgf {
  margin-top: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.nxhjfdffgf5548 {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  padding: 15px;
}
.btnhd22 {
  border: none;
  background-color: #03a96c;
  color: white;
  font-size: 14px !important;
  padding: 7px 20px;
  border-radius: 4px;
  font-weight: 600;
}

.btnhd22a {
  border: none;
  background-color: #d61604;
  color: white;
  font-size: 14px !important;
  padding: 7px 20px;
  border-radius: 4px;
  font-weight: 600;
}

.btnhd22b {
  border: none;
  background-color: #00cfc8;
  color: white;
  font-size: 14px !important;
  padding: 7px 20px;
  border-radius: 4px;
  font-weight: 600;
}

.btnhd22c {
  border: none;
  background-color: #601efa !important;
  color: white;
  font-size: 14px !important;
  padding: 7px 20px;
  border-radius: 4px;
  font-weight: 600;
}

.downarrowsize {
  font-size: 12px;
  margin-left: 8px;
}
.col-lg-10 {
  width: 100%;
}

.Baseposition {
  position: relative;
}

.nfhgfg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 40px 0 0;
}

.submenu_sub {
  position: absolute;
  top: 4rem;
  left: 7rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 2px;
  border-radius: 8px;
}

.submenu_sub1 {
  width: 100%;
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
  border: white;
  border: 1px solid white;
  border-radius: 5px;
}

.submenu_sub1:hover {
  background-color: #7fa5ff;
  color: white;
}

.submenu_sub2 {
  position: absolute;
  top: 8rem;
  left: 7rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 2px;
  border-radius: 8px;
}
