.gnhvgfdfgfdgfd {
  width: 100%;
  padding: 40px 0;
  margin-top: 100px;
}

.gjdfhu545 label {
  font-weight: 600;
  font-size: 16px;
  color: #636161;
}

.fhguygdfg .form-control {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  padding: 9px;
}

.gjdfhu545 {
  margin-bottom: 7px;
}

.dfkjghjdffg {
  display: flex;
  padding: 0 10px;
  margin: 0;
  justify-content: space-between;
}
.dfkjghjdffg h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
}
.dfkjghjdffg p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: gray;
}

.hrgfg {
  margin: 0 !important;
}

.nfghjf h4 {
  color: #292828;
  padding-bottom: 5px;
  font-size: 22px;
}
.nfghjf p {
  font-size: 17px;
  color: gray;
  line-height: 25px;
}

.nhjfgf p {
  line-height: 24px;
}

.nhfd25 {
  width: 100%;
  height: 110px;
}
.nhfd25 img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.jfhgvf {
  margin-top: 50px;
}

.fhghfg {
  width: 100%;
  height: 140px;
}
.fhghfg img {
  width: 100%;
  height: 100%;
}

.fbghdfgdfg h3 {
  font-size: 24px;
  margin-top: 10px;
}

.dfbdfgfdf {
  margin-top: 10px;
}

.dfbdfgfdf h4 {
  font-size: 24px;
}

.fhfbfghg button {
  padding: 6px 10px;
  margin: 5px 3px;
  border: 1px solid rgb(216, 214, 214);
  border-radius: 6px;
  font-family: var(--font-family-lato);
  color: #444444;
  font-weight: 600;
}
