/* 
.aslkhghj2 {
  padding: 100px 0;
  background: url("../../Images/Courses/vbvb2.png") no-repeat;
  background-color: #fff;
  background-size: cover;
}

.jgfgfg i {
  padding: 0 5px;
}

.kjfghdgvfgfghfh {
  width: 100%;
  padding: 40px 0;
}

.kdrhgjddf {
  display: flex;
  align-items: baseline;
}
.kdrhgjddf p {
  padding-right: 10px;
  color: gray;
  padding-top: 15px;
}
.kdrhgjddf h6 {
  padding-right: 10px;
  font-weight: 600;
  font-size: 17px;
  color: var(--main-orange-color);
}
.kdrhgjddf span {
  color: var(--main-orange-color) !important;
}

.spmjj {
  color: var(--main-orange-color) !important;
  font-weight: bold;
}

.jdfhygdff {
  padding: 15px;
  border-radius: 50px;
}

.nghjgfg55 {
  background-color: var(--main-orange-color);
  width: 100%;
  padding: 40px 30px;
  height: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.kdfhfgfg55 p {
  width: 80px;
  height: 25px;
  background-color: #fff;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 500 !important;
  line-height: 25px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding-left: 10px;
}

.mdfhjgbg h2 {
  font-size: 27px;
  color: white;
  font-weight: 500;
  margin-bottom: 0;
}
.mdfhjgbg span {
  font-size: 14px;
  color: white;
}
.mdfhjgbg p {
  margin-bottom: 0;
}

.jufbgyfr p {
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.kjhgdfgfd2 {
  display: flex;
}
.kjhgdfgfd2 p {
  padding-right: 15px;
  color: rgb(255, 255, 255);
  height: 25px !important;
}

.kjghgdg button {
  padding: 10px 15px;
  border: none;
  font-weight: 600;
  border-radius: 25px;
  margin-top: 10px;
}
.kjghgdg button i {
  padding-left: 5px;
  color: var(--main-orange-color);
}

.fgfdg h2 {
  font-size: 35px;
  padding: 10px;
}

.nhjfg i {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--main-orange-color);
}

.nhjfg .form-control {
  border-radius: 50px;
}
 */

.practywiz-training-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 3rem 24px;
  margin-top: 3rem;
}

.practywiz-training-header {
  text-align: center;
  margin-bottom: 3rem;
}

.practywiz-training-header h1 {
  font-size: 2.5rem;
  color: #1a1a1a;
  margin: 0 0 0.5rem;
}

.practywiz-training-header p {
  color: #666;
}

.practywiz-training-layout {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 2rem;
}

.practywiz-training-sidebar {
  position: sticky;

  top: 6rem;
  height: fit-content;
}

.practywiz-training-search {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.practywiz-training-search input {
  padding: 0.75rem;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.practywiz-training-search button {
  width: 100%;
  padding: 0.75rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.practywiz-training-categories h2 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.practywiz-training-categories ul {
  list-style: none;
  padding: 0;
}

.practywiz-training-categories li {
  padding: 0.5rem 0;
  color: #666;
  cursor: pointer;
}

.practywiz-training-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.practywiz-training-card {
  border: 1px solid #e1e1e1;
  border-radius: 1rem;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
}

.practywiz-training-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.practywiz-training-card-image {
  position: relative;
}

.practywiz-training-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.practywiz-training-duration {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
}

.practywiz-training-card-content {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
.practywiz-training-card-body {
  flex-grow: 1;
}

.practywiz-training-card-category {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #e3f2fd;
  color: #1976d2;
  border-radius: 1rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.practywiz-training-card-content h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.practywiz-training-card-content p {
  color: #666;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.practywiz-training-instructor {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e1e1e1;
}

.practywiz-training-instructor-avatar img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.practywiz-training-instructor-info {
  display: flex;
  flex-direction: column;
}

.practywiz-training-instructor-info strong {
  color: #1a1a1a;
}

.practywiz-training-instructor-info span {
  color: #666;
  font-size: 0.875rem;
}

.practywiz-training-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.practywiz-training-rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.practywiz-training-stars {
  display: flex;
  gap: 0.25rem;
}

.practywiz-training-students {
  color: #666;
  font-size: 0.875rem;
}

.practywiz-training-price {
  font-weight: bold;
  color: #1976d2;
}

@media (max-width: 1024px) {
  .practywiz-training-layout {
    grid-template-columns: 200px 1fr;
  }
}

@media (max-width: 768px) {
  .practywiz-training-layout {
    grid-template-columns: 1fr;
  }

  .practywiz-training-sidebar {
    position: static;
    margin-bottom: 2rem;
  }

  .practywiz-training-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .practywiz-training-container {
    padding: 1rem;
  }

  .practywiz-training-header h1 {
    font-size: 2rem;
  }

  .practywiz-training-grid {
    grid-template-columns: 1fr;
  }
}
