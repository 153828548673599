.partner_slider-section {
  width: 100%;
  background-color: white;
  padding: 3rem 24px;
  overflow: hidden;
}

.partner_slider-container {
  max-width: 1300px;
  margin: 0 auto;
}

.partner_slider-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: #333;
}

.partner_slider {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 20px 0;
}

.partner_slider {
  position: relative;
}

.partner_slider::before,
.partner_slider::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  z-index: 2;
  pointer-events: none;
}

.partner_slider::before {
  left: 0;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}

.partner_slider::after {
  right: 0;
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}

.partner_slider-track {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: calc(200%);
  animation: scroll 30s linear infinite;
}

.partner_slider-item {
  flex: 0 0 auto;
  padding: 20px 40px;
  border-radius: 8px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: transform 0.3s ease;
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner_slider-item:hover {
  transform: translateY(-5px);
}

.partner_slider-logo {
  width: 120px;
  height: 40px;
  object-fit: contain;
  transition: all 0.3s ease;
}

.partner_slider-item:hover .partner_slider-logo {
  filter: grayscale(0%);
  opacity: 1;
}

@keyframes scroll-right {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@media (max-width: 1024px) {
  .partner_slider-track {
    gap: 2rem;
  }

  .partner_slider-item {
    padding: 20px 30px;
    width: 180px;
    height: 70px;
  }

  .partner_slider-logo {
    width: 110px;
    height: 35px;
  }

  /* Adjust gradient width for smaller screens */
  .partner_slider::before,
  .partner_slider::after {
    width: 90px;
  }
}

@media (max-width: 768px) {
  .partner_slider-title {
    text-align: center;
    margin-bottom: 30px;
  }

  .partner_slider-track {
    gap: 2rem;
  }

  .partner_slider-item {
    padding: 15px 30px;
    width: 160px;
    height: 70px;
  }

  .partner_slider-logo {
    width: 100px;
    height: 35px;
  }

  /* Adjust gradient width for mobile */
  .partner_slider::before,
  .partner_slider::after {
    width: 100px;
  }
}

@media (max-width: 480px) {
  .partner_slider-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .partner_slider-track {
    gap: 1.5rem;
  }

  .partner_slider-item {
    padding: 10px 20px;
    width: 140px;
    height: 60px;
  }

  .partner_slider-logo {
    width: 90px;
    height: 30px;
  }

  /* Adjust gradient width for smaller mobile */
  .partner_slider::before,
  .partner_slider::after {
    width: 70px;
  }
}
