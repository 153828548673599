.jkejd {
  display: flex;
  flex-direction: column;
}
.dwdyjw {
  width: 75%;
  margin-top: 10px;
  display: flex;
  margin-left: 6px;
  gap: 30px;
  align-items: center;
}
.yehdggf {
  margin-top: 20px;
}
.htbjsjg {
  height: fit-content;
  display: flex;
  justify-content: end;
  width: 100%;
}
.dkjiherer input[type="checkbox"] + label {
  border-radius: 10px;
}
.ihduwfr_form_wrapper .form-control {
  border: 1px solid #acaeaf !important;
}
.dwdyfdwjw {
  width: 85%;
  margin-top: 20px;
  display: flex;
  margin-left: 6px;
  gap: 20px;
  align-items: center;
}
.thdyefbfe {
  display: flex;
  gap: 20px;
}
.dhjwwdk {
  display: flex;
  gap: 15px;
}
.intershipPostdate {
  width: 50%;
}
.intershipWidth {
  width: 33%;
}
.SpantopMagine {
  margin-top: 10px;
}
.PostintershipTypecss {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.internshipCheckBox {
  width: 20px;
}

.postintern-perk {
  width: 40%;
  margin-left: 15px;
}

.toggle-container {
  display: flex;
  border: 2px solid #ccc;
  border-radius: 15px;
  overflow: hidden;
  width: 50%;
  margin: 20px;
}

.toggle-button {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button.active {
  background-color: #007bff;
  color: white;
  font-family: "Lato", sans-serif;
}

.toggle-button.inactive {
  background-color: white;
  color: black;
  font-family: "Lato", sans-serif;
}

.postinternAling {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.ql-editor {
  min-height: 18em;
  max-height: 18em;
}

.ql-toolbar {
  background-color: #fcfcfc;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.ql-container {
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.internal-description {
  margin-top: 20px;
  font-size: 14px;
  color: #287dc7;
}

.form-container {
  width: 100%;
  margin: 1px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 24px;
  position: relative;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.form-select {
  width: 100%;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 8px;
}

.form-select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.form-textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
}

.form-textarea:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.error-input {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 4px;
  display: block;
}
.Inernship_form .react-tel-input .form-control {
  background-color: #e9ecef !important;
}

@media screen and (max-width: 992px) {
  .intershipWidth {
    width: 100%;
  }
  .postintern-perk {
    width: 100%;
    margin-left: 0;
  }
  .toggle-container {
    width: 100%;
  }
  .toggle-button {
    padding: 10px 0;
  }
  .postinternAling {
    margin: 20px 0;
  }
  .ql-editor {
    min-height: 12em;
    max-height: 12em;
  }
}

@media screen and (max-width: 768px) {
  .intershipPostdate {
    width: 100%;
  }
  .intershipWidth {
    width: 100%;
  }
  .postintern-perk {
    width: 100%;
    margin-left: 0;
  }
  .toggle-container {
    width: 100%;
    border-radius: 1.5rem;
  }
  .toggle-button {
    padding: 10px 15px;
  }
}

/* Stipend form container */
.internship-post-page-stipend-container {
  width: 100%;
  margin-bottom: 20px;
}

/* Row for inputs */
.internship-post-page-input-row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 15px;
}

/* Individual input group */
.internship-post-page-input-group {
  flex: 1;
  min-width: 0; /* Prevents flex items from overflowing */
}

/* Input styling */
.internship-post-page-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
}

/* Select styling to match other inputs */
.internship-post-page-select {
  width: 100%;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 14px;
}

/* Error message styling */
.internship-post-page-error {
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
}

/* Submit button styling */
.internship-post-page-submit-btn {
  background-color: #0d6efd;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.internship-post-page-submit-btn:hover {
  background-color: #0b5ed7;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .internship-post-page-input-row {
    flex-direction: column;
    gap: 10px;
  }
}
