.practywiz-about-container {
  min-height: 100vh;
  margin-top: 3rem;
  background-color: #ffffff;
}

.practywiz-about-section-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.practywiz-about-icon-container {
  background-color: #dbeafe;
  padding: 0.75rem;
  border-radius: 0.75rem;
  display: inline-flex;
  margin-bottom: 1rem;
}

.practywiz-about-icon {
  font-size: 1rem;
  text-align: center;
  color: #2563eb;
}

.practywiz-about-section-title {
  font-size: 1.875rem;
  font-weight: bold;
  text-align: center;
  color: #111827;
  margin-bottom: 3rem;
}

.practywiz-about-hero-section {
  width: 100%;
  min-height: 90vh;
  max-width: 100vw;
  overflow: hidden;
  align-content: center;
}

.practywiz-about-hero-container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 4rem 2.5rem;
  justify-content: space-between;
  gap: 4rem;
}

.practywiz-about-hero-content {
  flex: 1;
  max-width: 600px;
  flex-direction: column;
}

.practywiz-about-hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.practywiz-about-hero-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #000;
}

.practywiz-about-hero-tagline {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.practywiz-about-hero-text {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #555;
  max-width: 500px;
}

.practywiz-about-hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.practywiz-about-hero-img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  width: 100%;
  border-radius: 1rem;
}

.practywiz-about-vn-container {
  display: flex;
  gap: 2rem;
  max-width: 1300px;
  margin: 0 auto;
  padding: 2rem;
  align-items: center;
}

.practywiz-about-vn-illustration {
  flex: 1;
  max-width: 40%;
}

.practywiz-about-vn-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 2rem;
}

.practywiz-about-vn-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.practywiz-about-vn-card {
  background-color: #eff6ff;
  padding: 2rem;
  border-radius: 1rem;
  transition: all 0.3s ease;
}

.practywiz-about-vn-card:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  cursor: default;
}

.practywiz-about-vn-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 0.75rem 0;
  color: #1a1a1a;
}

.practywiz-about-vn-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a4a4a;
  margin: 0;
}

.practywiz-about-founder {
  background-color: #f9fafb;
}

.practywiz-about-founder-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: center;
  margin-top: 3rem;
}

.practywiz-about-founder-text h3 {
  font-size: 2.25rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 1.5rem;
}

.practywiz-about-founder-quote {
  position: relative;
  margin-bottom: 1.5rem;
}

.practywiz-about-founder-quote::before {
  content: '"';
  position: absolute;
  left: -2rem;
  top: 0;
  font-size: 4rem;
  color: #2563eb;
  opacity: 0.5;
  line-height: 1;
}

.practywiz-about-founder-quote p {
  font-size: 1.25rem;
  color: #4b5563;
  line-height: 1.75;
}

.practywiz-about-founder-description p {
  color: #4b5563;
  margin-bottom: 1rem;
  line-height: 1.75;
}

.practywiz-about-founder-image {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.practywiz-about-founder-image-container {
  position: relative;
  max-width: 400px;
}

.practywiz-about-founder-image-container::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: #2563eb;
  border-radius: 1rem;
  transform: rotate(6deg);
}

.practywiz-about-founder-image-container img {
  position: relative;
  z-index: 1;
  border-radius: 1rem;
  width: 100%;
  height: auto;
  transform: rotate(-3deg);
  transition: transform 0.3s ease;
}

.practywiz-about-founder-image-container:hover img {
  transform: rotate(0);
}
.practywiz-about-unique {
  background-color: #ffffff;
}

.practywiz-about-unique-content {
  background-color: #eff6ff;
  padding: 2rem;
  border-radius: 1rem;
}

.practywiz-about-unique-header h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 1rem;
}

.practywiz-about-unique-text {
  color: #4b5563;
  line-height: 1.75;
}

.practywiz-about-unique-text p {
  margin-bottom: 1rem;
}

.practywiz-about-unique-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: center;
}

.practywiz-about-unique-text-content {
  flex: 1;
}

.practywiz-about-unique-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.practywiz-about-unique-image img {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
}

.practywiz-about-offer-grid-our-offer {
  padding: 3rem 0;
  background-color: #ffffff;
}

.practywiz-about-offer-grid-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2.5rem;
}

.practywiz-about-offer-grid-section-title {
  text-align: center;
  color: #1e40af;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 32px;
}

.practywiz-about-offer-grid-main-title {
  color: #2563eb;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 8px;
}

.practywiz-about-offer-grid-subtitle {
  font-size: 24px;
  font-weight: 600;
  color: #374151;
  margin-bottom: 8px;
}

.practywiz-about-offer-grid-grid-top,
.practywiz-about-offer-grid-grid-bottom {
  display: grid;
  gap: 24px;
  margin-bottom: 24px;
}

.practywiz-about-offer-grid-grid-top {
  grid-template-columns: 58.33% 41.67%;
}

.practywiz-about-offer-grid-grid-bottom {
  grid-template-columns: 55% 45%;
}

.practywiz-about-offer-grid-section {
  padding: 24px;
  border-radius: 8px;
  height: 100%;
  transition: all 0.3s ease;
}

.practywiz-about-offer-grid-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.practywiz-about-offer-grid-section h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 16px;
}

.practywiz-about-offer-grid-mentors {
  background-color: #eff6ff;
}

.practywiz-about-offer-grid-students {
  background-color: #f0fdf4;
}

.practywiz-about-offer-grid-institutes {
  background-color: #fefce8;
}

.practywiz-about-offer-grid-forall {
  background-color: #f5f3ff;
}

.practywiz-about-offer-grid-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.practywiz-about-offer-grid-section li {
  display: flex;
  align-items: center;
  color: #4b5563;
  margin-bottom: 12px;
  line-height: 1.5;
}

.practywiz-about-offer-grid-section li::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
}

.practywiz-about-offer-grid-mentors li::before {
  background-color: #2563eb;
}

.practywiz-about-offer-grid-students li::before {
  background-color: #22c55e;
}

.practywiz-about-offer-grid-institutes li::before {
  background-color: #eab308;
}

.practywiz-about-offer-grid-forall li::before {
  background-color: #7c3aed;
}

.practywiz-about-offer-grid-two-cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.practywiz-about-timeline-section {
  padding: 6rem 2rem;
  background: #f9fafb;
}

.practywiz-about-timeline-title {
  font-size: 3rem;
  font-weight: 200;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 6rem;
  letter-spacing: 1px;
}

.practywiz-about-timeline-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}

.practywiz-about-timeline-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #e0e0e0;
}

.practywiz-about-timeline-item {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 4rem;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease;
}

.practywiz-about-timeline-visible {
  opacity: 1;
  transform: translateY(0);
}

.practywiz-about-timeline-marker {
  position: absolute;
  left: -5px;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4a90e2;
  transition: all 0.3s ease;
}

.practywiz-about-timeline-item:hover .practywiz-about-timeline-marker {
  transform: scale(1.3);
  background: #357abd;
}

.practywiz-about-timeline-year {
  font-size: 0.875rem;
  font-weight: 500;
  color: #666;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
}

.practywiz-about-timeline-content {
  background: transparent;
  transition: transform 0.3s ease;
}

.practywiz-about-timeline-item:hover .practywiz-about-timeline-content {
  transform: translateX(10px);
}

.practywiz-about-timeline-subtitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin: 0 0 1rem 0;
}

.practywiz-about-timeline-text {
  font-size: 1rem;
  line-height: 1.7;
  color: #666;
  margin: 0;
  font-weight: 400;
}

@media (max-width: 1024px) {
  .practywiz-about-grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .practywiz-about-hero-title {
    font-size: 3.5rem;
  }

  .practywiz-about-hero-tagline {
    font-size: 1.75rem;
  }
}

@media (max-width: 768px) {
  .practywiz-about-hero-container {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }

  .practywiz-about-hero-content {
    max-width: 100%;
  }

  .practywiz-about-hero-text {
    margin: 0 auto;
  }

  .practywiz-about-hero-title {
    font-size: 3rem;
  }

  .practywiz-about-hero-text h1 {
    font-size: 3rem;
  }

  .practywiz-about-hero-text h2 {
    font-size: 1.5rem;
  }

  .practywiz-about-grid-2,
  .practywiz-about-grid-3,
  .practywiz-about-founder-content {
    grid-template-columns: 1fr;
  }

  .practywiz-about-section-container {
    padding: 3rem 2rem;
  }
  .practywiz-about-offer-grid-grid-top,
  .practywiz-about-offer-grid-grid-bottom {
    grid-template-columns: 1fr;
  }

  .practywiz-about-offer-grid-two-cols {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .practywiz-about-offer-grid-section {
    margin-bottom: 16px;
    padding: 3rem 2rem;
  }

  .practywiz-about-offer-grid-main-title {
    font-size: 32px;
  }

  .practywiz-about-offer-grid-subtitle {
    font-size: 20px;
  }

  .practywiz-about-founder-image-container img {
    max-width: 300px;
  }

  .practywiz-about-vn-container {
    flex-direction: column-reverse;
    padding: 1rem;
  }

  .practywiz-about-vn-illustration {
    max-width: 100%;
  }

  .practywiz-about-vn-card {
    padding: 1rem;
  }

  .practywiz-about-timeline-section {
    padding: 4rem 1rem;
  }

  .practywiz-about-timeline-title {
    font-size: 2.5rem;
    margin-bottom: 4rem;
  }

  .practywiz-about-timeline-container {
    padding-left: 1rem;
  }

  .practywiz-about-timeline-item {
    padding-left: 1.5rem;
  }

  .practywiz-about-unique-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .practywiz-about-unique-image {
    order: -1;
  }
}

@media (max-width: 480px) {
  .practywiz-about-hero-section {
    padding: 2rem 1rem;
  }

  .practywiz-about-hero-title {
    font-size: 2.5rem;
  }

  .practywiz-about-hero-tagline {
    font-size: 1.5rem;
  }

  .practywiz-about-hero-subtitle {
    font-size: 1.25rem;
  }

  .practywiz-about-hero-text {
    font-size: 1.1rem;
  }

  .practywiz-about-hero-text h1 {
    font-size: 2.5rem;
  }

  .practywiz-about-hero-text h2 {
    font-size: 1.25rem;
  }

  .practywiz-about-hero-text p {
    font-size: 1rem;
  }

  .practywiz-about-card,
  .practywiz-about-solution-card {
    padding: 1.5rem;
  }

  .practywiz-about-founder-quote::before {
    left: -1rem;
    font-size: 3rem;
  }

  .practywiz-about-offer-grid-container {
    padding: 0 16px;
  }

  .practywiz-about-offer-grid-section {
    padding: 20px;
  }

  .practywiz-about-offer-grid-main-title {
    font-size: 28px;
  }

  .practywiz-about-offer-grid-subtitle {
    font-size: 18px;
  }
}
