.faq-container {
  padding: 20px;
  width: 90%;
  margin: 5.5rem auto;
  font-family: "Roboto", sans-serif;
  background-color: #f7f9fc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.faq-header {
  text-align: center;
  color: #0073e6;
  font-size: 28px;
  border-bottom: 3px solid #0073e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.faq-list {
  list-style-type: none;
  padding: 0;
}

.faq-item {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-item.active {
  background-color: #f6f6f6;
  border-color: #0073e6;
  color: #000000;
}

.faq-question {
  margin: 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  margin-top: 10px;
  font-size: 16px;
}

.faq-arrow {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.faq-arrow.down {
  transform: rotate(225deg);
}
