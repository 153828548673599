@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("./custombs.css");
@import url("./responsive.css");
@import url("./OptionExtractor.css");
@import url("./mobile.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: clip;
}

input:disabled,
select:disabled,
textarea:disabled {
  cursor: not-allowed;
}
.iijieirr_left2 {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  border: 8px solid #fff;
  transform: translateY(1rem);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
:root {
  --main-orange-color: #0255ca;
  --font-family-lato: "Lato", sans-serif;
  --white-shade-linear-gradient: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #fff
  );
  --h1-custom-font-size: 55px;
  --light-main-background-color: #fafafa;
  --main-light-black-text-color: #404040;
  --light-grey-border: #b4b4b4;
  --light-orange-background-color: #ffeadf;
  --light-highlighted-text-color: #878787;
  --light-blue-border-color: #2dbedb;
}

h1 {
  font-family: var(--font-family-lato);
  line-height: 1.33;
  font-size: var(--h1-custom-font-size);
  font-weight: 600;
}

h2 {
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 1.22;
}

h3 {
  font-weight: bold;
  letter-spacing: 2px;
}

h4 {
  font-weight: 600;
  color: #000;
  font-size: 1.4rem;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-lato);
}

::-moz-placeholder {
  font-family: var(--font-family-lato);
  color: #9e9e9e !important;
}

::placeholder {
  font-family: var(--font-family-lato);
  color: #9e9e9e !important;
}

p,
li,
label {
  font-family: var(--font-family-lato);
  font-size: 1rem;
  color: var(--main-light-black-text-color);
  font-weight: 500;
  line-height: 1.78;
}

a {
  font-family: var(--font-family-lato);
  text-decoration: none !important;
  color: var(--light-black-text-color) !important;
}

.btn-main {
  cursor: pointer;
  border: none;
  background-color: var(--light-blue-border-color);
  color: #fff;
  padding: 10px 1.4rem;
  border-radius: 50px;
  font-size: 1rem !important;
  font-weight: 600;
  border: 2px solid #fff;
  font-family: var(--font-family-lato) !important;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.small-btn-main {
  cursor: pointer;
  border: none;
  background-color: var(--light-blue-border-color);
  color: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 12px !important;
  font-weight: 600;
  border: 2px solid #fff;
  font-family: var(--font-family-lato) !important;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.btn-main i {
  padding-left: 5px;
  transition: 0.3s ease-in-out;
}
.small-btn-main:hover,
.btn-main:hover {
  color: #fff;
  background: var(--main-orange-color);
  transition: 0.3s ease-in-out;
}
.mt-8 {
  padding-top: 6.5rem;
}
.btn:focus,
.form-control:focus {
  box-shadow: none !important;
}

.ihduwfr_form_wrapper .form-control {
  border: 1px dashed #acaeaf !important;
}

.fa-star {
  color: rgb(249, 195, 19);
}

.section-hor-gap {
  padding: 0 2rem;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

header nav {
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}
header #navbarSupportedContent {
  justify-content: flex-end;
}
.md-header header nav {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
header .navbar-brand img {
  width: 10rem;
  max-width: 100%;
}
header .navbar-nav .nav-link {
  text-transform: uppercase;

  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px !important;
  margin: 0px 10px;
}
header.headeractive .navbar-nav .nav-link {
  color: #000 !important;
}
header .iugeuirrr .udgehrr {
  border-right: 1px solid #cdcdcd;
}
header .iugeuirrr .udgehrr .fvjhdf_btn:hover {
  background-color: var(--main-orange-color) !important;
  border: 2px solid #fff;
  transition: 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
header .iugeuirrr .udgehrr .cdsfsdvnghff .form-control {
  border-radius: 25px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px rgba(0, 0, 0, 0.26);
  padding: 0.5rem 3.5rem 0.5rem 1.2rem;
  transition: 0.3s ease-in-out;
  font-family: var(--font-family-lato);
  font-weight: 600;
}
header .iugeuirrr .udgehrr .cdsfsdvnghff .form-control::-moz-placeholder {
  color: #505050 !important;
  font-weight: 600;
}
header .iugeuirrr .udgehrr .cdsfsdvnghff .form-control::placeholder {
  color: #505050 !important;
  font-weight: 600;
}
header .iugeuirrr .udgehrr .cdsfsdvnghff i {
  font-size: 1.4rem;
  right: 33px;
  top: 50%;
  transform: translateY(-50%);
  color: #505050 !important;
  cursor: pointer;
}
header .iugeuirrr .udgehrr:last-child {
  border-right: 0;
}
header .fvjhdf_btn {
  box-shadow: none;

  border: 2px solid #fff;
  margin: 0 5px;
}

.headeractive .navbar.bg-white {
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  background: #fff !important;
}
.headeractive .navbar.mt-2 {
  margin-top: 0 !important;
  transition: 0.3s ease-in-out;
}
.headeractive .navbar.mx-4 {
  margin-left: 0 !important;
  margin-right: 0 !important;
  transition: 0.3s ease-in-out;
}
.headeractive .iugeuirrr .udgehrr .cdsfsdvnghff .form-control {
  padding: 0.5rem 3.8rem 0.5rem 1.5rem;
  transition: 0.3s ease-in-out;
}

header .navbar.bg-white {
  background: #0255ca !important;
}

header #autosuggestions {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1000;
  background-color: #fff;
  width: calc(100% - 2.5rem);
  transform: translateX(-50%);
  border-radius: 0 0 5px 5px;
  border: 1px solid #cdcdcd;
  border-top: 0;
  font-family: var(--font-family-lato);
}
header .suggestion {
  padding: 8px 10px;
  cursor: pointer;
  font-family: var(--font-family-lato);
  border-bottom: 1px solid #cdcdcd;
  font-weight: 600;
}
header .suggestion:hover {
  background-color: #f0f0f0;
}

.banner {
  background: var(--main-orange-color);
}
.banner .banner_content {
  padding: 10rem 0 5rem 0;
}
.banner .banner_content .iduherr_left h1 {
  line-height: 1.4;
  letter-spacing: 1.5px;
  color: #fff;
}
.banner .banner_content .iduherr_left h1 span {
  color: var(--main-orange-color);
}

.banner .banner_content .iduherr_left #typing {
  height: 2rem;
  font-family: var(--font-family-lato);
  font-weight: 600;
  line-height: 1.4;
  font-size: 1.2rem;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;

  display: inline-block;
  padding-right: 10px;
}
.iduherr_left .btn {
  display: block;
  padding: 1rem 3rem;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 1.2rem !important;
}

.uidgher_circle {
  width: 3.5rem;
  height: 3.5rem;
  padding: 10px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid var(--light-blue-border-color);
  top: 6rem;
  left: -2rem;
  z-index: 9;
}

.diehjrrr_intern div:last-child img {
  left: -4rem;
  width: 58%;
  transform: rotate(343deg);
  top: -1rem;
}

.diehjsdrrr_intern .uidgher_circle {
  top: 18rem;
  right: -7rem;
  left: initial;
}

.diehjsdrrr_intern div:last-child img {
  width: 66%;
  right: -8rem;
  top: 8rem;
  transform: rotate(338deg);
}

.section2 {
  width: 100%;
  background: var(--light-main-background-color);
  position: relative;
}
.section2:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 3rem;
  background: var(--white-shade-linear-gradient);
  transform: rotate(180deg);
  top: 0;
  left: 0;
}
.section2:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3rem;
  background: var(--white-shade-linear-gradient);
  bottom: 0;
  left: 0;
}

.iheroijr_inner {
  border: 2px solid var(--light-blue-border-color);
  border-radius: 10px;
}

.doiuher_content {
  padding: 20px 20px;
}

.doiuher_content .profession {
  color: var(--main-orange-color);
}

.doiuher_content h4 {
  color: #2e3e64;
}

.khjfdgdf .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  border-radius: 50% !important;
  border: 2px solid var(--light-blue-border-color) !important;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.khjfdgdf .owl-nav button span {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ihdieruir_slider .owl-dots .owl-dot span {
  width: 1.6rem !important;
  height: 0.35rem !important;
  margin: 5px 5px !important;
}

.ihdieruir_slider .owl-dots .owl-dot.active span {
  background: var(--main-orange-color) !important;
}

.khjfdgdf .owl-nav button:hover {
  background: var(--light-blue-border-color) !important;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.khjfdgdf .owl-nav button.owl-prev {
  left: -5rem;
}

.khjfdgdf .owl-nav button.owl-next {
  right: -5rem;
}

.diegrher {
  height: 20rem;
  background: #bce3ff;
}

.diegrher img {
  margin: auto;
}

.ndfjgf h4 {
  margin-top: 1rem;
  letter-spacing: 1.2px;
  word-spacing: 4px;
  font-size: 1.3rem;
}

.hjfgdfgfg h2 {
  text-align: center;
  letter-spacing: 1px;
}
.hjfgdfgfg span {
  color: #ee6c29;
}

.jdhfhddf {
  width: 100%;
}
.jdhfhddf img {
  width: 100%;
  max-height: 100%;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 3px 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.mkjghfdfgg {
  width: 100%;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 7px 0 7px 7px;
  overflow: hidden;
}

.kjdrhgjdfgf {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #cacaca;
  border-right: 0;
  border-radius: 10px 0 0 0;
  width: 65%;
  margin-left: auto;
}
.kjdrhgjdfgf h3 {
  font-size: 20px;
}
.kjdrhgjdfgf .duiher {
  background: var(--main-orange-color);
  color: #fff;
  width: 50%;
  position: relative;
}
.kjdrhgjdfgf .duiher:after {
  position: absolute;
  content: "";
  border-top: 45px solid transparent;
  border-right: 25px solid #fff;
  border-bottom: 45px solid transparent;
  top: 0;
  right: 0;
}

.mfkjghhdfgd {
  width: 100%;
}
.mfkjghhdfgd img {
  width: 100% !important;
}

.fkjhgfj {
  text-align: center;
}
.fkjhgfj h4 {
  margin-bottom: 0;
  color: #000;
  padding-top: 10px;
  font-weight: 600;
}
.fkjhgfj p {
  margin: 5px 0;
  color: #444444;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.fkjhgfj i {
  font-size: 20px;
  color: rgb(248, 179, 16);
}

.owldihsard .owl-dots {
  margin-top: 1.4rem !important;
}

.owldihsard .owl-dots .owl-dot span {
  width: 2rem !important;
  height: 0.3rem !important;
  margin: 3px !important;
}

.owldihsard .owl-dots .owl-dot.active span {
  background: var(--main-orange-color) !important;
}

.khjfdgdf {
  width: 100%;
  padding: 40px 0;
}

.duihdda .uifrsdfff_circle {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  box-shadow: 0 4px 5px 1px rgba(0, 0, 0, 0.25);
}
.duihdda .uifrsdfff_circle img {
  width: 100%;
}

.jbhgjfg1 h4 {
  line-height: 40px;
}

.jhgdfg h5 {
  font-size: 20px;
}

.hbgfgfghf h2 {
  text-align: center;
}
.hbgfgfghf span {
  color: #ee6c29;
}

.uihfdff_right h5 {
  font-weight: 600;
  font-size: 1.2rem;
}

.uihfdff_right h6 i {
  font-size: 1rem;
}
.uihfdff_right p {
  font-size: 1rem;
  letter-spacing: 0.48px;
  text-align: left;
  color: #5a5a5a;
  font-weight: 600;
  border-bottom: 1px solid #e1e1e1;
}

.mfkjhgjfhf .tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.duiehrr_dropdwn ul {
  position: absolute;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.defdweregee:hover .edfrerteee_list {
  display: block !important;
}

.edfrerteee_list li {
  list-style: none;
  border-bottom: 1px solid #dbdbdb;
  transition: 0.3s ease-in-out;
  padding: 0 5px;
}

.edfrerteee_list li:last-child {
  border-bottom: none;
}

.edfrerteee_list li:hover {
  background: var(--main-orange-color);
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  color: #fff;
}

.duiehrr_dropdwn .tab-link {
  list-style: none;
  padding: 5px 5px;
  border-bottom: 1px solid #d5d5d5;
}

.ghudiahoidead .tabs .tab-link {
  color: #222;
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  background-color: #ffffff;
  border: 2px solid var(--main-orange-color);
  padding: 6px 15px !important;
  border-radius: 25px;
  margin-right: 15px;
  font-family: var(--font-family-lato);
  font-size: 17px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.ghudiahoidead .tabs .tab-link.cccccsa {
  border: none;
  background-color: var(--main-orange-color);
  color: #fff;
  padding: 6px 15px !important;
  border-radius: 25px;
  font-size: 17px !important;
  margin-top: 15px;
  border: 2px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.ghudiahoidead .tab-contentsa {
  display: none;
  padding: 15px;
  margin-top: 50px;
}

.ghudiahoidead .tab-contentsa.cccccsa {
  display: inherit;
}

.ghudiahoidead {
  text-align: center;
}

.udguafr_slider .uihderr img {
  width: 1rem !important;
}

.uihderr {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  padding: 2px 5px;
}

.udguafr_slider i {
  font-size: 0.8rem;
}

.udguafr_slider h5 {
  font-size: 1rem;
  font-style: italic;
  font-weight: 600;
  color: #434242;
}

.udguafr_slider button {
  font-size: 0.8rem !important;
  padding: 0.2rem 0.8rem;
}

.duigher_circle {
  position: relative;
  overflow: hidden;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 50%;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.diuh_image {
  width: 100% !important;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.udguafr_slider .owl-stage-outer {
  padding-bottom: 1rem;
}

.udguafr_slider .uihderr img {
  width: 2rem;
}

.uihderr_slider .duiereee {
  font-weight: 600;
  color: #444;
}

.uihderr_slider img {
  border-radius: 25%;
}

.section3 {
  width: 100%;
  background: var(--main-orange-color);
}

.section3 h2 {
  color: #fff;
  line-height: 1.5;
}

.idheirr_content p {
  color: #fff;
  margin: 1.2rem 0;
}

.cuierr_image img {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.uidghegbr__uerww {
  box-shadow: none !important;
}

.dieghrytretew_image {
  left: -6rem;
  top: -7rem;
}

.duihueirjt_image {
  transform: rotate(359deg);
  bottom: -7rem;
  right: -6rem;
}

.dertgrtyf_image {
  z-index: 9;
}

.idheirr_content h2 span {
  text-decoration: underline;
}

.diherrr_content h3 {
  color: var(--light-blue-border-color);
  position: relative;
  font-size: 1.4rem;
}

.diherrr_content h3:before {
  position: absolute;
  content: "";
  width: 4rem;
  height: 0.2rem;
  background: var(--light-blue-border-color);
  border-radius: 50px;
  top: 50%;
  transform: translate(-4rem, -50%);
}

.diherrr_content h3:after {
  position: absolute;
  content: "";
  width: 4rem;
  height: 0.2rem;
  background: var(--light-blue-border-color);
  border-radius: 50px;
  top: 50%;
  transform: translate(0, -50%);
}

.diherrr_content h3 span {
  border-radius: 50px;
  padding: 5px 1rem;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.tabs__buttons--container {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  margin-top: 20px;
}

.tabs__tab-btn {
  border: none;
  background-color: var(--main-orange-color);
  color: white;
  padding: 8px 25px;
  border-radius: 25px;
  font-weight: 600;
  font-family: var(--font-family-lato);
  font-size: 1.2rem !important;
  border: 2px solid #fff;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.tabs__tab-btn--not-selected {
  background-color: #ffffff;
  border: 2px solid var(--main-orange-color);
  color: rgb(0, 0, 0);
}

.tabs__tab--hide {
  display: none;
}

.tabs__tab--show {
  display: block;
}

.tabs__tab {
  animation: tabApear 0.6s;
}

@keyframes tabApear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.hjgfdfg {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  min-height: 125px;
  line-height: 125px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  max-height: 125px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.hjgfdfg img {
  max-width: 100%;
  max-height: 125px;
}

.kjdhhjgd {
  border-radius: 10px;

  border: solid 1px var(--light-grey-border);
}

.mhgf {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 2px solid var(--main-orange-color);
  cursor: pointer;
}
.mhgf i {
  top: 50%;
  left: 50%;
  font-size: 1rem;
  transform: translate(-50%, -50%);
  color: var(--main-orange-color);
}

.nfhjdfghfdghf .rlknjhgjfg {
  pointer-events: none;
  padding-right: 0.4rem;
}
.nfhjdfghfdghf .rlknjhgjfg i {
  color: var(--main-orange-color);
}
.nfhjdfghfdghf .rlknjhgjfg p {
  font-size: 0.9rem;
  letter-spacing: 0.36px;
  color: #878787;
  font-weight: 600;
}

.rlknjhgjfg1 {
  font-family: var(--font-family-lato);
  border: 0;
  height: 30px;
  border-radius: 50px;
  font-size: 0.8rem;
  font-weight: 600;
}

.oidefrgtry {
  background: var(--light-orange-background-color);
  color: #767676;
}

.ihjsifddd {
  background: #cff4ff;
  color: #1485a9;
}

.cdcdsgvdsd {
  background: #ccf2e4;
  color: #03a96c;
}

.hjgfjgfgf button {
  border-radius: 50px;
  border: 1px solid var(--light-grey-border);
  background: #fff;
  padding: 0.3rem 0.7rem;
  color: var(--light-highlighted-text-color);
  font-family: var(--font-family-lato);
  font-size: 0.9rem;
}

.mngjkfhg {
  text-align: center;
}

.section4 {
  width: 100%;
}
.section4 .uihderrr .tab-container .uydwgeaheoiwee {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  box-shadow: 0 4px 107px 2px rgba(238, 108, 41, 0.25);
  background-color: rgba(238, 108, 41, 0.21);
  margin: auto;
  transform: translateY(5rem);
}
.dihuiehr_circle {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: #f5fbff;
  position: relative;
  margin: auto;
  margin-bottom: 10px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.dihuiehr_circle img {
  width: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.section4 .uihderrr .tab {
  width: 8rem;
  height: 8rem;
  cursor: pointer;
  padding: 10px 20px;
  font-family: var(--font-family-lato);
  position: absolute;
  box-shadow: 0 0 22px 1px rgba(238, 108, 41, 0.25);
  background-color: #fff;
  border-radius: 50%;
  z-index: 9;
}
.section4 .uihderrr .tab .ugdeerrf {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.section4 .uihderrr .tab .ugdeerrf i {
  font-size: 1.6rem;
  margin-bottom: 5px;
  color: var(--main-orange-color);
}
.section4 .uihderrr .tab.active .ugdeerrf i {
  color: #fff;
}
.section4 .uihderrr .tab .ugdeerrf h5 {
  font-weight: 600;
  letter-spacing: 0.56px;
}
.section4 .uihderrr .tab .ugdeerrf h6 {
  font-size: 0.9rem;
}
.section4 .uihderrr .tab:nth-of-type(4n-3) {
  top: -3.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.section4 .uihderrr .tab:nth-of-type(4n-3):before {
  position: absolute;
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  box-shadow: 0 4px 10px 5px rgba(238, 108, 41, 0.25);
  border-radius: 50%;
  background-color: #ee6c29;
  left: -60%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.section4 .uihderrr .tab:nth-of-type(4n-2) {
  top: 50%;
  right: -3.5rem;
  transform: translateY(-50%);
}
.section4 .uihderrr .tab:nth-of-type(4n-2):before {
  position: absolute;
  content: "";
  width: 1.75rem;
  height: 1.75rem;
  box-shadow: 0 4px 10px 5px rgba(30, 191, 241, 0.25);
  background-color: #1ebff1;
  border-radius: 50%;
  right: 35%;
  top: -70%;
  transform: translate(-50%, -50%);
}
.section4 .uihderrr .tab:nth-of-type(4n-1) {
  bottom: -3.5rem;
  left: 54%;
  transform: translateX(-50%);
}
.section4 .uihderrr .tab:nth-of-type(4n-1):after {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  box-shadow: 0 4px 10px 5px rgba(238, 41, 183, 0.25);
  background-color: #ee29b7;
  border-radius: 50%;
  right: -75%;
  top: 15%;
  transform: translate(-50%, -50%);
}
.section4 .uihderrr .tab:nth-of-type(4n) {
  top: 46%;
  transform: translateY(-50%);
  left: -3.5rem;
}
.section4 .uihderrr .tab:nth-of-type(4n):after {
  position: absolute;
  content: "";
  width: 1.85rem;
  height: 1.85rem;
  box-shadow: 0 4px 10px 5px rgba(243, 189, 50, 0.25);
  background-color: #f3bd32;
  border-radius: 50%;
  right: 0;
  bottom: -100%;
  transform: translate(-50%, -50%);
}
.section4 .uihderrr .tab.active {
  background-color: var(--main-orange-color);
  color: #fff;
}
.section4 .uihderrr .tab-content {
  display: none;
}
.section4 .uihderrr .tab-content img {
  border-radius: 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.section4 .uihderrr .tab-content p {
  line-height: 1.83;
  letter-spacing: 1.2px;
  font-size: 1.2rem;
  color: var(--main-light-black-text-color);
}
.section4 .uihderrr .tab-content.active {
  display: block;
}

.bghfdg {
  text-align: center;
}
.bghfdg h2 span {
  color: var(--main-orange-color);
}
.bghfdg p {
  color: var(--main-light-black-text-color);
  font-weight: 600;
  letter-spacing: 1px;
}

.section5 {
  background: url("../Images/mentorbclk.png") no-repeat;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
}

.duygerre_partners .diuehrrtrer img {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.duygerre_partners .diuehrrtrer {
  padding: 10px;
}

.duygerre_partners .owl-stage-outer {
  padding-bottom: 30px;
}

.duygerre_partners .owl-nav {
  margin-top: 0 !important;
}

.duygerre_partners .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  border-radius: 50% !important;
  border: 2px solid var(--light-blue-border-color) !important;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.duygerre_partners .owl-nav button span {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.duygerre_partners .owl-dots .owl-dot span {
  width: 1.6rem !important;
  height: 0.35rem !important;
  margin: 5px 5px !important;
}

.duygerre_partners .owl-dots .owl-dot.active span {
  background: var(--main-orange-color) !important;
}

.duygerre_partners .owl-nav button:hover {
  background: var(--light-blue-border-color) !important;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.duygerre_partners .owl-nav button.owl-prev {
  left: -5rem;
}

.duygerre_partners .owl-nav button.owl-next {
  right: -5rem;
}

.section5::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 3rem;
  background: var(--white-shade-linear-gradient);
  transform: rotate(180deg);
  top: 0;
  left: 0;
}

.gnjhbff {
  text-align: center;
}
.gnjhbff h2 span {
  color: var(--main-orange-color);
}
.gnjhbff p {
  color: var(--main-light-black-text-color);
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 1px;
}

.mfkjhgjfhf {
  text-align: center;
}

.section6 {
  width: 100%;
  background: #f5fbff;
}

.iduherr_box {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.csdghkeetr i,
.csdghkeetr p {
  color: var(--main-orange-color);
}

.csdghkeetr p {
  font-weight: 600;
}

.deirorner_content .btn-main {
  color: var(--light-blue-border-color) !important;
  background: transparent !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.jnhfgj {
  text-align: center;
}
.jnhfgj h2 span {
  color: var(--main-orange-color);
}
.jnhfgj p {
  color: var(--main-light-black-text-color);
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.rkgjfg {
  width: 12.5rem;
  height: 4rem;
  background: var(--main-orange-color);
  border-radius: 50% 50% 10px 50%;
}
.rkgjfg .iudherr_circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.jhhjgfg h4 {
  font-size: 26px;
  line-height: 1.4;
}
.jhhjgfg p {
  font-size: 1.2rem;
  line-height: 1.9;
  color: var(--main-light-black-text-color);
}

.jhhjgf {
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.hhjdfgfd {
  margin-top: 25px;
}

.kjgbhjfgfd img img {
  width: 100%;
  height: 100%;
}

.hjgfg {
  padding: 10px 10px;
}
.hjgfg p {
  line-height: 1.9;
  letter-spacing: 0.54px;
  color: var(--main-light-black-text-color);
}

.dfnhjfg .ghbn {
  background: transparent;
  color: #000;
  border: solid 2px var(--main-orange-color);
  box-shadow: none;
}
.dfnhjfg .ghbn i {
  color: var(--main-orange-color);
}

.kjgbhjfgfd h4 {
  position: absolute;
  width: 3rem;
  height: 3rem;
  text-align: center;
  padding-top: 5px;
  color: #fff;
  background-color: var(--main-orange-color);
  border-radius: 25px 25px 0 25px;
  box-shadow: -4px -2px 4px 0 #fff;
  bottom: 0;
  right: 0;
  line-height: 1.8;
}

.section7 {
  position: relative;
  background: #00ced7;
}
.uiherr_wrapper h2,
.diehrer_inner p {
  color: #fff;
}

.uiherr_wrapper .btn-main {
  background: var(--main-orange-color);
  text-transform: capitalize;
  padding: 0.6rem 3rem;
  letter-spacing: 1px;
}
.section7 .duieghrrr_content {
  padding: 10rem 0 5rem 0;
}
.section7 .duieghrrr_content .dskjdfhgdfgfgfd h2 span {
  color: var(--main-orange-color);
}
.section7 .duieghrrr_content .dskjdfhgdfgfgfd p {
  font-weight: 600;
  line-height: 1.54;
  font-size: 1.2rem;
}
.section7 .duieghrrr_content .idcuhsewfrsedf .form-control {
  border-radius: 50px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px #dadada;
  padding: 1rem 10rem 1rem 3.5rem;
}
.section7 .duieghrrr_content .idcuhsewfrsedf .form-control::-moz-placeholder {
  color: #696969 !important;
  font-weight: 600;
}
.section7 .duieghrrr_content .idcuhsewfrsedf .form-control::placeholder {
  color: #696969 !important;
  font-weight: 600;
}
.section7 .duieghrrr_content .idcuhsewfrsedf i {
  font-size: 1.4rem;
  left: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--main-orange-color);
}
.section7 .duieghrrr_content .idcuhsewfrsedf button {
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
}

.nfhjhdfgdfgfgfg {
  width: 100%;
  margin-top: 100px;
  padding: 40px 0;
}

.jbdgfdsf {
  width: 100%;
  height: 400px;
  border-radius: 8px;
}
.jbdgfdsf img {
  width: 100%;
}

.bdhjgdf h6 {
  font-weight: 600;
}
.bdhjgdf h2 {
  font-size: 45px;
}
.bdhjgdf p {
  color: gray;
}

.mdbhcxvvx2 {
  width: 100%;
  padding: 40px 0;
  margin-bottom: 30px;
}

.jfhfbg {
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.kjdhfdf {
  width: 100%;
  height: 170px;
}
.kjdhfdf img {
  width: 100%;
  height: 100%;
}

.jgfbhfhgf {
  padding: 10px;
}
.jgfbhfhgf h4 {
  padding-top: 15px;
}
.jgfbhfhgf p {
  font-size: 1.1rem;
  line-height: 25px;
}

.dfjgkfg {
  padding: 30px 0;
}
.dfjgkfg h2 {
  text-align: center;
}

.lfmgkjdfg {
  text-align: center;
  padding: 30px 0;
}

.fgbfgfgfh h2 {
  font-size: 1.6rem;
}
.fgbfgfgfh h6 {
  font-style: italic;
  font-size: 1.2rem;
  font-weight: 600;
}
.fgbfgfgfh p {
  color: #000;
  font-size: 16px;
}

.dfghdfg {
  height: 420px;
  text-align: center;
}
.dfghdfg img {
  height: 100%;
}

.hjvfbvfvfgbhf {
  margin-bottom: 2rem;
}

.lkdhgjdfgdfgdfg {
  width: 100%;
  padding: 40px 0;
}

.kjdfhdfgfd {
  text-align: center;
  margin-bottom: 30px;
}
.kjdfhdfgfd span {
  color: var(--main-orange-color);
}

.fhjffs {
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 50px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.fhjffs img {
  width: 100%;
  height: 100%;
}

.hjfghfg {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 10px;
  margin-bottom: 24px;
  border-radius: 10px;
}

.kjdfghhdfg h4 {
  font-size: 22px;
  color: black;
}
.kjdfghhdfg p {
  font-size: 17px;
  color: gray;
}

.nfjgdfg {
  text-align: right;
}
.nfjgdfg button {
  border: none;
  font-weight: 600;
  color: #2e64e3;
  background: none;
  font-family: var(--font-family-lato);
}

.dhjdfhgdfgdfgdf {
  width: 100%;
  padding: 40px 0;
}

.fkjhfgfgh {
  margin-bottom: 30px;
  text-align: center;
}
.fkjhfgfgh span {
  color: var(--main-orange-color);
}

.kjdfhgjdfgf {
  width: 60px;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 50px;
  text-align: center;
  margin: auto;
  padding: 10px;
}
.kjdfhgjdfgf img {
  width: 100%;
  height: 100%;
}

.bfvdfgf {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 15px;
  margin: 15px;
  border-radius: 8px;
}

.fjgkfhg {
  text-align: center;
  margin-top: 10px;
}
.fjgkfhg h4 {
  font-size: 22px;
  font-weight: 600;
}
.fjgkfhg p {
  font-size: 16px;
  line-height: 26px;
}
.fjgkfhg button {
  margin-top: -15px;
}

.contactuss {
  width: 100%;
  background: url("../Images/map-pattern-2.png") no-repeat;
  background-size: 100% 100%;
  padding: 10rem 0 5rem 0;
}

.dnghjfgdf {
  display: flex;
  align-items: center;
}

.kjnghdfg {
  margin-left: 25px;
}
.kjnghdfg p {
  margin-bottom: 0;
}

.kjnghdfg2 i {
  color: white;
  padding: 15px;
  font-size: 25px;
  border-radius: 12%;
  background-color: var(--main-orange-color);
}

.hgjfgbfg {
  margin-top: 35px;
}

.kjnghdfg2 {
  margin-bottom: 0 !important;
}



.gfhgf {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.mjdfkjhgfg .form-control {
  padding: 10px !important;
}

.mjdfkjhgfg {
  margin-bottom: 10px;
}

.btnss {
  padding: 7px 25px;
  color: white;
  background-color: var(--main-orange-color);
  border: none;
  border-radius: 25px;
  margin-top: 10px;
  font-weight: 500;
}

.dnhghjdfgdfg {
  width: 100%;
  height: 80vh;
}

.aslkhghj {
  padding: 10rem 0 5rem 0;
  background: linear-gradient(
    90deg,
    rgb(45, 190, 219, 0.3) 20%,
    rgb(45, 190, 219, 0.2) 52%,
    rgb(45, 19, 201, 0.1) 92%
  );
}

.knjghjfggfgfghgh input {
  border: 1px solid #d9d9d9 !important;
  font-family: var(--font-family-lato);
}

.sfgrwwe_btn .select-selected {
  color: #000000;
  cursor: pointer;
  text-transform: initial;
  display: flex;
  align-items: center;
  color: #636363;
}

.sfgrwwe_btn .select-selected:before {
  position: relative;
  content: "\f161";
  font-family: "Font Awesome 6 Free";
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  background: var(--main-orange-color);
  display: -webkit-inline-box;
  color: #fff;
  margin-right: 0.5rem;
}

.sfgrwwe_btn .select-selected:after {
  border-color: #000 transparent transparent transparent;
  top: 1.2rem;
}

.sfgrwwe_btn .select-selected.select-arrow-active:after {
  border-color: transparent transparent #000 transparent;
  top: 0.8rem;
}

.cfbghed i {
  color: var(--main-orange-color);
}

.jgfgfg {
  text-align: center;
  margin-top: 30px;
}
.jgfgfg h2 {
  font-size: 40px;
}

.lkrhhdfgfdfgfhf {
  width: 100%;
  padding: 40px 0;
  margin-bottom: 8rem;
}

.khgjfgfhg2252 {
  background: #fafafa;
  padding: 1.2rem;
  height: 33rem;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.khgjfgfhg2252.sticky-top {
  top: 17%;
}

.dfdf2 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 4px;
}

.knjghjfggfgfghgh input[type="text"] {
  width: 100%;
  border: 1px solid #8b8989;
  border-radius: 4px;
  margin: 12px 0;
  outline: none;
  padding: 12px;
  box-sizing: border-box;
  transition: 0.3s;
}

.knjghjfggfgfghgh input[type="text"]::-moz-placeholder {
  font-size: 0.8rem !important;
  color: #636363 !important;
  font-family: var(--font-family-lato);
  font-weight: 500 !important;
}

.knjghjfggfgfghgh input[type="text"]::placeholder {
  font-size: 0.8rem !important;
  color: #636363 !important;
  font-family: var(--font-family-lato);
  font-weight: 500 !important;
  letter-spacing: 0.5px;
}

.knjghjfggfgfghgh input[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.knjghjfggfgfghgh .inputWithIcon input[type="text"] {
  padding-left: 2rem;
}

.knjghjfggfgfghgh .inputWithIcon {
  position: relative;
}

.knjghjfggfgfghgh .inputWithIcon i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  padding: 9px 8px;
  color: var(--main-orange-color);
  transition: 0.3s;
}

.knjghjfggfgfghgh .inputWithIcon input[type="text"]:focus + i {
  color: dodgerBlue;
}

.knjghjfggfgfghgh .inputWithIcon.inputIconBg i {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

.knjghjfggfgfghgh .inputWithIcon.inputIconBg input[type="text"]:focus + i {
  color: #fff;
  background-color: dodgerBlue;
}

.label2 {
  font-weight: 600;
  font-size: 1.2rem;
  font-family: var(--font-family-lato);
}

.knjghjfggfgfghgh {
  margin-bottom: 15px;
}

.njghbfgfhhg h4 {
  font-size: 1.2rem;
  margin: 1.2rem 0;
}

.khgjfgfhg2252 .knjghjfggfgfghgh:nth-of-type(3n-2) {
  padding: 1.4rem 0;
}

.toggleSwitch span span {
  display: none;
}

.toggleSwitch {
  margin-left: 0 !important;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 50px;
    cursor: pointer;
    width: 40px;
  }
  .toggleSwitch * {
    box-sizing: border-box;
  }
  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }
  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }
  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding-top: 2px !important;
    left: 2px;
    width: 17px;
    background-color: #fff;
    border: 2px solid var(--main-orange-color);
    border-radius: 100%;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    color: #ccc;
    opacity: 1;
    left: 45%;
  }
  .toggleSwitch > span:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #fff;
    border: 1px solid var(--main-orange-color);
    border-radius: 30px;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: var(--main-orange-color);
    box-shadow: inset 0 0 0 30px var(--main-orange-color);
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
}
.gghfthghdf {
  display: flex;
  align-items: baseline;
}

.khgjfgf {
  margin-left: 25px;
}
.khgjfgf p {
  color: gray;
  font-size: 16px;
}

.mkdfhgjgh h4,
.kjghfhgj h4 {
  font-size: 1.2rem;
  margin: 1.2rem 0;
}
.csdfygtyy {
  justify-content: end;
}

.csdfygtyy h5 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.dieirherr_btn {
  display: flex;
  justify-content: space-between;
}

.dieirherr_btn button {
  text-transform: initial !important;
  line-height: 2rem;
}

.dieirherr_btn .sfgrwwe_btn {
  background: #fff !important;
  color: #636363;
}

.kjhfhffd {
  display: flex;
  margin-top: 45px;
  align-items: baseline;
}

.mhgf1 i {
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  color: gray;
  border: 2px solid var(--main-orange-color);
  border-radius: 50px;
  margin-left: 15px;
}

.jhgjgf i {
  color: #ee6c29;
}

.kjdfvghjdrbgdfgfd {
  width: 100%;
  padding: 40px 0;
}

.lghjgt {
  margin-bottom: 25px;
}
.lghjgt h5 {
  font-size: 25px;
  color: black;
  font-weight: 600;
}
.lghjgt p {
  font-size: 16px;
  color: gray;
  line-height: 25px;
}
.lghjgt ul li {
  font-size: 16px;
  color: gray;
  padding-bottom: 10px;
  line-height: 25px;
}

.kfgjh i {
  padding: 8px;
  border-radius: 50px;
  font-size: 18px;
  border: 2px solid var(--main-orange-color);
  color: var(--main-orange-color);
}

.kfgjh {
  margin-left: 20px;
}

.jfgngjfgh {
  display: flex;
}

.jfgngjfgh p {
  font-weight: bold;
}

.kjdhjdrgfg {
  margin-top: 15px;
}
.kjdhjdrgfg h5 {
  font-size: 25px;
  color: black;
  font-weight: 600;
  margin-bottom: 0;
}
.kjdhjdrgfg p {
  font-size: 17px;
  color: #8b8989;
}

.oidjaioerr,
.klfgf {
  height: 10rem;
  overflow-y: auto;
  margin-bottom: 1.8rem;
}

.kjdfhjdfdffg {
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.kjdfhjdfdffg h5 {
  font-size: 22px;
  color: black;
  font-weight: 600;
  margin-bottom: 0;
}

.fkghfgnhgjhg {
  margin-top: 15px;
}

.fjhg {
  display: flex;
  margin-bottom: -10px;
  align-items: baseline;
  justify-content: space-between;
}
.fjhg h6 {
  font-weight: 600;
  font-size: 16px;
  color: black;
}
.fjhg p {
  font-size: 15px;
  color: gray;
}

.nfhjdfghfdghf {
  margin-top: -10px;
}

.hjgfjgfgf {
  margin-top: -10px;
}

.fkjhgjfgf {
  display: flex;
  justify-content: space-between;
}
.fkjhgjfgf i {
  color: var(--main-orange-color);
  font-size: 14px;
}
.fkjhgjfgf p {
  font-size: 16px;
  color: gray;
  font-weight: 500;
}

.fkjhgjfgf {
  margin-top: 10px;
}

.md-header header {
  position: relative;
  z-index: 9;
}

.md-header header nav {
  border-radius: 0;
}

.md-header header .navbar.mx-4 {
  margin: 0 !important;
}

.md-header .navbarmenucollapse {
  justify-content: end;
}

.md-header .udgehrr .btn {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: relative;
  border: none;
  box-shadow: 0 4px 10px 2px rgba(238, 108, 41, 0.25);
}

.md-header .udgehrr .btn i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
}

.md-header .difuhtre_nav button {
  width: 100%;
}

.md-header .difuhtre_nav button span {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(238, 108, 41, 0.25);
}

.md-header .difuhtre_nav button span i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.6rem;
  color: var(--main-orange-color);
}

.md-header .difuhtre_nav button h5 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
  color: #fff;
}

.csdegbfraedd {
  background: var(--main-orange-color);
}

.mentor_dashboard .csdegbfraedd,
.difuhtre_content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 85vh;
}

.mentor_dashboard .hgkfgkjfgfghgfg.sticky-top {
  top: -13%;
}

.djioerr_dpdwn {
  width: 12.8rem;
  right: 5%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 9;
  top: 45px;
}

.djioerr_dpdwn li {
  list-style: none;
  text-transform: uppercase;
  border-bottom: 1px solid var(--light-grey-border);
  padding: 5px 0;
  font-weight: 600;
  transition: background-color 0.3s ease;
}
.djioerr_dpdwn li:hover {
  background-color: #f0f0f0;
}
.djioerr_dpdwn li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.udgehrr:hover .djioerr_dpdwn {
  display: block !important;
}

.custom-select {
  position: relative;
}

.custom-select select {
  display: none;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

.select-items div,
.select-selected {
  color: #ffffff;
  cursor: pointer;
}

.select-selected {
  text-align: left;
}

.select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.select-hide {
  display: none;
}

.select-items div {
  color: #000;
  padding: 0.6rem;
  border-bottom: 1px solid var(--light-grey-border);
}

.select-items div:last-child {
  border-bottom: 0;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.difuhtre_content .mentor-prf-settings h5,
.hiniertvrer_change_password h5 {
  color: var(--light-highlighted-text-color);
}

.ufguirniirtr #ypautosuggestions,
.ufguirniirtr #yeautosuggestions,
.ufguirniirtr #amautosuggestions,
.ufguirniirtr #peautosuggestions {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  transform: translateX(-50%);
  border-radius: 0 0 5px 5px;
  border: 1px solid #cdcdcd;
  border-top: 0;
  font-family: var(--font-family-lato);
}

.ufguirniirtr .ypsuggestion,
.ufguirniirtr .yesuggestion,
.ufguirniirtr .amsuggestion,
.ufguirniirtr .pesuggestion {
  padding: 8px 10px;
  cursor: pointer;
  font-family: var(--font-family-lato);
  border-bottom: 1px solid #cdcdcd;
  font-weight: 600;
}

.tagify__dropdown {
  font-family: var(--font-family-lato);
}

.mentor-prf-settings #skill-tag tags {
  display: block;
  border-radius: 0.25rem;
  font-family: var(--font-family-lato);
}

.difuhtre_content .huygrut .deuirr_circle {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background: var(--light-orange-background-color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ijaihnifrtt #autosuggestions {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1000;
  background-color: #fff;
  width: calc(100% - 2.5rem);
  transform: translateX(-50%);
  border-radius: 0 0 5px 5px;
  border: 1px solid #cdcdcd;
  border-top: 0;
  font-family: var(--font-family-lato);
}

.huygrut button:last-child {
  text-decoration: underline;
  font-family: var(--font-family-lato);
  transition: 0.3s ease-in-out;
  font-weight: 600;
}

.huygrut button:last-child:hover {
  color: var(--main-orange-color);
  transition: 0.3s ease-in-out;
}

.mentor-prf-settings form label {
  font-weight: 600;
  font-size: 1.1rem;
}

.mentor-prf-settings form textarea {
  height: 10rem;
}

.mentor-prf-settings form .form-control {
  font-family: var(--font-family-lato);
}

.mentor-prf-settings form .form-control::placeholder {
  font-family: var(--font-family-lato);
  font-style: italic;
}

#frame {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 85vh;
  min-height: 300px;
  max-height: 720px;
  background: #e6eaea;
}
@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
}
#frame #sidepanel {
  float: left;
  min-width: 220px;
  max-width: 220px;
  width: 40%;
  height: 100%;
  background: var(--light-main-background-color);
  color: #f5f5f5;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel {
    width: 58px;
    min-width: 58px;
  }
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #profile {
    width: 100%;
    margin: 0 auto;
    padding: 5px 0 0 0;
    background: #32465a;
  }
}
#frame #sidepanel #profile.expanded .wrap {
  height: 210px;
  line-height: initial;
}
#frame #sidepanel #profile.expanded .wrap p {
  margin-top: 20px;
}
#frame #sidepanel #profile.expanded .wrap i.expand-button {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
#frame #sidepanel #profile .wrap {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap {
    height: 55px;
  }
}
#frame #sidepanel #profile .wrap img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #e74c3c;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 4px;
  }
}
#frame #sidepanel #profile .wrap img.online {
  border: 2px solid #2ecc71;
}
#frame #sidepanel #profile .wrap img.away {
  border: 2px solid #f1c40f;
}
#frame #sidepanel #profile .wrap img.busy {
  border: 2px solid #e74c3c;
}
#frame #sidepanel #profile .wrap img.offline {
  border: 2px solid #95a5a6;
}
#frame #sidepanel #profile .wrap p {
  float: left;
  color: #fff;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap p {
    display: none;
  }
}
#frame #sidepanel #profile .wrap i.expand-button {
  float: right;
  margin-top: 23px;
  font-size: 0.8em;
  cursor: pointer;
  color: #435f7a;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap i.expand-button {
    display: none;
  }
}
#frame #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #435f7a;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options {
    width: 58px;
    margin-top: 57px;
  }
}
#frame #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options.active {
    margin-top: 62px;
  }
}
#frame #sidepanel #profile .wrap #status-options:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #435f7a;
  margin: -8px 0 0 24px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options:before {
    margin-left: 23px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px;
}
#frame #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 35px 22px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li:hover {
  background: #496886;
}
#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    width: 14px;
    height: 14px;
  }
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li
  span.status-circle:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0;
}
@media screen and (max-width: 735px) {
  #frame
    #sidepanel
    #profile
    .wrap
    #status-options
    ul
    li
    span.status-circle:before {
    height: 18px;
    width: 18px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li p {
    display: none;
  }
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-online
  span.status-circle {
  background: #2ecc71;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-online.active
  span.status-circle:before {
  border: 1px solid #2ecc71;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-away
  span.status-circle {
  background: #f1c40f;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-away.active
  span.status-circle:before {
  border: 1px solid #f1c40f;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-busy
  span.status-circle {
  background: #e74c3c;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-busy.active
  span.status-circle:before {
  border: 1px solid #e74c3c;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-offline
  span.status-circle {
  background: #95a5a6;
}
#frame
  #sidepanel
  #profile
  .wrap
  #status-options
  ul
  li#status-offline.active
  span.status-circle:before {
  border: 1px solid #95a5a6;
}
#frame #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important;
}
#frame #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0;
}
#frame #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #32465a;
  border-radius: 3px;
  color: #f5f5f5;
  padding: 7px;
  width: calc(100% - 43px);
}
#frame #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #435f7a;
}
#frame #sidepanel #search {
  border-top: 1px solid #32465a;
  border-bottom: 1px solid #32465a;
  font-weight: 300;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #search {
    display: none;
  }
}
#frame #sidepanel #search label {
  position: absolute;
  margin: 10px 0 0 20px;
}
#frame #sidepanel #search input {
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
  padding: 10px 0 10px 46px;
  width: calc(100% - 25px);
  border: none;
  background: #32465a;
  color: #f5f5f5;
}
#frame #sidepanel #search input:focus {
  outline: none;
  background: #435f7a;
}
#frame #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #contacts {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts {
    height: calc(100% - 149px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #frame #sidepanel #contacts::-webkit-scrollbar {
    display: none;
  }
}
#frame #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}
#frame #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 15px 0;
  font-size: 0.9em;
  cursor: pointer;
  border-bottom: 1px dashed var(--light-grey-border);
}

#frame #sidepanel #contacts ul li.contact:last-child {
  border-bottom: 0;
}

@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 46px 8px;
  }
}
#frame #sidepanel #contacts ul li.contact:hover {
  background: #f5f5f5;
}
#frame #sidepanel #contacts ul li.contact.active {
  background: var(--main-orange-color);
}
#frame #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #32465a !important;
}
#frame #sidepanel #contacts ul li.contact .wrap {
  width: 88%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap img {
    margin-right: 0px;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap .meta {
    display: none;
  }
}
#frame #sidepanel #contacts ul li.contact.active .wrap .meta .name {
  color: #fff;
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-weight: 600;
  margin-bottom: 0 !important;
}
#frame #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}
#frame #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 50%;
  padding: 10px 0;
  background: #32465a;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }
}
#frame #sidepanel #bottom-bar button:focus {
  outline: none;
}
#frame #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid #2c3e50;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2c3e50;
  }
}
#frame #sidepanel #bottom-bar button:hover {
  background: #435f7a;
}
#frame #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button span {
    display: none;
  }
}
#frame .content {
  width: auto;
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame .content {
    width: calc(100% - 58px);
  }
}
#frame .content .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5;
}
#frame .content .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
}
#frame .content .contact-profile p {
  float: left;
  font-weight: 600;
}
#frame .content .contact-profile .social-media {
  float: right;
}
#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#frame .content .contact-profile .social-media i:hover {
  color: #435f7a;
}
#frame .content .messages {
  height: auto;
  min-height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (max-width: 735px) {
  #frame .content .messages {
    max-height: calc(100% - 105px);
  }
}
#frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0);
}
#frame .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
#frame .content .messages ul li {
  display: inline-block;
  clear: both;

  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  font-size: 0.9em;
}
#frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
#frame .content .messages ul li.sent img {
  margin: 6px 8px 0 0;
}
#frame .content .messages ul li.sent p {
  background: var(--light-blue-border-color);
  color: #f5f5f5;
}
#frame .content .messages ul li.replies img {
  float: right;
  margin: 6px 0 0 8px;
}
#frame .content .messages ul li.replies p {
  background: #f5f5f5;
  float: right;
}
#frame .content .messages ul li img {
  width: 22px;
  border-radius: 50%;
  float: left;
}
#frame .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 205px;
  line-height: 130%;
}
@media screen and (min-width: 735px) {
  #frame .content .messages ul li p {
    max-width: 300px;
  }
}
#frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}
#frame .content .message-input .wrap {
  position: relative;
}
#frame .content .message-input .wrap input {
  font-family: var(--font-family-lato);
  float: left;
  border: none;
  width: calc(100% - 50px);
  padding: 11px 32px 10px 8px;
  color: var(--main-light-black-text-color);
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap input {
    padding: 15px 32px 16px 8px;
  }
}
#frame .content .message-input .wrap input:focus {
  outline: none;
}
#frame .content .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: 0.5;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}
#frame .content .message-input .wrap .attachment:hover {
  opacity: 1;
}
#frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 50px;
  padding: 12px 0;
  cursor: pointer;
  background: var(--main-orange-color);
  color: #f5f5f5;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap button {
    padding: 16px 0;
  }
}
#frame .content .message-input .wrap button:hover {
  background: #435f7a;
}
#frame .content .message-input .wrap button:focus {
  outline: none;
}

.fkjhgdfbghh ul.tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.fkjhgdfbghh ul.tabs li {
  background: none;
  color: #222;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1.2rem;
}

.fkjhgdfbghh ul.tabs li.current {
  background: #ffffff;
  color: #222;
  color: #000;
  font-weight: 600;
  border-bottom: 2px solid var(--main-orange-color);
}

.fkjhgdfbghh .tab-content {
  display: none;
  background: #ffffff;
  padding: 15px;
}

.fkjhgdfbghh .tab-content.current {
  display: inherit;
}

.lgkgg {
  width: 25px;
  height: 25px;
}
.lgkgg img {
  width: 100%;
  height: 100%;
}

.djbghdrfgfgh h6 {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: var(--font-family-lato);
}
.djbghdrfgfgh p {
  color: var(--light-highlighted-text-color);
  padding-top: 5px;
  line-height: 1.78;
  letter-spacing: 0.5px;
}

.kfghg {
  width: 100%;
}

.kfghg video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.kfghg img {
  width: 100%;
  height: 100%;
}

.dfujghffg {
  width: 100%;
  padding: 30px 0;
}

.jghdfrg {
  border: 1px solid gainsboro;
  border-radius: 12px;
  padding: 0.8rem 10px 1rem 10px;
  background: #fff;
}
.hgkfgkjfgfghgfg.sticky-top {
  top: -13%;
}

.kmg {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  min-height: 195px;
  line-height: 195px;
  overflow: hidden;
  position: relative;
  max-height: 195px;
}

.kmg img {
  max-width: 100%;
  max-height: 195px;
  width: auto !important;
  margin: auto;
}

.dfhjbghfjgfgh {
  text-align: center;
}
.dfhjbghfjgfgh h4 {
  font-size: 1.6rem;
  margin-bottom: 0 !important;
}
.dfhjbghfjgfgh h6 {
  color: #444444;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-family-lato);
}
.dfhjbghfjgfgh span {
  color: rgb(255, 174, 0);
  font-size: 1rem;
  display: block;
}

.hgkfgkjfgfghgfg h3 {
  color: #000;
  font-size: 22px;
  width: 60px;
  font-weight: bold;
  border-bottom: 1px solid gainsboro;
}

.fhfbfghg button {
  padding: 6px 10px;
  margin: 5px 3px;
  border: 1px solid rgb(216, 214, 214);
  border-radius: 6px;
  font-family: var(--font-family-lato);
  color: #444444;
  font-weight: 600;
}

.fkjbghdfgfghghjygh {
  margin-top: 25px;
  border-radius: 10px;
  border: 1px solid gainsboro;
}

.fjbgfg {
  text-align: center;
}
.fjbgfg h4 {
  color: var(--main-light-black-text-color);
  border-bottom: 1px solid rgb(216, 214, 214);
  padding-bottom: 10px;
}

table,
th,
td {
  padding: 9px 0;
  color: #222;
  text-align: center;
  font-size: 0.9rem !important;
  font-weight: 600;
  font-family: var(--font-family-lato) !important;
}

table,
th {
  color: gray !important;
  font-family: var(--font-family-lato) !important;
}

.ihgh {
  color: white !important;
  background-color: var(--main-orange-color);
  padding: 5px;
  border-radius: 50px;
}

table {
  border-collapse: collapse;
  caption-side: bottom;
  font-family: var(--font-family-lato) !important;
}

caption {
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}

.sefagfberr input[type="radio"]:checked + label {
  background: #2e64e3;
  color: #fff;
}

.btngdfr {
  border: none;
}

.sefagfberr label {
  padding: 0.2em 0.7rem;
  display: inline-block;
  border: 1px solid #dadce0;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.sefagfberr input[type="radio"] {
  display: none;
}

.lkfhgfgf {
  margin-top: 10px;
}
.lkfhgfgf h5 {
  color: #444444;
  font-weight: bold;
  font-family: var(--font-family-lato);
  font-size: 1.2rem;
}

.dfghjffg button {
  padding: 8px 40px !important;
}

.dfghjffg {
  text-align: center;
}

.kjghjfg {
  width: 55px;
  height: 55px;
}
.kjghjfg img {
  width: 100%;
  height: 100%;
}

.fgnhjghjgh {
  display: flex;
  align-items: baseline;
}

.ihceuirr h4 {
  margin: 0;
  font-size: 21px;
}

.fgnhjghjgh span {
  color: rgb(249, 195, 19);
  margin-right: 25px;
}
.fgnhjghjgh p {
  margin-bottom: 0 !important;
}

.hghgg {
  margin: 10px 0 20px 0;
  padding-left: 1rem;
}

.gnhjbggf {
  margin-top: -40px;
}
.gnhjbggf a {
  color: var(--main-orange-color) !important;
  text-decoration: underline !important;
  font-weight: 600;
}

.fgnhjghjgh p i {
  color: var(--main-orange-color);
}

.dfkjbghdfffg p {
  line-height: 28px;
  margin: 0;
  text-align: justify;
}

.hfuydfgftgh {
  display: flex;
  align-items: baseline;
}
.hfuydfgftgh p {
  padding-left: 10px !important;
}

.mcgghghfhfghyg {
  width: 100%;
  padding: 40px 0;
}

.ngdfhbgfd {
  display: flex;
  align-items: baseline;
}
.ngdfhbgfd h4 {
  padding-right: 20px;
  font-size: 30px;
  color: #000;
  font-weight: 700;
}
.ngdfhbgfd i {
  font-size: 20px !important;
  padding-right: 10px !important;
}
.ngdfhbgfd p {
  font-size: 16px;
  color: gray;
}

.jfgg {
  height: 110px;
  width: 100%;
}
.jfgg img {
  width: 100%;
  height: 100%;
}

.jhtgfhgfg h6 {
  font-size: 18px;
  font-weight: 600;
  color: gray;
  padding-top: 15px;
}

.lkdfjhjdfgffghf {
  padding: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 12px;
}

.fkghjfghjfgh {
  padding: 15px 0;
  margin-top: 15px;
  border-top: 1px solid rgb(224, 222, 222);
  border-bottom: 1px solid rgb(224, 222, 222);
}

.flgjf {
  display: flex;
  align-items: baseline;
  padding: 0 10px;
  margin-bottom: 0;
  justify-content: space-between;
}
.flgjf p {
  color: gray;
  font-size: 17px;
}
.flgjf h6 {
  color: black;
  font-weight: 600;
}

.jgf img {
  width: 30%;
  margin: -4.5rem 0 0 3rem;
}

.ghgfkhgjgf {
  display: flex !important;
}

.gkjffgg98 button {
  padding: 10px 20px !important;
}

.jnhjkhjgh button {
  width: 100%;
  height: 45px;
  font-size: 17px;
  font-weight: 700;
  margin-top: 20px;
}

.dgkjbfklks .radio {
  margin: 0.5rem;
}
.dgkjbfklks .radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.dgkjbfklks .radio input[type="radio"] + .radio-label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid var(--main-orange-color);
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.dgkjbfklks .radio input[type="radio"]:checked + .radio-label:before {
  background-color: var(--main-orange-color);
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.dgkjbfklks .radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #9857ee;
}
.dgkjbfklks .radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  background-color: var(--main-orange-color);
  background: var(--main-orange-color);
}

.dgkjbfklks .radio {
  margin-bottom: 35px;
}
.dgkjbfklks .radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}

.aslkhghj2 {
  padding: 100px 0;
  background: url("../Images/plpl1.png");
  background-color: white;
  background-size: 100% 100%;
}

.kghfbgfgbhfg {
  width: 100%;
  padding-top: 5rem;
}

.jhkjghkjghn select {
  width: 200px;
  height: 40px;
  border-radius: 25px;
  padding: 0 10px;
  color: black;
  text-align: center;
  font-weight: 600;
  border: none;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.nfhjgbgf {
  width: 100%;
  padding: 40px 0;
}

.nfhjgbgf {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 20px;
}

.dfhjbghfjgfgh22 {
  text-align: center;
}
.dfhjbghfjgfgh22 h4 {
  font-size: 22px;
}
.dfhjbghfjgfgh22 h6 {
  margin-top: 0;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-family-lato);
}
.dfhjbghfjgfgh22 span {
  color: rgb(255, 174, 0);
  font-size: 1rem;
}
.dfhjbghfjgfgh22 p {
  font-size: 0.8rem;
  margin: 10px 0;
}

.ghfghgfhg {
  margin-bottom: 20px;
}

.gjhtrhgjghjh {
  width: 100%;
  padding: 40px 0;
}

.bfxbdfdfg {
  width: 100%;
  height: 400px;
}
.bfxbdfdfg img {
  width: 100%;
  height: 100%;
}

.kjhjgdfgf h2 {
  color: black;
  font-size: 30px;
}

.kjhjgdfgf button {
  width: 150px !important;
  height: 40px;
  font-size: 16px !important;
  font-weight: 600;
}

.nhggfhf h2 {
  font-size: 30px;
}

#star-1:checked ~ section [for="star-1"] svg,
#star-2:checked ~ section [for="star-1"] svg,
#star-2:checked ~ section [for="star-2"] svg,
#star-3:checked ~ section [for="star-1"] svg,
#star-3:checked ~ section [for="star-2"] svg,
#star-3:checked ~ section [for="star-3"] svg,
#star-4:checked ~ section [for="star-1"] svg,
#star-4:checked ~ section [for="star-2"] svg,
#star-4:checked ~ section [for="star-3"] svg,
#star-4:checked ~ section [for="star-4"] svg,
#star-5:checked ~ section [for="star-1"] svg,
#star-5:checked ~ section [for="star-2"] svg,
#star-5:checked ~ section [for="star-3"] svg,
#star-5:checked ~ section [for="star-4"] svg,
#star-5:checked ~ section [for="star-5"] svg {
  transform: scale(1);
}

#star-1:checked ~ section [for="star-1"] svg path,
#star-2:checked ~ section [for="star-1"] svg path,
#star-2:checked ~ section [for="star-2"] svg path,
#star-3:checked ~ section [for="star-1"] svg path,
#star-3:checked ~ section [for="star-2"] svg path,
#star-3:checked ~ section [for="star-3"] svg path,
#star-4:checked ~ section [for="star-1"] svg path,
#star-4:checked ~ section [for="star-2"] svg path,
#star-4:checked ~ section [for="star-3"] svg path,
#star-4:checked ~ section [for="star-4"] svg path,
#star-5:checked ~ section [for="star-1"] svg path,
#star-5:checked ~ section [for="star-2"] svg path,
#star-5:checked ~ section [for="star-3"] svg path,
#star-5:checked ~ section [for="star-4"] svg path,
#star-5:checked ~ section [for="star-5"] svg path {
  fill: #ee6c29;
  stroke: #d35311;
}

.bcggfgjfgg section {
  text-align: center;
  position: absolute;
}

.bcggfgjfgg label {
  display: inline-block;
  width: 50px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}
.bcggfgjfgg label svg {
  width: 100%;
  height: auto;
  fill: white;
  stroke: #ccc;
  transform: scale(0.8);
  transition: transform 200ms ease-in-out;
}
.bcggfgjfgg label svg path {
  transition: fill 200ms ease-in-out, stroke 100ms ease-in-out;
}

.bcggfgjfgg label[for="star-null"] {
  margin: 0 auto;
  color: #999;
}

.bcggfgjfgg label i {
  color: var(--main-orange-color);
  font-size: 35px;
}

.bnb222 {
  padding-top: 10px !important;
}

.bcggfgjfgg input {
  margin-top: 1rem;
}

.nhggfhf {
  width: 100%;
  padding: 80px 0;
}

.kjhjgdfgf button {
  margin-top: 15px;
}

.bfxbdfdfg {
  margin-top: 40px;
}

.hjgbghghf {
  width: 100%;
  height: 400px;
  align-items: center;
  margin: auto;
}

.dsjujfghdfgdfghf {
  margin-top: 100px;
}
.hjgbghghf img {
  width: 100%;
  height: 100%;
}

.mndhjgbghfgh {
  text-align: center !important;
}

.mndhjgbghfgh h2 {
  font-weight: bold !important;
  margin-bottom: 15px;
}

.mndhjgbghfgh button {
  padding: 10px 30px !important;
  margin-right: 20px !important;
  margin-top: 30px !important;
}

.aslkhghj2 {
  padding: 100px 0;
  background: url("../Images/vbvb2.png");
  background-color: white;
}

.jgfgfg i {
  padding: 0 5px;
}

.kjfghdgvfgfghfh {
  width: 100%;
  padding: 40px 0;
}

.kdrhgjddf {
  display: flex;
  align-items: baseline;
}
.kdrhgjddf p {
  padding-right: 10px;
  color: gray;
  padding-top: 15px;
}
.kdrhgjddf h6 {
  padding-right: 10px;
  font-weight: 600;
  font-size: 17px;
  color: var(--main-orange-color);
}
.kdrhgjddf span {
  color: var(--main-orange-color) !important;
}

.spmjj {
  color: var(--main-orange-color) !important;
  font-weight: bold;
}

.jdfhygdff {
  padding: 15px;
  border-radius: 50px;
}

.nghjgfg55 {
  background-color: var(--main-orange-color);
  width: 100%;
  padding: 40px 30px;
  height: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.kdfhfgfg55 p {
  width: 80px;
  height: 25px;
  background-color: #fff;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 500 !important;
  line-height: 25px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding-left: 10px;
}

.mdfhjgbg h2 {
  font-size: 27px;
  color: white;
  font-weight: 500;
  margin-bottom: 0;
}
.mdfhjgbg span {
  font-size: 14px;
  color: white;
}
.mdfhjgbg p {
  margin-bottom: 0;
}

.jufbgyfr p {
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.kjhgdfgfd2 {
  display: flex;
}
.kjhgdfgfd2 p {
  padding-right: 15px;
  color: rgb(255, 255, 255);
  height: 25px !important;
}

.kjghgdg button {
  padding: 10px 15px;
  border: none;
  font-weight: 600;
  border-radius: 25px;
  margin-top: 10px;
}
.kjghgdg button i {
  padding-left: 5px;
  color: var(--main-orange-color);
}

.fgfdg h2 {
  font-size: 35px;
  padding: 10px;
}

.dfghgghfg {
  padding: 1rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  border-radius: 4px;
  margin-bottom: 0;
  background-color: white;
  border: 4px solid white;
}

.dfghgghfg2 {
  padding: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  border-radius: 4px;
  border: 1px solid var(--main-orange-color);
}

.knhgvgfghfg {
  width: 100%;
  padding: 40px 0;
}

.gfkjghfg {
  display: flex;
}

.gkbhfg {
  width: 100%;
  text-align: center;
  min-height: 40px;
  line-height: 32px;
  overflow: hidden;
  position: relative;
  max-height: 40px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: 2px solid #fff;
  border-radius: 10px;
}
.gkbhfg img {
  max-width: 100%;
  max-height: 40px;
  transform: scale(1.5);
}

.mgjbhfghfgh h6 {
  font-size: 1.1rem;
  color: #000000;
  font-weight: 600;
}
.mgjbhfghfgh span {
  color: black !important;
}

.mkghdfgf {
  display: flex;
  margin-bottom: 10px;
}
.mkghdfgf p {
  font-size: 16px;
  padding-right: 5px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
}
.mkghdfgf i {
  padding: 5px;
  margin-right: 5px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 50%;
  color: var(--main-orange-color);
}

.kdfhjfg {
  margin-top: 0;
  margin-bottom: 0;
}
.kdfhjfg p {
  padding-top: 5px;
  line-height: 20px;
}

.nhvbstk .kdfhjfg p {
  font-size: 0.8rem;
}

.nhvbstk {
  padding: 0.35rem;
  padding-top: 0;
}

.dfhjbg button {
  padding: 6px 15px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 12px;
  color: black;
  font-size: 14px;
  background: #fff;
  margin: 10px;
  font-family: var(--font-family-lato);
}

.dfhjbg2 button {
  padding: 4px 15px;
  border: 1px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 50px;
  color: #000;
  font-size: 13px;
  background: #fff;
  margin: 0 5px;
}

.jkjfghfg .sticky-top {
  top: 24%;
}

.hgdfggtf {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 12px 20px -20px;
}

.gkbhfg2 {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  min-height: 114px;
  line-height: 90px;
  position: relative;
  max-height: 90px;
  border-radius: 10px;
  border: 3px solid #fff;
  overflow: hidden;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.gkbhfg2 img {
  max-width: 100%;
  max-height: 102px;
  transform: scale(1.3);
}

.dhjgdfghgj {
  margin-left: 15px;
}

.mgjbhfghfgh2 h6 {
  font-size: 26px;
  font-family: var(--font-family-lato);
  font-weight: 600;
}
.mgjbhfghfgh2 span {
  color: gray;
  font-size: 1.3rem;
}

.duilehri_left .kdfhjfg p {
  font-size: 0.9rem;
  font-weight: 600;
}

.kdfhjfg p {
  font-weight: 600;
  line-height: 22px;
}

.mbpm .csdffh_modal {
  position: fixed;
  width: 100%;
  height: 65vh;
  overflow-y: auto;
  top: 35%;
  background: #fff !important;
  z-index: 999;
  border-radius: 10px 10px 0 0;
  transition: 0.3s ease-in-out;
  padding-top: 4rem !important;
}

.mbpm .csdffh_modal.dbhebn__biedr {
  position: fixed;
  width: 100%;
  top: 100%;
  background: #fff;
  z-index: -2;
  border-radius: 10px 10px 0 0;
  transition: 0.3s ease-in-out;
}

.mbpm .gkbhfg2 {
  width: 35%;
}

.mbpm .mgjbhfghfgh2 h6 {
  margin-top: 1rem;
  font-size: 1.6rem;
}

.mbpm .kdfhjfg p {
  font-weight: 400;
}

.mbpm .njbfghddfgf p {
  padding-left: 0;
}

.mbpm .lfjguifhgftgh {
  margin-left: 0;
}

.mbpm .hghgg {
  padding-left: 0;
}

.mbpm .fhdfgf h2 {
  font-size: 1.4rem;
}

.mbpm .fyhugdghg.d-flex {
  display: block !important;
}

.mbpm .ihceuirr h4 {
  font-size: 1.2rem !important;
}

.mbpm #modalbackdrop {
  opacity: 0;
  transition: 0.3s ease-in-out;
  z-index: -2;
}

.mbpm #modalbackdrop.modal_bck_drop {
  opacity: 1 !important;
  transition: 0.3s ease-in-out;
  z-index: 999;
}

.mbpm .djoier_bck_drp {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
}

.mbpm .ihstrerer .fa-chevron-down {
  color: #a9a9a9;
  top: 0;
  left: 0;
  font-size: 2rem;
  line-height: 50px;
  background: #fff;
  width: 33rem;
  height: 34%;
  opacity: 0;
  text-align: center;
  border-radius: 50%;
  position: fixed !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  cursor: pointer;
}

.djbhfdf button {
  border-radius: 50px;
  font-size: 0.9rem !important;
  font-weight: 800;
  letter-spacing: 0.5px;
  background: transparent !important;
  border: none;
  box-shadow: none;
  color: #2e64e3;
  text-transform: capitalize;
  position: relative;
  transition: 0.3s ease-in-out;
}

.djbhfdf button:after {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  border-radius: 10px;
  background: #43c0d7;
  left: 0;
  bottom: 0;
  transition: 0.3s ease-in-out;
}

.djbhfdf button:hover:after {
  width: 100%;
  transition: 0.3s ease-in-out;
}

.djbhfdf button:hover i {
  transform: translateX(10px);
  transition: 0.3s ease-in-out;
}

.djbhfdf button:hover {
  color: #1b759a;
}

.mkghdfgf2 p {
  padding-right: 1rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 600;
}
.mkghdfgf2 i {
  padding: 7px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 0.8rem;
  color: var(--main-orange-color);
}
.jghfttght {
  float: right;
}
.jghfttght i {
  font-size: 25px;
  color: var(--main-orange-color);
  margin-right: 1rem;
}
.jghfttght button {
  padding: 5px 10px;
  font-size: 0.9rem !important;
  font-weight: 600;
  color: var(--main-orange-color) !important;
  background-color: #fff;
  border: 1px solid var(--main-orange-color);
}

.jghfttght button:hover {
  color: #fff !important;
}

.jghfttght button:hover i {
  color: #fff !important;
}

.ihstrerer {
  border-radius: 10px;
  padding-top: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.jdfghfghfghfg h5 {
  font-weight: 600;
  color: #404040;
}

.khrudghfgfdghfh {
  width: 100%;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 8px;
  margin-bottom: 15px;
  background: #fff;
}

.drkjbghgg {
  text-align: right;
}

.fjbffd h5 {
  color: #000;
  font-weight: 600;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
}

.hjvbf {
  display: flex;
  margin-bottom: 1rem;
}
.hjvbf span {
  width: 90px;
  font-weight: 600 !important;
  text-align: center;
  border-radius: 6px;
  color: rgb(185, 115, 1);
  margin-right: 10px !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.hjvbf span i {
  color: var(--light-blue-border-color);
}

.hjvbf p {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0 !important;
}

.fhdfgf h2 {
  font-size: 1.6rem;
  color: #404040;
  margin-bottom: 0 !important;
}
.fhdfgf i {
  color: var(--main-orange-color);
}

.fhdfgf {
  display: flex;
  margin-top: 5px;
  align-items: center;
}
.fhdfgf p {
  font-size: 0.9rem;
  font-weight: 600;
  padding-left: 5px;
  margin-bottom: 0 !important;
}

.khrudghfgfdghfh .row {
  align-items: end;
}

.jbfgf {
  display: none;
}

.jbfgf button {
  border: none;
  padding: 6px;
  background-color: #fddbdb;
  font-size: 15px;
  font-size: 600;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.njbfghddfgf {
  padding: 10px 0;
  margin-top: 20px;
}
.njbfghddfgf h4 {
  color: #2f2f2f;
  font-size: 22px;
}
.njbfghddfgf p {
  line-height: 22px;
  color: #2f2f2f;
  padding-left: 10px;
}

.fgkjhdffg h4 {
  color: #2f2f2f;
  font-size: 22px;
  margin-bottom: 0 !important;
}

.ihceuirr h4 {
  font-size: 1.2rem;
}

.fgkjhdffg {
  margin: 2rem 0;
}

.njhfd2 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  overflow: hidden;
  text-align: center;
  border: 2px solid #fff;
  position: relative;
}
.njhfd2 img {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lfjguifhgftgh {
  margin-left: -15px;
  margin-top: 10px;
}
.lfjguifhgftgh h6 {
  font-size: 18px;
  margin-bottom: 0;
  color: rgb(51, 51, 51);
  font-weight: 600;
  font-family: var(--font-family-lato);
}
.lfjguifhgftgh span p {
  font-weight: 600;
  margin-bottom: 0 !important;
  color: var(--main-orange-color);
}

.lfjguifhgftgh p {
  margin-bottom: 0 !important;
  font-weight: 600;
  color: #818181;
}

.kjgfghvfggfhgfhg556 {
  background-color: #f6f8fa;
  padding-top: 3.8rem;
}

.bnbgjhg {
  padding-top: 1rem;
}

.bhduebbuger .sfgrwwe_btn .select-items div,
.bhduebbuger .sfgrwwe_btn .same-as-selected,
.bhduebbuger .sfgrwwe_btn .select-items {
  pointer-events: none;
}

.ndfhjgdfrgdfgfd {
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background: #fff;
  border-radius: 10px;
}

.kjgfghvfggfhgfhg556 ul.tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  height: 33rem;
  overflow-y: auto;
  padding-right: 10px;
}

.kjgfghvfggfhgfhg556 ul.tabs li {
  background: none;
  color: #222;
  margin-bottom: 15px;
  display: inline-block;
  border: 2px solid #fff;
  cursor: pointer;
}

.kjgfghvfggfhgfhg556 ul.tabs li.current {
  background: #ffffff;
  color: #222;
  margin-bottom: 15px;
  border: 1px solid var(--main-orange-color);
  border-radius: 4px;
}

.kjgfghvfggfhgfhg556 .tab-content {
  display: none;
}

.kjgfghvfggfhgfhg556 .tab-content.current {
  display: inherit;
}

.nhvbstk {
  height: 100vh;
  overflow-x: scroll;
  top: 17%;
}

.ighefirr .sfgrwwe_btn .select-selected {
  font-size: 0.9rem;
}

.ighefirr .sfgrwwe_btn {
  padding-left: 0.6rem;
  padding-right: 2.5rem;
  box-shadow: none;
  border: 1px solid #dadce0;
}

.ighefirr .sfgrwwe_btn .select-selected:before {
  margin-right: 0.3rem;
}

.ighefirr {
  box-shadow: 0 1px 6px #00000008;
}

.ighefirr .ibhdiber_btn .btn-main {
  border-color: #2e64e3;
  background: #fff;
  color: #000;
  box-shadow: none;
  transition: 0.3s ease-in-out;
}

.ighefirr .ibhdiber_btn .btn-main:hover {
  background: #2e64e3;
  color: #fff;
  border-color: #fff;
  transition: 0.3s ease-in-out;
}

.ighefirr.sticky-top {
  top: 11%;
  z-index: 999;
}

.oikahdbaed_filter .select-selected:before {
  content: "\f161" !important;
}

.oikahdbaed_filter {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.oikahdbaed_filter .select-selected:after {
  display: none;
}

.dieirherr_btn .sfgrwwe_btn span:before {
  position: relative;
  content: "\f161";
  font-family: "Font Awesome 6 Free";
  padding: 0rem 0.4rem;
  border-radius: 50%;
  background: var(--main-orange-color);
  display: -webkit-inline-box;
  color: #fff;
  margin-right: 0.5rem;
}

.dieirherr_btn .sfgrwwe_btn span:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.dieirherr_btn .sfgrwwe_btn span:after {
  border-color: #000 transparent transparent transparent;
  top: 1.2rem;
}

.dieirherr_btn .cjdsuibfsdf_btn:nth-of-type(4n-3) span:before {
  content: "\f51c" !important;
}

.dieirherr_btn .cjdsuibfsdf_btn:nth-of-type(4n-2) span:before {
  content: "\e4b7" !important;
}

.dieirherr_btn .cjdsuibfsdf_btn:nth-of-type(4n-1) span:before {
  content: "\f4c0" !important;
}

.dieirherr_btn .cjdsuibfsdf_btn:nth-of-type(4n) span:before {
  content: "\f5c0" !important;
}

.digerier-tabs-container {
  padding: 10px 10px;
  border: 1px solid #dde5ea;
  border-bottom: none;
}

.digerier-tab {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 5px 5px;
  font-family: var(--font-family-lato);
  font-size: 0.8rem;
  color: #383838;
  font-weight: 600;
}
.digerier-tab.active {
  background-color: #dce5f9;
  border-bottom: 1px solid #fff;
}
.digerier-tab-content {
  display: none;
  border-radius: 5px 5px;
}
.digerier-tab-content.active {
  display: block;
}

.nfhjvdfvgdf {
  display: flex;
  align-items: baseline;
}
.nfhjvdfvgdf i {
  padding: 0px 5px;
}
.nfhjvdfvgdf p {
  font-size: 16px;
  font-weight: 600;
}

.hfgspan {
  color: #082687 !important;
}

.nhhgdfgdfgdf {
  padding: 30px 20px;
  margin: 15px;
  border-radius: 8px;
  background-color: #ffeed5;
  border: 2px solid #f7bf6b;
}

.bghfdfgfdg {
  display: flex;
  align-items: baseline;
}
.bghfdfgfdg p {
  font-size: 15px;
  width: 85px;
  text-align: center;
  color: rgb(197, 112, 7);
  background-color: rgb(252, 210, 159);
  border-radius: 6px;
}
.bghfdfgfdg h6 {
  padding-left: 15px;
  font-size: 15px;
}

.dhdff {
  border-bottom: 1px dotted rgb(249, 197, 134);
}
.dhdff span {
  font-size: 35px !important;
  color: black !important;
}
.dhdff h4 {
  color: #082687;
  font-size: 22px;
  padding-bottom: 7px;
}

.mdfghdfgdf {
  margin-top: 15px;
}
.mdfghdfgdf button {
  padding: 8px 15px;
  border: none;
  background-color: white;
  border-radius: 25px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  margin-right: 5px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.bhffgbffg {
  padding: 20px 30px;
}
.bhffgbffg h5 {
  color: black;
  font-size: 20px;
  font-weight: bold;
}
.bhffgbffg p {
  font-size: 16px;
  line-height: 22px;
}

.calendar {
  height: 30rem;
  width: -moz-max-content;
  width: max-content;
  background-color: white;
  border-radius: 25px;
  overflow: hidden;
  padding: 35px 50px 0px 50px;
}

.calendar {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.calendar-header {
  background: #8089fe;
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  align-items: center;
  font-weight: 700;
  color: #ffffff;
  padding: 10px;
}

.calendar-body {
  padding: 10px;
}

.calendar-week-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: 600;
  cursor: pointer;
  color: rgb(104, 104, 104);
}

.bnttpl5 {
  width: 100%;
  height: 45px;
  background-color: gainsboro;
  color: #6a6a6b;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  margin-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.bnttpl5:hover {
  background-color: #3474eb;
  transition: 0.3s;
  color: white;
}

.calendar-week-days div:hover {
  color: black;
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
}

.calendar-week-days div {
  display: grid;
  place-items: center;
  color: black;
  height: 50px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  color: #0a0921;
}

.calendar-days div {
  width: 37px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: relative;
  cursor: pointer;
  animation: to-top 1s forwards;
}

.month-picker {
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.year-picker {
  display: flex;
  align-items: center;
}

.year-change {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin: 0px 10px;
  cursor: pointer;
}

.year-change:hover {
  background-color: #9796f0;
  transition: all 0.2s ease-in-out;
  transform: scale(1.12);
}

.calendar-footer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#year:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: all 0.2 ease-in-out;
}

.calendar-days div span {
  position: absolute;
}

.calendar-days div:hover {
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
  background-color: #fbc7d4;
  border-radius: 20%;
  color: #f8fbff;
}

.calendar-days div.current-date {
  color: #f8fbff;
  background-color: #9796f0;
  border-radius: 20%;
}

.month-list {
  position: relative;
  left: 0;
  top: -130px;
  background-color: #ebebeb;
  color: #151426;
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 5px;
  border-radius: 20px;
}

.month-list > div {
  display: grid;
  place-content: center;
  margin: 5px 10px;
  transition: all 0.2s ease-in-out;
}

.month-list > div > div {
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

.month-list > div > div:hover {
  background-color: #9796f0;
  color: #f8fbff;
  transform: scale(0.9);
  transition: all 0.2s ease-in-out;
}

.month-list.show {
  visibility: visible;
  pointer-events: visible;
  transition: 0.6s ease-in-out;
  animation: to-left 0.71s forwards;
}

.month-list.hideonce {
  visibility: hidden;
}

.month-list.hide {
  animation: to-right 1s forwards;
  visibility: none;
  pointer-events: none;
}

.date-time-formate {
  height: 4rem;
  width: 100%;
  font-family: Dubai Light, Century Gothic;
  position: relative;
  display: flex;
  top: 50px;
  justify-content: center;
  align-items: center;
}

.day-text-formate {
  font-family: Microsoft JhengHei UI;
  font-size: 1.4rem;
  padding-right: 5%;
  border-right: 3px solid #9796f0;
}

.date-time-value {
  display: block;
  position: relative;
  text-align: center;
  padding-left: 5%;
}

.time-formate {
  font-size: 1.5rem;
}

.time-formate.hideTime {
  animation: hidetime 1.5s forwards;
}

.day-text-formate.hidetime {
  animation: hidetime 1.5s forwards;
}

.date-formate.hideTime {
  animation: hidetime 1.5s forwards;
}

.day-text-formate.showtime {
  animation: showtime 1s forwards;
}

.time-formate.showtime {
  animation: showtime 1s forwards;
}

.date-formate.showtime {
  animation: showtime 1s forwards;
}

@keyframes to-top {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 1;
  }
}
@keyframes to-left {
  0% {
    transform: translatex(230%);
    opacity: 1;
  }
  100% {
    transform: translatex(0);
    opacity: 1;
  }
}
@keyframes to-right {
  10% {
    transform: translatex(0);
    opacity: 1;
  }
  100% {
    transform: translatex(-150%);
    opacity: 1;
  }
}
@keyframes showtime {
  0% {
    transform: translatex(250%);
    opacity: 1;
  }
  100% {
    transform: translatex(0%);
    opacity: 1;
  }
}
@keyframes hidetime {
  0% {
    transform: translatex(0%);
    opacity: 1;
  }
  100% {
    transform: translatex(-370%);
    opacity: 1;
  }
}
.ndfgdffgdfgfddf {
  margin-top: 120px;
}

.jghfffdf {
  padding: 25px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  border-radius: 8px;
}

.gfydffdg {
  display: flex;
  align-items: baseline;
  padding: 10px;
  width: 100%;
  height: 42px;
  line-height: 40px;
  border: 1px solid rgb(3, 181, 3);
  background-color: rgb(172, 247, 172);
  justify-content: space-between;
  border-radius: 6px;
}
.gfydffdg p {
  font-size: 16px;
  padding: 0;
  font-weight: 500;
  margin-top: -10px;
}
.gfydffdg h6 {
  padding: 0;
}
.gfydffdg i {
  padding: 0;
  font-size: 17px;
  padding-right: 5px;
}

.jfgggfg {
  margin-top: 15px;
}

.dfgydfgdfg label {
  font-size: 16px;
  font-weight: 600;
}

.dfgydfgdfg .form-control {
  padding: 10px !important;
}

.dfgydfgdfg25 .searchbox-wrap {
  display: flex;
  width: 100%;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
}
.dfgydfgdfg25 .searchbox-wrap input {
  flex: 1;
  padding: 10px;
  font-size: 1.1em;
  -webkit-border-top-left-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-topleft: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: none;
  border: none;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.dfgydfgdfg25 .searchbox-wrap button {
  padding-right: 10px;
  background-color: #fff;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  border: none;
  cursor: pointer;
  cursor: hand;
}
.dfgydfgdfg25 .searchbox-wrap button span {
  margin-left: 50px;
  padding: 9px 18px;
  font-weight: 600 !important;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
  background-color: #8d8e90;
  border-radius: 8px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.19);
}
.dfgydfgdfg25 .searchbox-wrap button span:hover {
  background-color: #797a7c;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.19);
}

.hdfgftdhftghfg {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 20px;
}
.hdfgftdhftghfg h6 {
  font-size: 25px;
  font-weight: 600;
}

.hhfbtn {
  padding: 12px 50px;
  border: none;
  background-color: #09b341;
  border-radius: 25px;
  color: white;
  font-size: 16px;
  border-radius: 12px;
  font-weight: 600;
}

.gkbfgbg {
  width: 100%;
  height: 400px;
  position: relative;
}
.gkbfgbg img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.hjdfbtn button {
  margin-top: 15px;
  border-radius: 12px;
}

.dfknhguyfdgf {
  width: 100%;
  padding: 4rem 0 4rem;
}

.owl58 .kmg {
  text-align: center;
  position: relative;
  border-radius: 10px;
  min-height: initial !important;
  width: 5rem;
  height: 5rem;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.owl58 .kmg img {
  max-width: initial !important;
  max-height: initial !important;
  height: initial !important;
  width: 100% !important;
  transform: scale(1.4);
}

.dfjgjhdfgdf {
  padding: 10px;
}
.dfjgjhdfgdf h2 {
  font-size: 35px;
}

.vhffgfgf span {
  color: #063764 !important;
}

.ndfhjvdfv {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
}
.ndfhjvdfv h2 {
  padding: 5px 0;
  font-size: 30px;
  padding-top: 5px;
}
.ndfhjvdfv h3 {
  font-size: 1rem;
  letter-spacing: 0;
  color: #a7a7a7;
  font-weight: 400;
  margin-bottom: 0;
}

.ndfhjvdfv h3 i {
  color: var(--main-orange-color);
}

.hdfgfghfgh {
  padding: 20px 15px;
  margin-top: 6rem;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  background: #fff;
}
.hdfgfghfgh.sticky-top {
  top: 14%;
}
.hff h4 i,
.jvhfdfvgdf h4 i {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  line-height: 1.8;
  color: var(--main-orange-color);
  text-align: center;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.gtyfdgfgf {
  margin-top: 45px;
}

.ndfhjvdfv {
  padding: 0 5px;
}

.hff {
  padding: 10px 0;
  margin-top: 10px;
}

.hff p {
  color: gray;
  font-size: 16px;
  line-height: 2;
}

.dfbhfgg h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: var(--font-family-lato);
}
.dfbhfgg p {
  color: gray;
  font-size: 16px;
  padding-top: 5px;
  line-height: 24px;
}

.dfbhfgg i {
  color: var(--main-orange-color);
  padding-right: 5px;
}

.jvhfdfvgdf {
  margin: 20px 0;
}

.ggvgdfdf {
  margin-top: 20px;
}

.jghfg {
  width: 100%;
  height: 160px;
}
.jghfg img {
  width: 100%;
  height: 100%;
}

.jdfgghfdf {
  height: 350px;
  margin-bottom: 25px;
  background-color: #e5e2e2;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.jfbdf {
  padding: 10px;
}
.jfbdf h3 {
  font-size: 24px;
}
.jfbdf p {
  font-size: 16px;
  color: black;
  line-height: 22px;
}

.ftuhtrh {
  border: none;
  color: rgb(0, 0, 0);
  background-color: transparent;
  border-bottom: 2px solid var(--main-orange-color);
  padding: 8px 5px;
  font-weight: 600;
}
.ftuhtrh i {
  color: var(--main-orange-color);
}

.ftuhtrh:hover {
  background-color: var(--main-orange-color);
  color: white;
  transition: 0.5s;
  border-bottom: 2px solid rgb(3, 8, 104);
}
.ftuhtrh:hover i {
  color: white;
}

.dnfhjdff h4 {
  font-size: 25px;
  border-bottom: 2px solid var(--main-orange-color);
  width: 320px;
}

.fhf {
  width: 100%;
  height: 140px;
  padding: 10px;
}
.fhf img {
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 8px;
}

.fffddf {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.fffddf img {
  width: 100%;
  height: 100%;
}

.hbffgg22 h3 {
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  color: #2c2c2c;
}
.hbffgg22 p {
  color: gray;
  font-size: 16px;
  line-height: 22px;
}

.khgudfgghg {
  margin-top: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 10px;
  border-radius: 4px;
  background: #fff;
}

.gnhjfgf {
  text-align: right;
}

.nhjfg .form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 50px;
}

.nhjfg i {
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--light-blue-border-color);
}

.gnhjfgf button {
  padding: 6px 10px;
  border: none;
  font-size: 16px;
  margin-right: 10px;
  color: #2e64e3;
  font-weight: 600;
  background-color: #fff;
  font-family: var(--font-family-lato);
}

.rejugfgh h2 {
  text-align: center;
}

.njfgfd {
  width: 100%;
  padding: 10px;
}
.njfgfd img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.dfnghdfgdf {
  margin-top: 25px;
}

.dfnghdfgdf .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: var(--main-orange-color) !important;
}

.mghggfg {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 8px;
}

.fhjvddfvfb .carousel img {
  width: 70px;
  max-height: 70px;
  border-radius: 50%;
  margin-right: 1rem;
  overflow: hidden;
}
.fhjvddfvfb .carousel-inner {
  padding: 1em;
}
@media screen and (min-width: 576px) {
  .fhjvddfvfb .carousel-inner {
    display: flex;
    width: 90%;
    margin-inline: auto;
    padding: 1em 0;
    overflow: hidden;
  }
  .fhjvddfvfb .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 768px) {
  .fhjvddfvfb .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 33.3333333333%;
  }
}
.fhjvddfvfb .carousel .card {
  margin: 0 0.5em;
  border: 0;
  padding: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}
.fhjvddfvfb .carousel-control-prev,
.fhjvddfvfb .carousel-control-next {
  width: 6rem;
  height: 2rem;
  border-radius: 12;
  border: none;
  background-color: #fff;
  border: 2px solid var(--main-orange-color);
  border-radius: 4px;
  font-weight: 600;
  top: 50%;
  transform: translateY(-50%);
}

.nfdfdvdfg {
  text-align: center;
  margin-top: 50px;
}

.hgfgf {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}
.hgfgf img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.fgfghjg55 {
  padding: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin: 10px;
}

.hjvgdhvdffg {
  width: 100%;
  padding: 40px 0;
}

.dfghfg h4 {
  text-align: center;
}

.jhffgfhfgh .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: var(--main-orange-color) !important;
}

.udbeir_banner_image {
  width: 30%;
  border-radius: 10px;
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

#address[type="text"] {
  padding: 10px;
  width: 100%;
}

#error-msg {
  color: #c50707;
}

#map-canvas {
  height: auto;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.duigehrr_wrapper .lkrhhdfgfdfgfhf {
  margin: 2rem 0;
}

.khgdfyhjgdfgfhftg854 {
  width: 100%;
  padding: 40px 0;
  margin-top: 80px;
}

.kgfd h4 {
  color: rgb(47, 47, 47);
  font-size: 26px;
  font-weight: bold !important;
  letter-spacing: 2px;
}

.fgjffghfg h6 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.nghjfbgfgf {
  margin-bottom: 20px;
  margin-top: 30px;
}
.nghjfbgfgf .img-btn img {
  width: 100px;
  height: 50px;
}
.nghjfbgfgf .img-btn > input {
  display: none;
}
.nghjfbgfgf .img-btn > img {
  cursor: pointer;
  border: 1px solid rgb(227, 226, 226);
  padding: 10px;
}
.nghjfbgfgf .img-btn > input:checked + img {
  border-color: var(--main-orange-color);
  border-radius: 4px;
  padding: 10px;
}
.nghjfbgfgf .author {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: black;
  padding: 3px;
  border-radius: 5px;
}
.nghjfbgfgf .author p {
  text-align: center;
  padding: 5px 10px;
  margin: 0;
  color: white;
  border: 2px solid lightgrey;
  border-radius: 5px;
}
.nghjfbgfgf .author a {
  color: lightgrey;
}

.nghjfbgfgf label {
  margin-right: 10px;
}

.gfgfggf label {
  font-size: 16px;
  font-weight: bold;
  color: gray;
}

.gfgfggf {
  margin-bottom: 10px;
}

.gfgfggf .form-control {
  border: none !important;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important;
}

.hgug58g {
  background: url("../Images/payment.jpg");
  width: 100%;
  height: 500px;
  background-size: 100% 100%;
  border-radius: 4px;
}

.overlay123 {
  width: 100%;
  height: 500px;
  border-radius: 4px;
  background-color: rgba(255, 119, 15, 0.6);
}

.bdsghdsfd {
  padding: 40px 50px;
}

.nfhjgf h6 {
  font-weight: 600;
  color: white;
  margin-bottom: 0;
  padding-bottom: 5px;
}
.nfhjgf h4 {
  font-weight: 600;
  color: white;
}
.nfhjgf p {
  color: white;
  font-size: 15px;
  line-height: 22px;
}

.gfgfgg h4 {
  font-size: 24px;
  padding-bottom: 5px;
}

.hrnm28 {
  margin-top: 0;
}

.hjcbghfg {
  display: flex;
}

.fbghvd {
  margin-left: 10px;
  color: white;
}
.fbghvd h6 {
  margin-bottom: 0;
  font-weight: bold;
}
.fbghvd p {
  font-size: 16px;
  margin-top: 0;
}

.fbghvd2 {
  margin-left: 10px;
  color: rgb(0, 0, 0);
}
.fbghvd2 h6 {
  margin-bottom: 0;
  font-weight: bold;
}
.fbghvd2 p {
  font-size: 16px;
  margin-top: 0;
}

.bfghv i {
  color: white;
}

.bfghv1 i {
  color: rgb(0, 0, 0);
}

.hjcbghfg2 {
  display: flex;
  padding: 0px 10px;
  align-items: baseline;
  margin-bottom: -5px;
  justify-content: space-between;
}
.hjcbghfg2 i {
  color: var(--main-orange-color);
}
.hjcbghfg2 h6 {
  font-size: 17px;
  font-weight: bold;
}
.hjcbghfg2 p {
  font-size: 16px;
  font-weight: 600;
  color: gray;
}

.btn254 {
  border: none;
  width: 100%;
  height: 50px;
  color: rgb(110, 110, 110);
  font-size: 17px;
  font-weight: 600;
  background-color: transparent;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 4px;
}

.btynh58 {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #fff;
  font-weight: 600;
  background-color: var(--main-orange-color);
  border-radius: 4px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  color: white;
}

.dfvbdfdf {
  margin-top: 15px;
}

.jhbfdf {
  width: 100%;
  height: 250px;
}
.jhbfdf img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.gnhdfgfdgf {
  padding: 25px;
  border-radius: 8px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.nfhjgf2 {
  margin-top: 10px;
}
.nfhjgf2 h6 {
  font-weight: 600;
  color: rgb(0, 0, 0);
  font-size: 18px;
  margin-bottom: 0;
  padding-bottom: 5px;
}
.nfhjgf2 h4 {
  font-weight: 600;
  color: rgb(0, 0, 0);
}
.nfhjgf2 p {
  color: rgb(1, 1, 1);
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0;
}

.gnhvgfdfgfdgfd {
  width: 100%;
  padding: 40px 0;
  margin-top: 100px;
}

.gjdfhu545 label {
  font-weight: 600;
  font-size: 16px;
  color: #636161;
}

.fhguygdfg .form-control {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  padding: 9px;
}

.gjdfhu545 {
  margin-bottom: 7px;
}

.dfkjghjdffg {
  display: flex;
  padding: 0 10px;
  margin: 0;
  justify-content: space-between;
}
.dfkjghjdffg h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
}
.dfkjghjdffg p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: gray;
}

.hrgfg {
  margin: 0 !important;
}

.nfghjf h4 {
  color: #292828;
  padding-bottom: 5px;
  font-size: 22px;
}
.nfghjf p {
  font-size: 17px;
  color: gray;
  line-height: 25px;
}

.nhjfgf p {
  line-height: 24px;
}

.nhfd25 {
  width: 100%;
  height: 110px;
}
.nhfd25 img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.jfhgvf {
  margin-top: 50px;
}

.fhghfg {
  width: 100%;
  height: 140px;
}
.fhghfg img {
  width: 100%;
  height: 100%;
}

.fbghdfgdfg h3 {
  font-size: 24px;
  margin-top: 10px;
}

.dfbdfgfdf {
  margin-top: 10px;
}

.dfbdfgfdf h4 {
  font-size: 24px;
}

.bgthfghfgh {
  margin-top: 15px;
}

.huygggfhfg {
  width: 100%;
  padding: 40px 0;
}

.hfgdfgfdf53564 {
  display: flex;
}

.gkfhjg5559 {
  margin-left: 20px;
}

.fhjgfg i {
  color: rgb(255, 255, 255);
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  background-color: #ebfaf8;
  border-radius: 50%;
  background-color: red;
}

.gkfhjg5559 h5 {
  font-size: 18px;
  font-weight: 600;
  padding-top: 5px;
  margin-bottom: 0;
}
.gkfhjg5559 p {
  font-size: 16px;
  line-height: 22px;
  color: gray;
  margin-bottom: 0;
}
.gkfhjg5559 span {
  font-size: 14px;
  font-weight: 600;
  color: red;
}

.dfghjdfdf P {
  font-size: 14px;
  color: gray;
  font-weight: 500;
  padding-top: -10px;
  text-align: right;
}

.dbhfhdfgfgf {
  margin-top: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.nxhjfdffgf5548 {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  padding: 15px;
}

.btnhd22 {
  border: none;
  background-color: #03a96c;
  color: white;
  font-size: 12px !important;
  padding: 3px 5px;
  border-radius: 4px;
  font-weight: 600;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.btnhd22a {
  border: none;
  background-color: #d61604;
  color: white;
  font-size: 12px !important;
  padding: 3px 5px;
  border-radius: 4px;
  font-weight: 600;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.btnhd22b {
  border: none;
  background-color: #00cfc8;
  color: white;
  font-size: 12px !important;
  padding: 3px 5px;
  border-radius: 4px;
  font-weight: 600;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.btnhd22c {
  border: none;
  background-color: #601efa !important;
  color: white;
  font-size: 12px !important;
  padding: 3px 5px;
  border-radius: 4px;
  font-weight: 600;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

footer {
  background: #001237;
}
footer p {
  color: #fff;
  letter-spacing: 0.5px;
}
footer h4 {
  color: #fff;
  position: relative;
}
footer h4:after {
  position: absolute;
  content: "";
  width: 2rem;
  height: 2px;
  border-radius: 25px;
  background: linear-gradient(to right, var(--main-orange-color), transparent);
  left: 0;
  bottom: -5px;
}
footer .hjgfgfghghgfh .nbffg {
  color: #fff;
  letter-spacing: 0.5px;
  transition: 0.3s ease-in-out;
}
footer .hjgfgfghghgfh .nbffg i {
  color: var(--main-orange-color);
  font-size: 1rem;
  transition: 0.3s ease-in-out;
}
footer .hjgfgfghghgfh .nbffg:hover i {
  padding-right: 5px;
  transition: 0.3s ease-in-out;
}
footer .hjgfgfghghgfh .nbffg:after {
  width: 0;
  content: "";
  position: absolute;
  height: 1.5px;
  border-radius: 25px;
  background: var(--main-orange-color);
  left: 1rem;
  bottom: -5px;
  transition: 0.2s ease-in-out;
}
footer .hjgfgfghghgfh .nbffg:hover:after {
  width: 80%;
  transition: 0.3s ease-in-out;
}
footer .nbffg2 .d-flex {
  align-items: baseline;
}
footer .nbffg2 a i {
  color: var(--main-orange-color);
  font-size: 1.2rem;
}
footer .nbffg2 a p {
  color: #fff;
}
footer .bghjdfgfdg button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #fff !important;
  border: none;
  transition: 0.3s ease-in-out;
}
footer .bghjdfgfdg button:hover {
  background: var(--main-orange-color) !important;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  transform: translateY(-5px);
}
footer .bghjdfgfdg button i {
  color: var(--main-orange-color);
  font-size: 1.4rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
}
footer .bghjdfgfdg button:hover i {
  color: #fff;
  transition: 0.3s ease-in-out;
  transform: translate(-50%, -45%) scale(1.2);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


