.case-study-card-container {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  height: 100%; /* Ensure all cards are same height */
  display: flex;
  flex-direction: column;
}

.case-study-card-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.case-study-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.case-study-card-content {
  padding: 20px;
  flex-grow: 1; /* Allow content to fill available space */
  display: flex;
  flex-direction: column;
}

.case-study-category-badge {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e6f0ff;
  color: #1e40af;
  border-radius: 16px;
  font-size: 14px;
  /* margin-bottom: 12px; */
  align-self: center;
}

.card-title {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.meta-info {
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
  margin-bottom: 12px;
}

.case-sub-head {
  color: #666;
  font-size: 14px;
}

.sub-container-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 14px;
  margin-bottom: 12px;
}

.card-excerpt {
  color: #444;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 20px;
  margin-top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex-grow: 1; /* Allow excerpt to fill available space */
}

.read-more-btn {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #2563eb;
  color: white !important;
  text-align: center;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: background-color 0.2s ease;
  border: none;
  cursor: pointer;
  margin-top: auto;
}

.read-more-btn:hover {
  background-color: #1d4ed8;
}
