.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 5rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.regmenu {
  position: absolute;
}
.navMenuIcon {
  color: rgb(27, 99, 222);
  position: absolute;
  top: 5px;
  font-size: 20px !important;
  right: -8px;
}
.navMenuIconPosition {
  position: relative;
}
.MenuBox {
  position: absolute;
  top: 1px;
  width: 10rem;
  left: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 2px;
  border-radius: 8px;
}

.MenuBox-item1 {
  width: 100%;
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
  border: white;
  border: 1px solid white;
  border-radius: 5px;
}

.MenuBox-item1:hover {
  background-color: #7fa5ff;
  color: white;
}

.udgehrr {
  display: flex;
}

.btn-mainHome:hover {
  color: blue;
  background-color: white;
}
.btn-mainHome {
  color: white;
  background-color: blue;
}

.navbarcolor {
  background-color: whitesmoke;
}

.inOneLine {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.ai-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #ff7e29, #ffbb72);
  color: black;
  font-weight: bold;
  border-radius: 20px;
  padding: 3px 5px;
  font-size: 12px;
}

.regmenu {
  position: absolute;
}

.MenuBox {
  position: absolute;
  top: 1px;
  width: 10rem;
  left: 1rem;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 2px;
  border-radius: 8px;
}

.MenuBox-item1 {
  width: 100%;
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid white;
  border-radius: 5px;
}

.MenuBox-item1:hover {
  background-color: #7fa5ff;
  color: white;
}

.udgehrr {
  display: flex;
}

.btn-login {
  background: white !important;
  color: #2dbedb !important;
  border-color: #2dbedb !important;
}
.btn-login:hover {
  background: #2dbedb !important;
  color: white !important;
}

.btn-mainHome:hover {
  color: blue;
  background-color: white;
}

.btn-mainHome {
  color: white;
  background-color: blue;
}

.navbarcolor {
  background-color: whitesmoke;
}

.inOneLine {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.ai-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #ff7e29, #ffbb72);
  color: black;
  font-weight: bold;
  border-radius: 20px;
  padding: 3px 5px;
  font-size: 12px;
  margin-left: 4px;
}

.regmenuR {
  position: relative;
}

.PositionAB {
  position: absolute;
  top: 3rem;
  /* right: 15rem; */
  width: 33%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 8px;
  border-radius: 8px;
}

.menushowbox {
  display: flex;
  width: auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly; /* More even spacing */
}

.MenuBox-item2 {
  width: 47%;
  display: block;
  padding: 10px 10px 0px 10px;
  cursor: pointer;
  white-space: normal; /* Allow wrapping */
  border: 1px solid white;
  border-radius: 5px;
  overflow-wrap: break-word; /* Break long words */
  text-transform: capitalize !important;
  border-bottom: 1px solid #1111;
}

.MenuBox-item2:hover {
  background-color: #f0f0f0; /* Light gray hover color */
}

.MenuBox-item2 h6 {
  font-family: var(--font-family-lato);
  font-size: 0.9rem; /* Adjust font size for better fitting */
  text-transform: uppercase !important;
  font-weight: 600;
  color: #404040;
}
.menubarUserIconDnone {
  display: none;
}
@media screen and (max-width: 575px) {
  .menubarUserIconDnone {
    display: block;
  }
  .menubarUsersIcons {
    margin-right: 5px;
  }
}
@media screen and (max-width: 767.8px) {
  .menubarUserIconDnone {
    display: block;
  }
}

@media (max-width: 1124px) {
  .nav-container {
    padding: 0.7rem 2rem;
  }
  .nav-link {
    margin: 0 !important;
  }
}
