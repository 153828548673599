.Toastify__toast-container {
  margin-top: 50px;
  z-index: 1000000 !important;
}
.scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 50px;
  font-size: 3rem;
  z-index: 1200000000;
  cursor: pointer;
  color: #0dcaf0a1;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
}

.text-danger {
  color: red;
  margin-left: 10px;
}
.Error-meg-login-register {
  color: red;
  margin-left: 10px;
  font-size: 14px;
  text-transform: lowercase;
}
.error-box {
  background-color: #ffcccc;
  color: #ff6600;
  border: 1px solid #ff0000;
  padding: 8px 20px;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.error-box-green {
  background-color: #ccffcc;
  color: #ff6600;
  border: 1px solid #00cc00;
  padding: 8px 20px;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.react-datepicker {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 10px;
}
.react-datepicker__header {
  padding: 15px 0 !important;
  background-color: #ffffff !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 10px !important;
  font-weight: 400;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.loadingBackdrop {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000000000;
  background-color: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
}
.text-danger {
  color: red;
}
.Error-meg-login-register {
  color: red;
  margin-left: 10px;
  font-size: 14px;
  text-transform: lowercase;
}

.typed-cursor {
  display: none;
}
.approveStatus {
  color: #4cee49;
  margin: 0 7px;
}

.disapproveStatus {
  color: #ff1414;
  margin: 0 7px;
}

.feedbackBorder {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  padding: 20px 40px;
  border-radius: 10px;
  margin: 20px !important;
}
#tickmarkimage {
  width: 20px;
  margin-left: 2px;
}
