.input-wrapper {
  position: relative;
}

.skill-input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.suggestions-dropdown {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  background-color: #f9f9f9;
}

.suggestion-item:hover {
  background-color: #ececec;
}

.skill-list {
  margin-top: 10px;
}

.skill-tag {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  background-color: #f0f0f0;
  border-radius: 15px;
  border: 1px solid #ccc;
  font-family: "Lato", sans-serif;
}

.remove-skill-btn {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
}

input {
  font-family: "Lato", sans-serif;
}
textarea {
  font-family: "Lato", sans-serif;
}
.uhdhf {
  font-family: "Lato", sans-serif;
}

.tag343 {
  border: 1px solid gray;
  border-radius: 19px;
  margin: 20px 0px;
  padding: 20px 0px;
}
.tageye {
  width: 99%;
  padding: 10px 20px;
  background: rgba(217, 217, 217, 0.05);
  border: 0.5px solid #afafaf;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.taglabel {
  font-family: var(--font-family-lato);
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.22;
  margin-bottom: 15px;
}

.dropdown {
  position: relative;
}

.dropdown input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 34%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #ddd;
}

.RecommendationText {
  font-family: lato;
  font-size: 12px;
  font-weight: 700;
  color: #848484;
}
.MentorRegInstitutePage {
  width: 100%;
  height: 11rem;
  background-color: #f9f9f9;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.MR-positionInstitute {
  position: relative;
}

.MentorPage2-EduD-Row {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.MentorProfile-EduD-Removebtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
.Mentorpage2-edu-Addbtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.mentorMicroHelpIcon {
  width: 25px;
  /* height: 25px; */
  color: #6f7276;
  /* border-radius: 50%; */
  text-align: center;
  /* line-height: 25px; */
  font-size: 14px;
  cursor: pointer;
  position: relative;
}
.mentorMicroHelpMessageDomain {
  position: absolute;
  /* left: 8rem; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  padding: 10px;
  background-color: rgb(249, 249, 249);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;

  z-index: 10;
}
.mentorMicroHelpMessageSkills {
  position: absolute;
  /* left: 6rem;
  top: -19%; */
  /* width: 50%; */
  /* transform: translateY(-50%); */
  padding: 10px;
  background-color: rgb(249, 249, 249);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
 
  z-index: 10;
}
.mentorMicroHelpMessagePrice{
  position: absolute;
  padding: 10px;
  background-color: rgb(249, 249, 249);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
 
  z-index: 10;
}
.Mentor-Microhelp-listFrontSize{
  font-size: 75%;
}
