.table-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 100%;
  overflow-x: auto;
}

.mentor-table {
  width: 100%;
  border-collapse: collapse;
}

.mentor-table th,
.mentor-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  text-align: center !important;
}

.mentor-table th {
  background-color: #f8f8f8;
  font-weight: bold;
  text-align: center !important;
}

.action-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  margin-right: 8px;
  padding: 5px 10px;
  border-radius: 4px;
}

.profile-button {
  background-color: #007bff;
  color: white;
}
.disapprove-button {
  background-color: #f84c40;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
}
.approve-button {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  padding: 8px 14px;
}
.invite-button {
  background-color: #28a745;
  color: white;
}
.mentor-active i {
  background-color: #28a745;
  font-size: 14px;
}
.profile-button:hover,
.invite-button:hover {
  opacity: 0.8;
}
