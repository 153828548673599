/* DetailedCaseStudy.css */
.dcs-wrapper {
  --dcs-primary: #2563eb;
  --dcs-primary-dark: #1e40af;
  --dcs-secondary: #3b82f6;
  --dcs-text-primary: #1f2937;
  --dcs-text-secondary: #4b5563;
  --dcs-bg-primary: #ffffff;
  --dcs-bg-secondary: #f8fafc;
  --dcs-accent: #3b82f6;

  min-height: 100vh;
  margin-top: 4rem;
  padding: 2rem;
  background: linear-gradient(135deg, #f0f9ff 0%, #e0f2fe 100%);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.dcs-visible {
  opacity: 1;
  transform: translateY(0);
}

.dcs-container {
  max-width: 1200px;
  margin: 0 auto;
  background: var(--dcs-bg-primary);
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.dcs-header {
  padding: 3rem 2rem;
  background: linear-gradient(
    135deg,
    var(--dcs-primary) 0%,
    var(--dcs-primary-dark) 100%
  );
  color: white;
}

.dcs-header-content {
  text-align: center;
}

.dcs-title {
  font-size: 2.75rem;
  font-weight: 800;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.dcs-header-decoration {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 1.5rem;
}

.dcs-header-decoration span {
  width: 40px;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

.dcs-header-decoration span:nth-child(2) {
  width: 60px;
  background: rgba(255, 255, 255, 0.6);
}

.dcs-content {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 3rem;
  padding: 2rem 2rem;
}

.dcs-details-card {
  background: var(--dcs-bg-secondary);
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.dcs-details-card:hover {
  transform: translateY(-4px);
}

.dcs-detail-item {
  margin-bottom: 2.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.dcs-detail-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.dcs-detail-label {
  margin-bottom: 1rem;
  position: relative;
}

.dcs-label-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--dcs-primary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background: var(--dcs-bg-secondary);
  padding-right: 1rem;
  position: relative;
  z-index: 1;
}

.dcs-label-line {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background: #e5e7eb;
  z-index: 0;
}

.dcs-text {
  color: var(--dcs-text-secondary);
  line-height: 1.7;
  margin: 0;
  font-size: 1.05rem;
}

.dcs-character-count {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.dcs-count-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--dcs-primary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.dcs-count-label {
  color: var(--dcs-text-secondary);
  font-size: 0.9rem;
}

.dcs-roles-list {
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 0;
}

.dcs-role-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  background: rgba(59, 130, 246, 0.05);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.dcs-role-item:hover {
  transform: translateX(8px);
  background: rgba(59, 130, 246, 0.1);
}

.dcs-role-bullet {
  width: 8px;
  height: 8px;
  background: var(--dcs-primary);
  border-radius: 50%;
  margin-right: 1rem;
}

.dcs-role-text {
  color: var(--dcs-text-secondary);
}

.dcs-image-card {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.dcs-image-container {
  position: relative;
  aspect-ratio: 4/3;
  overflow: hidden;
}

.dcs-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.dcs-image:hover {
  transform: scale(1.05);
}

.dcs-image-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  display: flex;
  align-items: flex-end;
  padding: 2rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.dcs-image-container:hover .dcs-image-overlay {
  opacity: 1;
}

.dcs-overlay-title {
  color: white;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

/* Loading State */
.dcs-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.dcs-spinner-ring {
  width: 48px;
  height: 48px;
  border: 4px solid var(--dcs-bg-secondary);
  border-top: 4px solid var(--dcs-primary);
  border-radius: 50%;
  animation: dcs-spin 1s linear infinite;
}

/* Error State */
.dcs-error {
  text-align: center;
  padding: 4rem 2rem;
}

.dcs-error-icon {
  width: 64px;
  height: 64px;
  background: #fee2e2;
  color: #dc2626;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 0 auto 1.5rem;
}

.dcs-back-btn {
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  background: var(--dcs-primary);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dcs-back-btn:hover {
  background: var(--dcs-primary-dark);
  transform: translateY(-2px);
}

.dcs-image-description {
  font-size: 1.1rem;
  color: var(--dcs-text-secondary);
  padding: 1rem;
  margin-top: 1rem;
}

@keyframes dcs-spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .dcs-content {
    grid-template-columns: 1fr;
  }

  .dcs-title {
    font-size: 2.25rem;
  }
}

@media (max-width: 768px) {
  .dcs-wrapper {
    padding: 1rem;
  }

  .dcs-header {
    padding: 2rem 1.5rem;
  }

  .dcs-content {
    padding: 2rem 1.5rem;
  }

  .dcs-details-card {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .dcs-title {
    font-size: 1.75rem;
  }

  .dcs-content {
    padding: 1.5rem 1rem;
  }

  .dcs-label-text {
    font-size: 1rem;
  }
}
