.preview-single-intern-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.preview-single-intern-back-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background: none;
  color: #0369a1;
  cursor: pointer;
  font-weight: 500;
}

.preview-single-intern-back-btn:hover {
  color: #075985;
}

.preview-single-intern-header {
  margin-top: 24px;
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.preview-single-intern-title {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 12px;
}

.preview-single-intern-company {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4b5563;
  margin-bottom: 16px;
}

.preview-single-intern-meta {
  display: flex;
  gap: 24px;
}

.preview-single-intern-meta-item {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 15px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
}

.preview-single-intern-menta-item-location {
  background-color: #e7eff6;
  color: #0f4c75;
  border-color: #0f4c75;
}

.preview-single-intern-menta-item-duration {
  background-color: #e8f0e9;
  color: #1b5e20;
  border-color: #1b5e20;
}

.preview-single-intern-menta-item-stipend.unpaid {
  background-color: #fbe9e7;
  color: #b71c1c;
  border-color: #b71c1c;
}

.preview-single-intern-menta-item-stipend.paid {
  background-color: #fff8e1;
  color: #795548;
  border-color: #795548;
}

.preview-single-intern-menta-item-supervision {
  background-color: #f3e5f5;
  color: #6a1b9a;
}
.preview-single-intern-menta-item-supervision.self {
  background-color: #fbe9e7;
  color: #b71c1c;
  border-color: #b71c1c;
}

.preview-single-intern-menta-item-supervision.guided {
  background-color: #f1f8e9;
  color: #2e7d32;
  border-color: #2e7d32;
}

.preview-single-intern-apply-btn {
  padding: 12px 24px;
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.preview-single-intern-apply-btn:hover {
  background: #1d4ed8;
}

.preview-single-intern-content {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
}

.preview-single-intern-section {
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.preview-single-intern-section-title {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 16px;
}

.preview-single-intern-text {
  color: #4b5563;
  line-height: 1.6;
  white-space: pre-line;
}

.preview-single-intern-list {
  list-style-type: disc;
  padding-left: 20px;
  color: #4b5563;
}

.preview-single-intern-list-item {
  margin-bottom: 8px;
  line-height: 1.6;
}

.preview-single-intern-skills,
.preview-single-intern-perks {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.preview-single-intern-skill-tag {
  padding: 6px 12px;
  background: #f3f4f6;
  color: #111827;
  border-radius: 4px;
  font-size: 14px;
}

.preview-single-intern-perk-tag {
  padding: 6px 12px;
  background: #f0f9ff;
  color: #0369a1;
  border-radius: 4px;
  font-size: 14px;
}

.preview-single-intern-sidebar {
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.preview-single-intern-company-meta {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}

.preview-single-intern-company-meta-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #6b7280;
}

@media (max-width: 768px) {
  .preview-single-intern-content {
    grid-template-columns: 1fr;
  }

  .preview-single-intern-meta {
    flex-direction: column;
    gap: 12px;
  }

  .preview-single-intern-header {
    flex-direction: column;
    gap: 16px;
  }

  .preview-single-intern-apply-btn {
    width: 100%;
  }
}
