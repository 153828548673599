/* Loader Container - Centers everything */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: opacity 0.5s ease-in-out;
  }
  
  /* Logo Styling */
  .loader-logo {
    width: 150px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 20px;
  }
  
  /* Dots Animation */
  .dots-container {
    display: flex;
    gap: 8px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    background-color: #3498db;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  /* Animating each dot with a delay */
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  /* Keyframe for bouncing dots */
  @keyframes bounce {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  /* Fade-out effect */
  .fade-out {
    opacity: 0;
  }
  
  .hidden {
    display: none;
  }
  