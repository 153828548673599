.jghgfghfghgf {
  width: 100%;
  padding: 20px 0;
  margin-top: 7rem;
}

.tjbghdfhbgfgf {
  display: flex;
}
.tjbghdfhbgfgf p {
  padding-right: 7px;
  font-size: 16px;
}
.tjbghdfhbgfgf span {
  font-weight: 600;
}

.nhbfgfd {
  display: flex;
}
.nhbfgfd p {
  padding-right: 20px;
  font-size: 16px;
}
.nhbfgfd i {
  color: var(--main-orange-color);
}
.nhbfgfd span {
  font-size: 14px;
}

.span121 i {
  color: rgb(240, 171, 9) !important;
}

.aslkhghj2 {
  padding: 100px 0;
  background: url("../../../Images/Courses/vbvb2.png");
  background-color: white;
}

.jgfgfg i {
  padding: 0 5px;
}

.kjfghdgvfgfghfh {
  width: 100%;
  padding: 40px 0;
}

.kdrhgjddf {
  display: flex;
  align-items: baseline;
}
.kdrhgjddf p {
  padding-right: 10px;
  color: gray;
  padding-top: 15px;
}
.kdrhgjddf h6 {
  padding-right: 10px;
  font-weight: 600;
  font-size: 17px;
  color: var(--main-orange-color);
}
.kdrhgjddf span {
  color: var(--main-orange-color) !important;
}

.spmjj {
  color: var(--main-orange-color) !important;
  font-weight: bold;
}

.nhjfg button {
  border: none;
  background-color: transparent;
  color: gray;
  padding: 12px 18px;
  border: 1px solid gray;
  border-radius: 50px;
  font-weight: 500;
}
.nhjfg button i {
  color: var(--main-orange-color);
  padding: 8px;
  border-radius: 50px;
  color: white;
  background-color: var(--main-orange-color);
}

.nhjfg {
  text-align: right;
}

.jdfhygdff {
  padding: 15px;
  border: 1px solid rgb(194, 190, 190);
  border-radius: 50px;
}

.nghjgfg55 {
  background-color: var(--main-orange-color);
  width: 100%;
  padding: 40px 30px;
  height: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.kdfhfgfg55 p {
  width: 80px;
  height: 25px;
  background-color: #fff;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 500 !important;
  line-height: 25px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding-left: 10px;
}

.mdfhjgbg h2 {
  font-size: 27px;
  color: white;
  font-weight: 500;
  margin-bottom: 0;
}
.mdfhjgbg span {
  font-size: 14px;
  color: white;
}
.mdfhjgbg p {
  margin-bottom: 0;
}

.jufbgyfr p {
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.kjhgdfgfd2 {
  display: flex;
}
.kjhgdfgfd2 p {
  padding-right: 15px;
  color: rgb(255, 255, 255);
  height: 25px !important;
}

.kjghgdg button {
  padding: 10px 15px;
  border: none;
  font-weight: 600;
  border-radius: 25px;
  margin-top: 10px;
}
.kjghgdg button i {
  padding-left: 5px;
  color: var(--main-orange-color);
}

