/* SimulationPage.css */
.main-container {
  margin-top: 4rem;
  display: flex;
  min-height: calc(100vh - 60px); /* Adjust based on your navbar height */
  padding: 20px 5px;
  gap: 20px;
  background-color: #f5f5f7;
}

.case-study-container {
  flex: 0 0 30%;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.case-study-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #eaeaea;
}

.case-study-container > .case-study-content {
  font-size: 1rem;
  line-height: 1;
  color: #444;
  flex-direction: column;
}

.case-study-content p {
  margin-bottom: 1rem;
}

.question-page-container {
  flex: 1;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}

.question-page-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #eaeaea;
}

.question-page-content {
  flex: 1;
  overflow-y: auto;
}

/* Scrollbar styling */
.case-study-container::-webkit-scrollbar,
.question-page-content::-webkit-scrollbar {
  width: 6px;
}

.case-study-container::-webkit-scrollbar-thumb,
.question-page-content::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 3px;
}

/* Responsive design */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .case-study-container {
    flex: none;
    width: 100%;
    height: auto;
    max-height: 40vh;
  }

  .question-page-container {
    height: auto;
    max-height: 60vh;
  }
}
