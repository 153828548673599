.options-container {

  margin: 10px;
}
.dashboardFlex {
  display: flex;
  margin-right: 20px;
  overflow-x: scroll;
}
.dashboardFlexWidth {
  width: 400px;
}
.main-option {
  border: 1.55px solid #7272ec; 
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  background-color: #ffffff;
}
.marginRight {
  margin-right: 16px;
}
.sub-option {
  margin-top: 15px;
  padding-left: 20px;
}

.optionH2 {
  color: #2c3e50;
  font-size: 24px !important;
  margin-bottom: 10px;
}

h3 {
  color: #34495e;
  font-size: 20px;
  margin-bottom: 8px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  font-size: 16px;

}

p {
  font-size: 16px;
  color: #7f8c8d;
}

.skillsButton {
  padding: 6px 10px;
  margin: 5px 3px;
  border: 1px solid rgb(216, 214, 214);
  border-radius: 6px;
  font-family: var(--font-family-lato);
  color: #444444;
  font-weight: 600;
}
