.uigbhuyguberer .kmg {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  min-height: 105px;
  line-height: 189px;
  overflow: hidden;
  position: relative;
  max-height: 105px;
}

.uigbhuyguberer .dfhjbghfjgfgh22 h4 {
  font-size: 1rem;
  margin-top: 5px;
}

.uigbhuyguberer .dfhjbghfjgfgh22 h6 {
  font-size: 0.8rem;
}

.uigbhuyguberer .njfgfd {
  padding: 0;
}

.uigbhuyguberer .kbfhgfgfg .btn-main {
  font-size: 0.8rem !important;
  padding: 7px 20px;
}

.bg-light {
  background-color: transparent !important;
}

.d-t:focus {
  box-shadow: none !important;
}

.carouselMentor {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.itemMentor {
  flex: 0 0 auto;
  padding: 5px 10px;
  border-radius: 50px;
  border: 1px solid #ccc;
  margin-right: 10px;
  scroll-snap-align: start;
  cursor: pointer;
}

.iduheirer_img {
  overflow: hidden;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.itemMentor h5 {
  font-size: 1.1rem !important;
  font-weight: 600;
}

.itemMentor.selected {
  background-color: var(--light-blue-border-color);
  color: #fff;
}

.contentMentor {
  display: none;
  margin-top: 20px;
}

.contentMentor.show {
  display: block;
}

.ghjfgghgf .wrapper {
  width: 100%;
  background: #fff;
  border-radius: 10px;
}
.ghjfgghgf .wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ghjfgghgf header .icons {
  display: flex;
}
.ghjfgghgf header .icons span {
  height: 38px;
  width: 38px;
  margin: 0 1px;
  cursor: pointer;
  color: #878787;
  text-align: center;
  line-height: 38px;
  font-size: 1.9rem;
  user-select: none;
  border-radius: 50%;
}
.ghjfgghgf .icons span:last-child {
  margin-right: -10px;
}
.ghjfgghgf header .icons span:hover {
  background: #f2f2f2;
}
.ghjfgghgf header .current-date {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0;
  font-style: italic;
}
.ghjfgghgf .calendar {
  padding: 0 !important;
  width: 100%;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none;
  height: auto !important;
}
.ghjfgghgf .calendar ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  text-align: center;
  padding-left: 0;
}
.ghjfgghgf .calendar .days {
  margin-bottom: 20px;
}
.ghjfgghgf .calendar li {
  color: #333;
  width: calc(100% / 7);
  font-size: 1rem;
}
.ghjfgghgf .calendar .weeks li {
  font-weight: 500;
  cursor: default;
}
.ghjfgghgf .calendar .days li {
  z-index: 1;
  cursor: pointer;
  position: relative;
  margin-top: 5px;
}
.ghjfgghgf .days li.inactive {
  color: #aaa;
}
.ghjfgghgf .days li.active {
  color: #fff;
}
.ghjfgghgf .days li::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  height: 30px;
  width: 30px;
  z-index: -1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.ghjfgghgf .days li.active::before {
  background: #2e64e3;
}
.ghjfgghgf .days li:not(.active):hover::before {
  background: #f2f2f2;
}

@media only screen and (max-width: 991px) {
  .mb-responsive-calendar {
    position: initial;
    transform: initial;
  }

  .fkjbghdfgfghghjygh {
    margin-top: 25px;
  }
}

.duiehirer_left {
  border-radius: 10px;
  overflow: hidden;
  height: 9rem;
}

.duieghr_inner {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 10px;
}

.duiehirer_right h5 {
  font-size: 1.1rem;
  font-weight: 600;
  position: relative;
}

.duiehirer_right h5:after {
  position: absolute;
  content: "";
  width: 2rem;
  height: 2px;
  border-radius: 25px;
  background: linear-gradient(to right, var(--main-orange-color), transparent);
  left: 0;
  bottom: -5px;
}

.duiehirer_right .btn {
  padding: 0.375rem 0.75rem !important;
  font-size: 0.8rem !important;
  margin-top: 8px;
}

.deoihrehr_Oirtuetr ul li {
  display: inline;
  padding: 0.2em 0.7rem;
  display: inline-block;
  border: 1px solid #dadce0;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 5px;
}

.deoihrehr_Oirtuetr ul li i {
  color: var(--main-orange-color);
}

.deoihrehr_Oirtuetr h6 {
  font-family: var(--font-family-lato);
}

.duiehirer_left img {
  width: 100%;
}

.duiehirer_right p {
  color: #000;
}

.gkbfgbg {
  width: 100%;
  height: 400px;
  position: relative;
}
.gkbfgbg img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.hjdfbtn button {
  margin-top: 15px;
  border-radius: 12px;
}

.dfknhguyfdgf {
  width: 100%;
  padding: 4rem 0 4rem;
}

.owl58 .kmg {
  text-align: center;
  position: relative;
  border-radius: 10px;
  min-height: initial !important;
  width: 5rem;
  height: 5rem;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.owl58 .kmg img {
  max-width: initial !important;
  max-height: initial !important;
  height: initial !important;
  width: 100% !important;
  transform: scale(1.4);
}

.dfjgjhdfgdf {
  padding: 10px;
}
.dfjgjhdfgdf h2 {
  font-size: 35px;
}

.vhffgfgf span {
  color: #063764 !important;
}

.ndfhjvdfv {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
}
.ndfhjvdfv h2 {
  padding: 5px 0;
  font-size: 30px;
  padding-top: 5px;
}
.ndfhjvdfv h3 {
  font-size: 1rem;
  letter-spacing: 0;
  color: #a7a7a7;
  font-weight: 400;
  margin-bottom: 0;
}

.ndfhjvdfv h3 i {
  color: var(--main-orange-color);
}

.hdfgfghfgh {
  padding: 20px 15px;
  margin-top: 6rem;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  background: #fff;
}
.hdfgfghfgh.sticky-top {
  top: 14%;
}
.hff h4 i,
.jvhfdfvgdf h4 i {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  line-height: 1.8;
  color: var(--main-orange-color);
  text-align: center;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.gtyfdgfgf {
  margin-top: 45px;
}

.ndfhjvdfv {
  padding: 0 5px;
}

.hff {
  padding: 10px 0;
  margin-top: 10px;
}

.hff p {
  color: gray;
  font-size: 16px;
  line-height: 2;
}

.dfbhfgg h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: var(--font-family-lato);
}
.dfbhfgg p {
  color: gray;
  font-size: 16px;
  padding-top: 5px;
  line-height: 24px;
}

.dfbhfgg i {
  color: var(--main-orange-color);
  padding-right: 5px;
}

.jvhfdfvgdf {
  margin: 20px 0;
}

.ggvgdfdf {
  margin-top: 20px;
}

.jghfg {
  width: 100%;
  height: 160px;
}
.jghfg img {
  width: 100%;
  height: 100%;
}

.jdfgghfdf {
  height: 350px;
  margin-bottom: 25px;
  background-color: #e5e2e2;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.jfbdf {
  padding: 10px;
}
.jfbdf h3 {
  font-size: 24px;
}
.jfbdf p {
  font-size: 16px;
  color: black;
  line-height: 22px;
}

.ftuhtrh {
  border: none;
  color: rgb(0, 0, 0);
  background-color: transparent;
  border-bottom: 2px solid var(--main-orange-color);
  padding: 8px 5px;
  font-weight: 600;
}
.ftuhtrh i {
  color: var(--main-orange-color);
}

.ftuhtrh:hover {
  background-color: var(--main-orange-color);
  color: white;
  transition: 0.5s;
  border-bottom: 2px solid rgb(3, 8, 104);
}
.ftuhtrh:hover i {
  color: white;
}

.dnfhjdff h4 {
  font-size: 25px;
  border-bottom: 2px solid var(--main-orange-color);
  width: 320px;
}

.fhf {
  width: 100%;
  height: 140px;
  padding: 10px;
}
.fhf img {
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 8px;
}

.fffddf {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.fffddf img {
  width: 100%;
  height: 100%;
}

.hbffgg22 h3 {
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  color: #2c2c2c;
}
.hbffgg22 p {
  color: gray;
  font-size: 16px;
  line-height: 22px;
}

.khgudfgghg {
  margin-top: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 10px;
  border-radius: 4px;
  background: #fff;
}

.gnhjfgf {
  text-align: right;
}

.nhjfg .form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 50px;
}

.nhjfg i {
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--light-blue-border-color);
}

.gnhjfgf button {
  padding: 6px 10px;
  border: none;
  font-size: 16px;
  margin-right: 10px;
  color: #2e64e3;
  font-weight: 600;
  background-color: #fff;
  font-family: var(--font-family-lato);
}

.rejugfgh h2 {
  text-align: center;
}

.njfgfd {
  width: 100%;
  padding: 10px;
}
.njfgfd img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.dfnghdfgdf {
  margin-top: 25px;
}

.dfnghdfgdf .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: var(--main-orange-color) !important;
}

.mghggfg {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 8px;
}

.fhjvddfvfb .carousel img {
  width: 70px;
  max-height: 70px;
  border-radius: 50%;
  margin-right: 1rem;
  overflow: hidden;
}
.fhjvddfvfb .carousel-inner {
  padding: 1em;
}
@media screen and (min-width: 576px) {
  .fhjvddfvfb .carousel-inner {
    display: flex;
    width: 90%;
    margin-inline: auto;
    padding: 1em 0;
    overflow: hidden;
  }
  .fhjvddfvfb .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 768px) {
  .fhjvddfvfb .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 33.3333333333%;
  }
}
.fhjvddfvfb .carousel .card {
  margin: 0 0.5em;
  border: 0;
  padding: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}
.fhjvddfvfb .carousel-control-prev,
.fhjvddfvfb .carousel-control-next {
  width: 6rem;
  height: 2rem;
  border-radius: 12;
  border: none;
  background-color: #fff;
  border: 2px solid var(--main-orange-color);
  border-radius: 4px;
  font-weight: 600;
  top: 50%;
  transform: translateY(-50%);
}

.nfdfdvdfg {
  text-align: center;
  margin-top: 50px;
}

.hgfgf {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}
.hgfgf img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.fgfghjg55 {
  padding: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin: 10px;
}

.hjvgdhvdffg {
  width: 100%;
  padding: 40px 0;
}

.dfghfg h4 {
  text-align: center;
}

.jhffgfhfgh .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: var(--main-orange-color) !important;
}

.udbeir_banner_image {
  width: 30%;
  border-radius: 10px;
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

#address[type="text"] {
  padding: 10px;
  width: 100%;
}

#error-msg {
  color: #c50707;
}

#map-canvas {
  height: auto;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.container_intdashboard {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  flex-wrap: wrap;
  object-fit: contain;
}

.item_intdashboard {
  border: 1px solid black;
  width: 16rem;
  height: 12rem;
  border-radius: 5px;
  padding: 1rem;
  padding-top: 3rem;
  box-shadow: 4px 4px 15px 2px rgba(0, 0, 255, 0.2);
  border-radius: 30px;
}
.valuedash {
  margin-top: 2rem;
  font-size: 21px;
}

.bg-img {
  background-image: url("../InstituteDashboard/download\ \(1\).jpg");
  background-position: center;
  background-size: contain;
  display: flex;
  height: auto;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}

.text-1 {
  text-shadow: 2px 5px 12px rgb(245 245 245 / 44%);
  color: white;
  margin-top: 10px;
}

.text-2 {
  text-shadow: 2px 5px 12px rgb(245 245 245 / 44%);
  color: white;
  margin-top: 5px;
}

.animation {
  border-radius: 5px;
  border: 1px solid #ebdddd;

  width: 95%;
  padding: 1rem;
  margin: 1rem auto;
}
.iconsize {
  font-size: 5rem;
  text-align: center;
  color: white;
  margin-bottom: 30px;
}
