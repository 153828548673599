.container-feedback {
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding: 30px;
  margin: 0px auto;
}

.h1-feedback {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
  font-size: 24px;
}

.form-group-feedback {
  margin-bottom: 15px;
}

.label-feedback {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555555;
}

.select-feedback,
.textarea-feedback {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 16px;
}

.textarea-feedback {
  resize: vertical;
}

.star-rating-feedback {
  direction: rtl;
  font-size: 24px;
  unicode-bidi: bidi-override;
  display: inline-flex;
  justify-content: space-around;
}

.star-rating-feedback input[type="radio"] {
  display: none;
}

.star-rating-feedback label {
  color: #cccccc;
  cursor: pointer;
  padding: 5px;
  font-size: 24px;
}

.star-rating-feedback input[type="radio"]:checked ~ label,
.star-rating-feedback label:hover,
.star-rating-feedback label:hover ~ label {
  color: #ffcc00;
}

.error-message-feedback {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.submit-btn-feedback {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn-feedback:hover {
  background-color: #0056b3;
}

.radio-group-feedback {
  display: flex;
  margin-top: 10px;
  gap: 30px;
  align-items: center;
}

.Mentee-EduDetails input[type="radio"] {
  margin-right: 10px;
}
