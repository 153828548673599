.intern-container {
  margin: 0 auto;
  padding: 20px;
  display: grid;
  gap: 0.5rem;
  margin-top: 1rem;
}

.intern-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
  transition: box-shadow 0.3s ease;
  position: relative;
  cursor: pointer;
}

.intern-card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.college-name {
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #2563eb;
}

.card-content {
  display: flex;
  gap: 20px;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.info-section {
  flex: 1;
}

.intern-name {
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0 0 4px 0;
}

.department {
  font-size: 18px;
  font-weight: 600;
  color: #2563eb;
  margin: 0 0 16px 0;
}

.dates-container {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.date-box {
  background: #f3f4f6;
  padding: 12px;
  border-radius: 8px;
  flex: 1;
}

.date-label {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 4px;
  display: block;
}

.date-value {
  font-weight: 600;
  color: #1a1a1a;
}

.progress-section {
  margin-top: 16px;
}

.progress-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #e5e7eb;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #2dbedb;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.im-container {
  width: 80%;
  margin: 0 auto;
  padding: 1.5rem;
  font-family: "Lato", sans-serif;
}

.im-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.im-header h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

.im-header-actions {
  display: flex;
  gap: 0.5rem;
}

.im-filter-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.375rem;
  background: white;
  cursor: pointer;
}

.im-intern-profile {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.im-back-button {
  background: none;
  border: none;
  color: #333;
  font-weight: 600;
  margin-bottom: 1rem;
  cursor: pointer;
}

.im-intern-header-card {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.im-profile-avatar {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  object-fit: cover;
}

.im-profile-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.im-profile-department {
  color: #666;
}

.im-profile-start-date {
  color: #999;
  font-size: 0.875rem;
}

.im-tabs-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid #e0e0e0;
}

.im-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
}

.im-tab-active {
  border-bottom: 2px solid #333;
  color: #2563eb;
  font-weight: 600;
}

.im-tab-content {
  margin-top: 1.5rem;
}

.im-card {
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
}

.im-card-header {
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.im-card-header h3 {
  font-weight: 600;
}

.im-card-content {
  padding: 1rem;
}

.im-report-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}

.im-report-week {
  font-weight: 500;
}

.im-report-date {
  color: #666;
  font-size: 0.875rem;
}

.im-report-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.im-report-status {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
}

.im-status-submitted {
  background-color: rgba(34, 197, 94, 0.1);
  color: #22c55e;
}

.im-status-pending {
  background-color: rgba(234, 179, 8, 0.1);
  color: #eab308;
}

.im-view-report-btn {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: 1px solid #e0e0e0;
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.im-payments-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.im-payment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 1rem;
  border-radius: 0.5rem;
}

.im-payment-month {
  font-weight: 500;
}

.im-payment-amount {
  color: #666;
  font-size: 0.875rem;
}

.im-payment-status {
  background-color: rgba(34, 197, 94, 0.1);
  color: #22c55e;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
}

.im-no-payments {
  text-align: center;
  color: #666;
  padding: 1rem;
}

.im-process-payment-btn {
  width: 100%;
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
}

.im-certificate-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.im-certificate-icon {
  font-size: 4rem;
  color: #999;
  margin-bottom: 1rem;
}

.im-certificate-status {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.im-certificate-description {
  color: #666;
  margin-bottom: 1rem;
}

.im-generate-certificate-btn {
  background-color: #2563eb;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
}

.im-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.im-modal-content {
  background-color: white;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.im-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.im-modal-header h3 {
  font-weight: 600;
}

.im-modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
}

.im-modal-body {
  padding: 1.5rem;
}

.im-report-section {
  margin-bottom: 1.5rem;
}

.im-report-section h4 {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.im-report-section p {
  color: #666;
}

.im-detail-label {
  color: #666;
  font-size: 0.875rem;
}

.im-detail-value {
  font-weight: 500;
}

.intern-status-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
}

.status-tab {
  padding: 0.75rem 1.5rem;
  border: none;
  background: none;
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

.status-tab.active {
  color: #2563eb;
  font-weight: 600;
}

.status-tab.active::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2563eb;
}

.no-interns-message {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.125rem;
  background: #f9f9f9;
  border-radius: 0.5rem;
}

.certificate-status {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #f3f4f6;
  border-radius: 0.375rem;
  text-align: center;
  font-weight: 500;
  color: #2563eb;
}

.im-add-intern-btn {
  background-color: #2563eb;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.im-add-intern-btn:hover {
  background-color: #1d4ed8;
}

.im-add-intern-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.im-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.im-form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #374151;
}

.im-form-input {
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.im-form-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.im-submit-btn {
  background-color: #2563eb;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 8px;
}

.im-submit-btn:hover {
  background-color: #1d4ed8;
}

@media (max-width: 640px) {
  .im-form-group {
    gap: 4px;
  }

  .im-form-input {
    padding: 6px 10px;
  }

  .im-submit-btn {
    padding: 8px 14px;
  }

  .intern-container {
    grid-template-columns: 1fr;
  }

  .intern-status-tabs {
    flex-direction: row;
    justify-content: stretch;
  }

  .status-tab {
    flex: 1;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .card-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-image {
    margin-bottom: 16px;
  }

  .college-name {
    position: static;
    text-align: center;
    margin-bottom: 16px;
  }

  .dates-container {
    flex-direction: column;
    gap: 12px;
  }

  .progress-header {
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
}
