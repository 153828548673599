.mentor-approval-card {
  width: 20rem;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background: #ffffff;
  font-family: Arial, sans-serif;
  text-align: center;
  transition: transform 0.3s ease;
  padding: 15px;
}

.mentor-approval-card:hover {
  transform: translateY(-5px);
}

.mentor-card-header {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding: 10px;
  /* background: linear-gradient(135deg, #6e8efb, #939294); */
  color: white;
  border-radius: 8px;
}

.mentor-photo {
  width: 8rem;
  border-radius: 15%;
  background-color: white;
}

.mentor-info {
  text-align: left;
}

.UpcomingUpdated-card-name {
  font-size: 2rem;
  margin: 0;
}


.mentor-role {
  font-size: 14px;
  opacity: 0.8;
}

.session-details,
.mentee-query {
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  text-align: left;
}
.mentee-query-scroll{
  height: 8rem;
  overflow-y: scroll;
}

.session-details-flex {

  display: flex;
  align-items: baseline;
  gap: 0.25rem;
}

.session-details h3,
.mentee-query h3 {
  font-size: 15px;
  margin: 0 0 5px;
  color: #333;
}

.session-details p,
.mentee-query p {
  font-size: 14px;
  color: #444;
}

.approval-warning {
  background: #ffe3e3;
  color: #d9534f;
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
}

.approve-btn {
  background: #28a745;
  color: white;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: background 0.3s;
}

.approve-btn:hover {
  background: #218838;
}

.MentorUpcomingSessionCard{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-around;
  
}