/* Main container styles */
.case-study-display-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 0;
}

.app-container {
  padding: 0 15px;
}

/* Filter container styles */
.case-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding: 0 15px;
}

/* Search input styles */
.case-search {
  position: relative;
  flex: 1;
  min-width: 250px;
}

.case-search-input {
  width: 100%;
  padding: 12px 15px;
  padding-right: 40px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.case-search-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.2);
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #777;
}

/* Sort dropdown styles */
.case-sort {
  position: relative;
}

.case-dropdown {
  position: relative;
  display: inline-block;
}

.case-dropbtn {
  background-color: white;
  color: #333;
  padding: 12px 15px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 150px;
  transition: all 0.3s ease;
}

.case-dropbtn:hover {
  background-color: #f9f9f9;
}

.case-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 8px;
  overflow: hidden;
  padding-top: 5px;
}

.case-dropdown:hover .case-dropdown-content {
  display: block;
}

.case-dropdown-content button {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.case-dropdown-content button:hover {
  background-color: #f1f1f1;
}

.case-dropdown-content button.active {
  background-color: #f1f9ff;
  color: #2563eb;
  font-weight: 500;
}

/* Clear filters button */
.case-clear-btn {
  background: none;
  border: none;
  color: #2563eb;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  transition: all 0.3s ease;
}

.case-clear-btn:hover {
  text-decoration: underline;
}

/* Case study grid layout */
.case-study-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
  margin-top: 20px;
}

/* Case study card styles */
.case-study-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.case-study-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
}

.case-study-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.case-study-content {
  padding: 20px;
}

.case-study-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
  line-height: 1.4;
}

.case-study-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.case-study-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 13px;
  color: #777;
}

.case-study-date {
  display: flex;
  align-items: center;
}

.case-study-date i {
  margin-right: 5px;
}

.case-study-category {
  background-color: #f1f9ff;
  color: #2563eb;
  padding: 3px 8px;
  border-radius: 4px;
  font-weight: 500;
}

.case-study-rating {
  margin-bottom: 15px;
}

.full-star {
  color: #ffd700;
}

.half-star {
  color: #ffd700;
}

.empty-star {
  color: #e0e0e0;
}

.case-study-price {
  font-size: 18px;
  font-weight: 600;
  color: #2563eb;
  margin-bottom: 15px;
}

.case-study-actions {
  display: flex;
  gap: 10px;
}

.view-details-btn {
  flex: 1;
  padding: 10px;
  background-color: white;
  color: #2563eb;
  border: 1px solid #2563eb;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.view-details-btn:hover {
  background-color: #f1f9ff;
}

.add-to-cart-btn {
  flex: 1;
  padding: 10px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #1d4ed8;
}

.buy-now-btn {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #10b981;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.buy-now-btn:hover {
  background-color: #059669;
}

/* Loading state */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #2563eb;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* No results state */
.no-results {
  text-align: center;
  padding: 40px 0;
  color: #666;
}

.reset-filters-btn {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.reset-filters-btn:hover {
  background-color: #1d4ed8;
}

/* Responsive styles */
@media (max-width: 768px) {
  .case-filter-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .case-search,
  .case-sort {
    width: 100%;
    margin-bottom: 10px;
  }

  .case-dropbtn {
    width: 100%;
    justify-content: space-between;
  }

  .case-study-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .case-study-grid {
    grid-template-columns: 1fr;
  }

  .case-study-actions {
    flex-direction: column;
  }

  .view-details-btn,
  .add-to-cart-btn,
  .buy-now-btn {
    width: 100%;
  }
}
