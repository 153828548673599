.emp-profile {
  width: 100%;
}
.emp-profile_main {
  padding: 1rem 8rem;
}
.orgFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.orgImageLogo {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}
.emp-profile_header {
  margin-bottom: 5rem;
  position: relative;
}

.emp-profile_header-content {
  position: absolute;
  margin-left: 8rem;
  left: 0;
  bottom: -10rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: center;
}

.emp-profile_avatar {
  background-color: white;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: #111827;
  border: 4px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.emp-profile_orgLogo-fallback {
  width: 70px;
  height: 70px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  text-transform: uppercase;
}

.emp-profile_name {
  font-size: 2rem;
  font-weight: 600;
  color: #111827;
  margin: 1rem 0 0.5rem;
}

.emp-profile_info-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.5rem;
  margin: 2rem 0;
}

.emp-profile_card {
  background-color: white;
  border-radius: 0.7rem;
  padding: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.emp-profile_card-title {
  font-size: 2rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0 !important;
}

.emp-profile_detail {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  color: #4b5563;
}

.emp-profile_detail i {
  width: 1.25rem;
  color: #6b7280;
}

.emp-profile_about {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.emp-profile_subtitle {
  font-size: 0.875rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.5rem;
}

.emp-profile_about p {
  color: #4b5563;
  line-height: 1.5;
}

.emp-profile_stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 2rem;
}

.emp-profile_stat-card {
  background-color: rgba(245, 245, 245, 0.829);
  width: 80%;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 0.8rem;
  text-align: center;
}

.emp-profile_stat-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: #111827;
  margin: 0;
}

.emp-profile_stat-label {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0.25rem 0 0;
}

.emp-profile_stats_section {
  background: #ffffff;
  padding: 2rem;
  border-radius: 0.7rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1000px) {
  .emp-profile_main {
    padding: 1rem 2rem;
  }
  .emp-profile_info-grid {
    grid-template-columns: 1fr;
  }

  .emp-profile_card-title {
    font-size: 1.5rem;
  }
  .emp-profile_header-content {
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0;
  }

  .emp-profile_name {
    font-size: 1.5rem;
  }

  .emp-profile_stats {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 640px) {
  .emp-profile_stats {
    grid-template-columns: 1fr;
  }
}
