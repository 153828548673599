.my-app-container {
  width: 100%;
  padding: 0 2rem;
}

.my-app-header {
  margin-bottom: 24px;
}

.my-app-search-wrapper {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.my-app-search-input {
  flex: 1;
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.my-app-search-input:focus {
  outline: none;
  border-color: #2563eb;
}

.my-app-search-btn {
  padding: 8px 24px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.my-app-search-btn:hover {
  background-color: #1d4ed8;
}

.my-app-table-container {
  width: 100%;
  overflow-x: auto;
}

.my-app-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.my-app-table thead {
  background-color: #f9fafb;
}

.my-app-table th,
.my-app-table td {
  border-bottom: 1px solid #e5e7eb;
  padding: 12px 16px;
  text-align: left;
}

.my-app-table th {
  font-weight: 600;
  color: #111827;
  text-transform: uppercase;
  font-size: 12px;
}

.my-app-table tr:last-child td {
  border-bottom: none;
}

.my-app-status {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.my-app-status-accepted {
  background-color: #e6f4ea;
  color: #1e8e3e;
}

.my-app-status-rejected {
  background-color: #fce8e6;
  color: #d93025;
}

.my-app-status-review {
  background-color: #e3f2fd;
  color: #1976d2;
}

.my-app-status-pending {
  background-color: #fff3e0;
  color: #f57c00;
}

.my-app-review-btn {
  padding: 6px 12px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.my-app-review-btn:hover {
  background-color: #1d4ed8;
}

@media (max-width: 768px) {
  .my-app-container {
    padding: 0 1rem;
  }

  .my-app-table {
    border: none;
    border-radius: 0;
  }

  .my-app-table thead {
    display: none;
  }

  .my-app-table,
  .my-app-table tbody,
  .my-app-table tr,
  .my-app-table td {
    display: block;
    width: 100%;
  }

  .my-app-table tr {
    margin-bottom: 16px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    overflow: hidden;
  }

  .my-app-table td {
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
  }

  .my-app-table td:last-child {
    border-bottom: none;
  }

  .my-app-table td::before {
    content: attr(data-label);
    font-weight: 600;
    color: #111827;
    text-transform: uppercase;
    font-size: 12px;
  }
}
