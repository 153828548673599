.UpdateProfile-PhoneD .react-tel-input .form-control {
  background-color: #e9ecef !important;
}

.MentorProfileWidthSize {
  width: 90%;
  margin: auto;
}

.save-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
}
.AvailabilitySlotsWidth {
  width: 80%;
  margin: auto;
}
.MentorProfileDispalySize {
  width: 80%;
  margin: auto;
}
.Mentor_Profile1 .dropdown-content {
  position: absolute;
  width: 100%;
  top: 3rem;
  background-color: #f9f9f9;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.MentorProfile1-eduDetails-boxsize {
  width: 49%;
}
.MentorProfile1MentorEdu-Flex {
  display: flex !important;
  justify-content: space-between;
}
