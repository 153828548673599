.featured-mentor-section {
  background: linear-gradient(90deg, #f3f6ff 0%, #fff 100%);
  padding: 5rem 24px;
}

.featured-mentor-container {
  max-width: 1300px;
  margin: auto;
}

.featured-mentor-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.featured-mentor-header-content {
  max-width: 700px;
}

.featured-mentor-title {
  font-size: 3.3rem;
  font-weight: 600;
  color: #1a1a1a;
}

.featured-mentor-description {
  font-size: clamp(1rem, 3vw, 1.25rem);
  color: #1f2937;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.featured-mentor-view-all {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--bs-cyan);
  color: white !important;
  font-weight: 500;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.featured-mentor-view-all:hover {
  background-color: #1d4ed8;
}

.featured-mentor-view-all-mobile {
  display: none;
}

.featured-mentor-card {
  background: white;
  border-radius: 1rem;
  height: 430px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.featured-mentor-card:hover {
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.1);
  transform: translateY(-4px) !important;
}

.featured-mentor-image-wrapper {
  position: relative;
  height: 280px;
  overflow: hidden;
  border-radius: 1rem;
}

.featured-mentor-profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featured-mentor-rating {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.412);
  padding: 6px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.featured-mentor-star-icon {
  width: 16px;
  height: 16px;
  fill: #fbbf24;
}

.featured-mentor-company-badge {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.featured-mentor-company-badge img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.featured-mentor-expertise-badge {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  opacity: 0;
  transform: translate(0, 100%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.featured-mentor-card:hover .featured-mentor-expertise-badge {
  opacity: 1;
  transform: translateY(0);
}

.featured-mentor-expertise-tag {
  background: #ffffffa0;
  color: #000000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.featured-mentor-details {
  padding: 16px;
}

.featured-mentor-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #1a1a1a;
}

.featured-mentor-position {
  color: #666;
  font-size: 14px;
  margin-bottom: 12px;
}

.featured-mentor-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.featured-mentor-skill-tag {
  background-color: #e0f2fe;
  color: #0369a1;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
}

.featured-mentor-view-btn {
  width: 100%;
  padding: 12px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.featured-mentor-view-btn:hover {
  background-color: #1d4ed8;
}

.featured-mentor-loading {
  text-align: center;
  padding: 40px;
  color: #666;
}

.featured-mentor-slider {
  width: 100%;
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .featured-mentor-section {
    padding: 3rem 2rem;
  }
  .featured-mentor-header {
    flex-direction: column;
    gap: 16px;
  }
  .featured-mentor-title {
    font-size: 36px;
  }

  .featured-mentor-description {
    margin-bottom: 1rem;
  }
  .featured-mentor-view-all {
    display: none;
  }

  .featured-mentor-view-all-mobile {
    display: flex;
    padding: 10px 20px;
    margin-top: 1rem;
    background-color: var(--bs-cyan);
    color: white !important;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s;
    justify-self: center;
  }
  .featured-mentor-view-all-mobile:hover {
    background-color: #1d4ed8;
  }
}
