.profile-tabs-container {
  display: flex;
  min-height: 80vh;
  background-color: #fff;
}
.profile-sidebar {
  background: #fff;
}
.profile-sidebar ul {
  padding: 1rem;
}
.profile-sidebar-item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 8px;
  list-style-type: none;
  text-align: center;
  margin-bottom: 0.5rem;
  transition: all 0.3s;
}
.profile-sidebar-item:hover {
  background: rgba(171, 209, 255, 0.33);
}
.profile-sidebar-item i {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #333;
}
.profile-sidebar-item-active {
  background: rgba(171, 209, 255, 0.33);
  color: blue;
}
.profile-sidebar-item-active i {
  color: blue;
}
.profile-main-content {
  width: 100%;
  padding: 2rem;
}
.profile-card {
  background: #fff;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.profile-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}
.profile-form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}
.profile-label {
  display: block;
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 0.5rem;
}
.profile-input,
.profile-select,
.profile-textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
}
.profile-textarea {
  resize: none;
}
.profile-buttons {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}
.profile-button-save {
  background: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}
.profile-button-save:hover {
  background: #0056b3;
}
.profile-button-cancel {
  background: #f0f0f0;
  color: #333;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}
.profile-button-cancel:hover {
  background: #ddd;
}
@media (max-width: 768px) {
  .profile-sidebar {
    width: 100%;
    height: 10%;
    position: fixed;
    bottom: 0;
    margin: 0;
    border: 1px solid rgb(204, 204, 204);
  }
  .profile-sidebar ul {
    display: flex;
    justify-content: space-around;
  }
  .profile-sidebar-item-name {
    font-size: 0.7rem;
  }
}
