.ai-casestudy-featured-section {
  padding: 3rem 2rem;
  background-color: #ffffff;
}

.ai-casestudy-featured-header {
  text-align: center;
  margin-bottom: 3rem;
}

.ai-casestudy-featured-title {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.ai-casestudy-featured-subtitle {
  font-size: 1.25rem;
  color: #6b7280;
}

.ai-casestudy-featured-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 3rem;
}

.ai-casestudy-card {
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
}

.ai-casestudy-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.ai-casestudy-card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.ai-casestudy-card-content {
  padding: 1.5rem;
}

.ai-casestudy-card-category {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: #f3f4f6;
  color: #4b5563;
  border-radius: 9999px;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.ai-casestudy-card-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.75rem;
}

.ai-casestudy-card-description {
  color: #6b7280;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.ai-casestudy-card-stats {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.ai-casestudy-stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.ai-casestudy-stat-icon {
  width: 1rem;
  height: 1rem;
}

.ai-casestudy-card-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #2563eb;
  color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ai-casestudy-card-button:hover {
  background-color: #1d4ed8;
}

.ai-casestudy-button-icon {
  width: 1rem;
  height: 1rem;
}

.ai-casestudy-featured-footer {
  display: flex;
  justify-content: center;
}

.ai-casestudy-view-all {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #2563eb;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  border: 2px solid #2563eb;
  border-radius: 0.5rem;
  background: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ai-casestudy-view-all:hover {
  background-color: #eff6ff;
}

.ai-casestudy-view-icon {
  width: 1rem;
  height: 1rem;
}

@media (min-width: 768px) {
  .ai-casestudy-featured-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .ai-casestudy-featured-section {
    padding: 5rem 24px;
  }
  .ai-casestudy-featured-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .ai-casestudy-featured-container {
    max-width: 1300px;
    margin: 0 auto;
  }
}
