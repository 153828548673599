.mentor-cta-section {
  background-color: #002564;
  padding: 5rem 24px;
  position: relative;
}

.mentor-cta-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.mentor-cta-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: -20px;
  left: 60px;
  width: 230px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  z-index: 0;
  background-image: url("../images/HumaaansMeeting.png");
}

.mentor-cta-main-text {
  color: white;
  overflow: hidden;
  font-size: 3.3rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.mentor-cta-sub-text {
  font-size: clamp(1rem, 3vw, 1.25rem);
  color: #ffffff;
  line-height: 1.5;
}

.mentor-cta-right-content {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 20px;
}

.mentor-cta-card {
  background-color: #ffffff;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 1.5rem;
  margin: 20px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: all 0.3s;
}

.mentor-cta-card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
}

.mentor-cta-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000 !important;
  margin-bottom: 1rem;
}
.mentor-cta-card-feature {
  display: flex;
  margin-bottom: 10px;
}
.mentor-cta-card-feature p {
  margin: 0;
  line-height: 1.3;
}

.mentor-cta-card-icon {
  margin-right: 10px;
  width: 30px;
  margin-top: 5px;
  color: #0255ca;
  justify-content: center;
}

.mentor-cta-card-icon i {
  font-size: 1.3rem;
  justify-self: center;
}

.mentor-cta-card-btn {
  background-color: #0255ca;
  width: 90%;
  color: white;
  padding: 10px 20px;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin-top: auto;
  align-self: center;
}

.mentor-cta-card-btn:hover {
  background-color: #003366;
}

@media (max-width: 768px) {
  .mentor-cta-section {
    padding: 3rem 2rem;
  }
  .mentor-cta-right-content {
    flex-direction: column;
  }
  .mentor-cta-main-text {
    font-size: 2.5rem;
  }
  .mentor-cta-card {
    width: 100%;
    margin: 0;
  }
}
