.mob-flex {
  margin: 1rem auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
}

.mob-allmentor-container {
  min-width: 250px;
  max-width: 400px;
}

@media (max-width: 990px) {
  .mob-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: inherit auto;
  }

  .full-dis {
    width: 90%;
    padding: 1%;
  }

  .mob-t0 {
    top: 0 !important;
  }
  .fs2r {
    font-size: 2.5rem !important;
  }
  .filter-button {
    width: 50%;
  }
  .hide {
    display: none !important;
  }

  .mob-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 560px) {
  .filter-button {
    width: 95%;
  }
  .csdpeijf {
    padding: 2rem;
  }
}
