.internship-section-hero-section {
  background: linear-gradient(90deg, #e0e7ff 1.52%, #f7f7f7 99.87%);
  display: flex;
  align-items: center;
  padding: 5rem 24px;
  position: relative;
}
.internship-section-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.internship-section-hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 150px;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  z-index: 0;
  background-image: url("../images/creative-team-img.svg");
}

.internship-section-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  align-items: center;
}

.internship-section-content-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.internship-section-badge {
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  background-color: #dedcff;
  color: #4f46e5;
  font-size: 0.875rem;
  margin-bottom: 3rem;
}

.internship-section-title {
  font-size: 3.3rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #111827;
  line-height: 1.2;
}

.internship-section-highlight {
  color: #2563eb;
}

.internship-section-description {
  font-size: clamp(1rem, 3vw, 1.25rem);
  color: #1f2937;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.internship-section-button-group {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}

.internship-section-primary-button {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: #2563eb;
  color: white;
  padding: 0.875rem 2rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
  z-index: 1;
}

.internship-section-primary-button:hover {
  background-color: #1d4ed8;
}

.internship-section-badges-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.internship-section-feature-badge {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  background-color: white;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.internship-section-badge-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.75rem;
}

.internship-section-badge-icon i {
  font-size: 1.25rem;
}

.internship-section-feature-badge:first-child .internship-section-badge-icon {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.internship-section-feature-badge:last-child .internship-section-badge-icon {
  background-color: #f3e8ff;
  color: #7e22ce;
}

.badge-content h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.5rem;
}

.badge-content p {
  font-size: 0.875rem;
  color: #6b7280;
  line-height: 1.5;
}

.internship-section-preview-image {
  width: 100%;
  max-width: 500px;
  margin-top: 2rem;
}

.internship-section-image-column {
  display: none;
}

.internship-section-image-column-mobile {
  display: flex;
}

@media (min-width: 768px) {
  .internship-section-hero-section {
    min-height: 95vh;
  }
  .internship-section-button-group {
    justify-content: center;
  }

  .internship-section-image-column-mobile {
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  .internship-section-grid {
    grid-template-columns: repeat(12, 1fr);
  }

  .internship-section-content-column {
    grid-column: span 6;
  }

  .internship-section-button-group {
    justify-content: flex-start;
  }

  .internship-section-image-column-mobile {
    display: none;
  }

  .internship-section-image-column {
    grid-column: span 6;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .internship-section-badges-container {
    width: 100%;
    max-width: 500px;
  }
  .internship-section-hero-section::before {
    top: 0;
    bottom: 0;
    left: 40%;
    width: 230px;
  }
}

@media (max-width: 1024px) {
  .internship-section-title {
    font-size: 2.5rem;
  }

  .internship-section-image-column {
    order: -1;
  }

  .internship-section-preview-image {
    margin: 0 auto;
  }
}
