.ye-waala-naya-h-dusra-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.ye-waala-naya-h-dusra-nevigation-indication,
.ye-waala-naya-h-dusra-nevigation-indication i {
  font-size: 14px;
  color: #666;
  padding: 4px;
}

/* Header styles */
.ye-waala-naya-h-dusra-header {
  margin-bottom: 24px;
}

.ye-waala-naya-h-dusra-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.ye-waala-naya-h-dusra-meta {
  color: #666;
  font-size: 14px;
}

.ye-waala-naya-h-dusra-dot {
  margin: 0 8px;
}

/* Metrics section */
.ye-waala-naya-h-dusra-metrics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 24px;
}

.ye-waala-naya-h-dusra-metric {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ye-waala-naya-h-dusra-metric-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: white;
}

.ye-waala-naya-h-dusra-blue {
  background-color: #2979ff;
}

.ye-waala-naya-h-dusra-green {
  background-color: #4cd964;
}

.ye-waala-naya-h-dusra-purple {
  background-color: #5e5ce6;
}

.ye-waala-naya-h-dusra-orange {
  background-color: #ff9500;
}

.ye-waala-naya-h-dusra-metric-content h3 {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-bottom: 4px;
}

.ye-waala-naya-h-dusra-metric-value {
  font-size: 24px;
  font-weight: 700;
}

/* Charts section */
.ye-waala-naya-h-dusra-charts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 24px;
}

.ye-waala-naya-h-dusra-chart-container {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ye-waala-naya-h-dusra-chart-container h3 {
  font-size: 16px;
  margin-bottom: 16px;
}

.ye-waala-naya-h-dusra-chart-container h3 {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  color: #333;
}

.ye-waala-naya-h-dusra-bar-chart,
.ye-waala-naya-h-dusra-donut-chart,
.ye-waala-naya-h-dusra-line-chart {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ye-waala-naya-h-dusra-chart-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
}

.ye-waala-naya-h-dusra-legend-item {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.ye-waala-naya-h-dusra-legend-color {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 4px;
}

.ye-waala-naya-h-dusra-legend-label {
  color: #666;
}

/* Student list section */
.ye-waala-naya-h-dusra-student-list {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ye-waala-naya-h-dusra-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ye-waala-naya-h-dusra-list-header h2 {
  font-size: 18px;
  font-weight: 600;
}

.ye-waala-naya-h-dusra-search-export {
  display: flex;
  gap: 12px;
}

.ye-waala-naya-h-dusra-search {
  position: relative;
}

.ye-waala-naya-h-dusra-search input {
  padding: 8px 12px 8px 36px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 240px;
  outline: none;
  transition: border-color 0.2s;
}

.ye-waala-naya-h-dusra-search input:focus {
  border-color: #2979ff;
}

.ye-waala-naya-h-dusra-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.ye-waala-naya-h-dusra-export-btn {
  background-color: #2979ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;
}

.ye-waala-naya-h-dusra-export-btn:hover {
  background-color: #2962ff;
}

/* Table styles */
.ye-waala-naya-h-dusra-table-container {
  overflow-x: auto;
  margin-bottom: 20px;
}

.ye-waala-naya-h-dusra-table {
  width: 100%;
  border-collapse: collapse;
}

.ye-waala-naya-h-dusra-table th {
  text-align: left;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
  border-bottom: 1px solid #eee;
}

.ye-waala-naya-h-dusra-table td {
  padding: 12px 16px;
  font-size: 14px;
  border-bottom: 1px solid #eee;
}

.ye-waala-naya-h-dusra-student-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.ye-waala-naya-h-dusra-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.ye-waala-naya-h-dusra-status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.ye-waala-naya-h-dusra-status-completed {
  background-color: rgba(76, 217, 100, 0.1);
  color: #4cd964;
}

.ye-waala-naya-h-dusra-status-in-progress {
  background-color: rgba(41, 121, 255, 0.1);
  color: #2979ff;
}

.ye-waala-naya-h-dusra-status-not-started {
  background-color: rgba(158, 158, 158, 0.1);
  color: #9e9e9e;
}

.ye-waala-naya-h-dusra-status-overdue {
  background-color: rgba(255, 59, 48, 0.1);
  color: #ff3b30;
}

.ye-waala-naya-h-dusra-progress-bar {
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.ye-waala-naya-h-dusra-progress-fill {
  height: 100%;
  background-color: #2979ff;
  border-radius: 4px;
}

.ye-waala-naya-h-dusra-action-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;
}

.ye-waala-naya-h-dusra-action-btn:hover {
  color: #333;
}

/* Action dropdown styling */
.ye-waala-naya-h-dusra-action-dropdown {
  position: relative;
  display: inline-block;
}

.ye-waala-naya-h-dusra-action-btn {
  background: transparent;
  border: none;
  color: #6b7280;
  cursor: pointer;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.ye-waala-naya-h-dusra-action-btn:hover {
  background-color: #2962ff;
  color: #fff;
}

.ye-waala-naya-h-dusra-dropdown-menu {
  position: absolute;
  right: 0;
  z-index: 10;
  min-width: 160px;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
}

.ye-waala-naya-h-dusra-dropdown-menu button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  color: #374151;
  transition: background-color 0.2s;
}

.ye-waala-naya-h-dusra-dropdown-menu button:hover {
  background-color: #f3f4f6;
  color: #2962ff;
}

.ye-waala-naya-h-dusra-dropdown-menu button svg {
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
}

/* Add Student button styling */
.ye-waala-naya-h-dusra-add-btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #fff;
  border: 1px solid #2962ff;
  color: #2962ff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ye-waala-naya-h-dusra-add-btn:hover {
  background-color: #2962ff;
  color: #fff;
}

.ye-waala-naya-h-dusra-add-btn svg {
  margin-right: 0.5rem;
}

/* Modal styling */
.ye-waala-naya-h-dusra-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.ye-waala-naya-h-dusra-modal {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.ye-waala-naya-h-dusra-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.ye-waala-naya-h-dusra-modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
}

.ye-waala-naya-h-dusra-modal-close {
  background: transparent;
  border: none;
  color: #6b7280;
  cursor: pointer;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
}

.ye-waala-naya-h-dusra-modal-close:hover {
  color: #ef4444;
}

.ye-waala-naya-h-dusra-modal-body {
  padding: 1.5rem;
}

.ye-waala-naya-h-dusra-form-group {
  margin-bottom: 1rem;
}

.ye-waala-naya-h-dusra-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #374151;
}

.ye-waala-naya-h-dusra-form-group input,
.ye-waala-naya-h-dusra-form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: border-color 0.2s;
}

.ye-waala-naya-h-dusra-form-group input:focus,
.ye-waala-naya-h-dusra-form-group select:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.15);
}

.ye-waala-naya-h-dusra-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.ye-waala-naya-h-dusra-btn-secondary,
.ye-waala-naya-h-dusra-btn-primary {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ye-waala-naya-h-dusra-btn-secondary {
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  color: #4b5563;
  margin-right: 0.5rem;
}

.ye-waala-naya-h-dusra-btn-secondary:hover {
  background-color: #e5e7eb;
}

.ye-waala-naya-h-dusra-btn-primary {
  background-color: #4f46e5;
  border: none;
  color: white;
}

.ye-waala-naya-h-dusra-btn-primary:hover {
  background-color: #4338ca;
}

/* Pagination */
.ye-waala-naya-h-dusra-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ye-waala-naya-h-dusra-pagination-info {
  font-size: 14px;
  color: #666;
}

.ye-waala-naya-h-dusra-pagination-controls {
  display: flex;
  gap: 8px;
}

.ye-waala-naya-h-dusra-pagination-btn {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.ye-waala-naya-h-dusra-pagination-btn:hover:not(:disabled) {
  background-color: #f5f5f5;
}

.ye-waala-naya-h-dusra-pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ye-waala-naya-h-dusra-pagination-btn-primary {
  background-color: #2979ff;
  color: white;
  border-color: #2979ff;
}

.ye-waala-naya-h-dusra-pagination-btn-primary:hover:not(:disabled) {
  background-color: #2962ff;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .ye-waala-naya-h-dusra-metrics {
    grid-template-columns: repeat(2, 1fr);
  }

  .ye-waala-naya-h-dusra-charts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .ye-waala-naya-h-dusra-charts {
    grid-template-columns: 1fr;
  }

  .ye-waala-naya-h-dusra-list-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .ye-waala-naya-h-dusra-search-export {
    width: 100%;
    flex-direction: column;
  }

  .ye-waala-naya-h-dusra-search input {
    width: 100%;
  }

  .ye-waala-naya-h-dusra-pagination {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }
}

@media (max-width: 576px) {
  .ye-waala-naya-h-dusra-metrics {
    grid-template-columns: 1fr;
  }

  .ye-waala-naya-h-dusra-table th:nth-child(3),
  .ye-waala-naya-h-dusra-table td:nth-child(3),
  .ye-waala-naya-h-dusra-table th:nth-child(5),
  .ye-waala-naya-h-dusra-table td:nth-child(5) {
    display: none;
  }
}
