.recent_internships_container {
  padding: 3rem 2rem;
}

.recent_internships_header {
  text-align: center;
  margin-bottom: 2rem;
}

.recent_internships_header h2 {
  font-size: 2rem;
  font-weight: bold;
}

.recent_internships_highlight {
  color: #2563eb;
}

.recent_internships_slider_wrapper {
  position: relative;
}

.recent_internships_slider_content {
  overflow: hidden;
  margin: 0 -1rem;
}

.recent_internships_slider_track {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.recent_internships_card {
  padding: 1.5rem;
}

.recent_internships_card_inner {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
}

.recent_internships_card_inner:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.recent_internships_card_header {
  margin-bottom: 1rem;
}

.recent_internships_supervision_type {
  color: #2563eb;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.recent_internships_card_header h3 {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0.5rem 0;
  color: #111827;
}

.recent_internships_company_name {
  color: #6b7280;
  font-size: 0.875rem;
}

.recent_internships_card_details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.recent_internships_detail_item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.recent_internships_view_details {
  color: #2563eb;
  font-size: 0.875rem;
  font-weight: 500;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  align-self: flex-end;
  transition: color 0.2s;
}

.recent_internships_view_details:hover {
  color: #1d4ed8;
}

.recent_internships_nav_button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4b5563;
  z-index: 1;
  transition: background-color 0.2s;
}

.recent_internships_nav_button:hover {
  background-color: #f9fafb;
}

.recent_internships_prev {
  left: -1rem;
}

.recent_internships_next {
  right: -1rem;
}

.recent_internships_dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.recent_internships_dot {
  width: 0.5rem;
  height: 0.25rem;
  border: none;
  border-radius: 0.125rem;
  background: #e5e7eb;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}

.recent_internships_dot_active {
  width: 2rem;
  background: #2563eb;
}

@media (min-width: 640px) {
  .recent_internships_container {
    padding: 3rem 5rem;
  }

  .recent_internships_header h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .recent_internships_container {
    padding: 5rem 24px;
    max-width: 1300px;
    margin: auto;
  }

  .recent_internships_header h2 {
    font-size: 3.3rem;
  }

  .recent_internships_nav_button {
    width: 3rem;
    height: 3rem;
  }

  .recent_internships_prev {
    left: -1.5rem;
  }

  .recent_internships_next {
    right: -1.5rem;
  }
}

/*  */
.recent_internships_container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
}

.recent_internships_illustration {
  width: 16rem;
  height: 12rem;
  margin-bottom: 1.5rem;
}

.recent_internships_bg_circle_large {
  fill: #f0f5ff;
}

.recent_internships_bg_circle_small {
  fill: #e6edff;
}

.recent_internships_briefcase_top,
.recent_internships_briefcase_bottom {
  fill: #4477ff;
}

.recent_internships_clock_face {
  fill: white;
}

.recent_internships_clock_hands {
  stroke: #4477ff;
  stroke-width: 3;
  stroke-linecap: round;
}

.recent_internships_clock_center {
  fill: #4477ff;
}

.recent_internships_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.75rem;
  text-align: center;
}

.recent_internships_description {
  color: #666666;
  margin-bottom: 1.5rem;
  text-align: center;
  max-width: 28rem;
  line-height: 1.5;
}

.recent_internships_button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #4477ff;
  color: white;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.recent_internships_button:hover {
  background-color: #3366ee;
}

.recent_internships_button_icon {
  width: 1.25rem;
  height: 1.25rem;
}

@media (max-width: 640px) {
  .recent_internships_container-inner {
    padding: 1.5rem;
  }

  .recent_internships_illustration {
    width: 12rem;
    height: 9rem;
  }

  .recent_internships_title {
    font-size: 1.25rem;
  }
}
