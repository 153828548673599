.ye-waala-naya-h-container {
  font-family: "Lato", sans-serif;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
}

/* Header styles */
.ye-waala-naya-h-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
}

.ye-waala-naya-h-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: #1a1a1a;
}

.ye-waala-naya-h-subtitle {
  font-size: 14px;
  color: #666;
  margin: 4px 0 0 0;
}

.ye-waala-naya-h-actions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.ye-waala-naya-h-search {
  position: relative;
  width: 240px;
}

.ye-waala-naya-h-search input {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  padding: 10px 10px 10px 36px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.ye-waala-naya-h-search input:focus {
  border-color: #4285f4;
}

.ye-waala-naya-h-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.ye-waala-naya-h-add-button {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;
}

.ye-waala-naya-h-add-button:hover {
  background-color: #3367d6;
}

/* Metrics section */
.ye-waala-naya-h-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 24px;
}

.ye-waala-naya-h-metric-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.ye-waala-naya-h-metric-icon {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #4285f4;
  font-size: 18px;
}

.ye-waala-naya-h-metric-content {
  flex: 1;
}

.ye-waala-naya-h-metric-label {
  font-size: 14px;
  color: #666;
  margin: 0 0 4px 0;
}

.ye-waala-naya-h-metric-value {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 4px 0;
  color: #1a1a1a;
}

.ye-waala-naya-h-metric-change {
  font-size: 12px;
  margin: 0;
}

.ye-waala-naya-h-metric-change.positive {
  color: #4caf50;
}

.ye-waala-naya-h-metric-change.negative {
  color: #f44336;
}

.ye-waala-naya-h-metric-urgent {
  font-size: 12px;
  color: #ff9800;
  margin: 0;
}

.ye-waala-naya-h-metric-trend {
  position: absolute;
  top: 20px;
  right: 20px;
}

.ye-waala-naya-h-metric-trend.positive {
  color: #4caf50;
}

.ye-waala-naya-h-metric-trend.negative {
  color: #f44336;
}

.ye-waala-naya-h-metric-trend.warning {
  color: #ff9800;
}

/* Filters section */
.ye-waala-naya-h-filters {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
  background-color: #f9f9f9;
  padding: 12px 16px;
  border-radius: 8px;
}

.ye-waala-naya-h-filter-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

.ye-waala-naya-h-filter-dropdowns {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  flex: 1;
}

.ye-waala-naya-h-filter-dropdowns select {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  min-width: 140px;
  outline: none;
  cursor: pointer;
}

.ye-waala-naya-h-filter-dropdowns select:focus {
  border-color: #4285f4;
}

/* Case studies grid */
.ye-waala-naya-h-case-studies {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.ye-waala-naya-h-case-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.ye-waala-naya-h-case-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ye-waala-naya-h-case-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #1a1a1a;
}

.ye-waala-naya-h-case-menu {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 4px;
  border-radius: 4px;
}

.ye-waala-naya-h-case-menu:hover {
  background-color: #f5f5f5;
}

.ye-waala-naya-h-case-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ye-waala-naya-h-case-detail {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
}

.ye-waala-naya-h-case-detail svg {
  color: #999;
  width: 16px;
}

.ye-waala-naya-h-case-progress {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ye-waala-naya-h-progress-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
}

.ye-waala-naya-h-progress-bar {
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.ye-waala-naya-h-progress-fill {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.ye-waala-naya-h-case-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.ye-waala-naya-h-case-status {
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 16px;
}

.ye-waala-naya-h-case-status.active {
  background-color: #e8f5e9;
  color: #4caf50;
}

.ye-waala-naya-h-case-status.completed {
  background-color: #e3f2fd;
  color: #2196f3;
}

.ye-waala-naya-h-case-status.overdue {
  background-color: #ffebee;
  color: #f44336;
}

.ye-waala-naya-h-view-details {
  border: none;
  background-color: white;
  font-size: 14px;
  color: #4285f4;
  text-decoration: none;
  font-weight: 500;
}

.ye-waala-naya-h-view-details:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .ye-waala-naya-h-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .ye-waala-naya-h-actions {
    width: 100%;
    flex-direction: column;
  }

  .ye-waala-naya-h-search {
    width: 100%;
  }

  .ye-waala-naya-h-add-button {
    width: 100%;
    justify-content: center;
  }

  .ye-waala-naya-h-filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .ye-waala-naya-h-filter-dropdowns {
    width: 100%;
  }

  .ye-waala-naya-h-filter-dropdowns select {
    flex: 1;
    min-width: 0;
  }

  .ye-waala-naya-h-case-studies {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .ye-waala-naya-h-metrics {
    grid-template-columns: 1fr;
  }

  .ye-waala-naya-h-filter-dropdowns {
    flex-direction: column;
  }
}
