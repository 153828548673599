.practy-testimonials-section {
  padding: 5rem 24px;
}
.practy-testimonials-container {
  width: 100%;
}

.practy-testimonials-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.practy-testimonials-title h2 {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
}

.practy-testimonials-subtitle {
  color: #666;
  margin-top: 8px;
}

.practy-testimonials-nav {
  display: flex;
  gap: 12px;
}

.practy-testimonials-nav-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.practy-testimonials-nav-button:hover {
  background: #4460f1;
  color: white;
  border-color: #4460f1;
}

.practy-testimonials-carousel {
  position: relative;
  overflow: hidden;
}

.practy-testimonials-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.practy-testimonials-slide {
  flex: 0 0 100%;
  padding: 0 12px;
  box-sizing: border-box;
}

.practy-testimonials-card {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  height: 100%;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
}

.practy-testimonials-quote {
  color: #4460f1;
  font-size: 32px;
  margin-bottom: 16px;
}

.practy-testimonials-card-title {
  color: black;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.practy-testimonials-card-description {
  color: #666;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 24px;
  flex-grow: 1;
}

.practy-testimonials-profile {
  display: flex;
  align-items: center;
  gap: 12px;
}

.practy-testimonials-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.practy-testimonials-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.practy-testimonials-info {
  flex-grow: 1;
}

.practy-testimonials-author {
  color: white;
  font-weight: 500;
}

.practy-testimonials-role {
  color: #999;
  font-size: 12px;
}

.practy-testimonials-rating {
  color: #ffd700;
}

.practy-testimonials-pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
}

.practy-testimonials-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e0e0e0;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.practy-testimonials-dot.active {
  width: 16px;
  border-radius: 4px;
  background: #4460f1;
}

/* Responsive breakpoints */
@media (min-width: 768px) {
  .practy-testimonials-slide {
    flex: 0 0 50%;
  }
}

@media (min-width: 1024px) {
  .practy-testimonials-section {
    padding: 5rem 24px;
  }
  .practy-testimonials-container {
    max-width: 1300px;
    margin: 0 auto;
  }
  .practy-testimonials-slide {
    flex: 0 0 33.333%;
  }
}

@media (max-width: 767px) {
  .practy-testimonials-header {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  .practy-testimonials-nav {
    justify-content: center;
  }
}
