.practywiz-CTA-container-outer {
  padding: 3rem 24px;
}

.practywiz-CTA-container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #172554;
  color: white;
  padding: 2rem;
  border-radius: 2rem;
  transition: all 0.3s ease;
}

.practywiz-CTA-container:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-0.5rem) !important;
}

.practywiz-CTA-sub-header {
  font-size: 1.5rem;
  justify-self: center;
  color: #cbd5e1;
  text-align: center;
}

.practywiz-CTA-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
  background: #2563eb;
  padding: 0.5rem;
  width: fit-content;
  border-radius: 4rem;
  justify-content: center;
  justify-self: center;
  align-self: center;
  flex-wrap: wrap;
}

.practywiz-CTA-tab {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent;
  color: #cbd5e1;
}

.practywiz-CTA-tab:hover {
  background-color: rgba(59, 130, 246, 0.2);
}

.practywiz-CTA-tab.active {
  background-color: rgba(228, 234, 248, 0.25);
  color: white;
}

.practywiz-CTA-content-wrapper {
  min-height: 200px;
  position: relative;
  text-align: center;
}

.practywiz-CTA-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  transform: translateX(2rem);
  pointer-events: none;
}

.practywiz-CTA-content.active {
  opacity: 1;
  transform: translateX(0);
  pointer-events: auto;
}

.practywiz-CTA-title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.practywiz-CTA-highlight {
  color: #22d3ee;
}

.practywiz-CTA-subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.practywiz-CTA-description {
  color: #cbd5e1;
  margin-bottom: 2rem;
}

.practywiz-CTA-button {
  display: flex;
  justify-self: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.875rem 2rem;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.practywiz-CTA-button:hover {
  background-color: #1d4ed8;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(2rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 1024px) {
  .practywiz-CTA-title {
    font-size: 2rem;
  }

  .practywiz-CTA-subtitle {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .practywiz-CTA-container-outer {
    padding: 1.5rem 2rem;
  }

  .practywiz-CTA-container {
    padding: 1.5rem;
  }
  .practywiz-CTA-content-wrapper {
    min-height: 250px;
  }

  .practywiz-CTA-tabs {
    gap: 0.5rem;
    padding: 0.4rem;
    width: 100%;
  }

  .practywiz-CTA-tab {
    padding: 0.4rem 0.8rem;
    font-size: 0.7rem;
  }

  .practywiz-CTA-title {
    font-size: 1.75rem;
  }

  .practywiz-CTA-subtitle {
    font-size: 1.1rem;
  }

  .practywiz-CTA-description {
    font-size: 0.9rem;
  }

  .practywiz-CTA-button {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .practywiz-CTA-container-outer {
    padding: 1rem;
  }

  .practywiz-CTA-container {
    padding: 1rem;
    border-radius: 1rem;
  }

  .practywiz-CTA-sub-header {
    font-size: 1.25rem;
  }

  .practywiz-CTA-tabs {
    width: 100%;
    border-radius: 1rem;
  }

  .practywiz-CTA-tab {
    padding: 0.4rem;
    text-align: center;
  }

  .practywiz-CTA-title {
    font-size: 1.5rem;
  }

  .practywiz-CTA-subtitle {
    font-size: 1rem;
  }

  .practywiz-CTA-content-wrapper {
    min-height: 250px;
  }

  .practywiz-CTA-button {
    width: 70%;
  }
}
