

.mainContainer {
  margin: 10px 0;
  padding: 0;
}

.container1 {
  width: 100%;
  height: 230px;
  z-index: 1;
  position: relative;
  overflow: visible;
  margin-bottom: 4.8rem;
}

.profileContainer {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: -3rem;
  padding: 20px;
  width: 90%;
  max-width: 1440px;
}

.containerOFProfile {
  padding: 0;
  margin-bottom: 0;
  height: 70%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.base {
  position: relative;
  margin-left: 5px;
}
.btn_profile {
  margin-top: 7rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-right: 1rem;
}


.container2 {
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.detailContainer {
  width: 50%;
}
.nameContainer {
  display: flex;
  justify-content: space-between;
}
.expirienceContainer {
  width: 35%;
  margin-right: 10px;
}
